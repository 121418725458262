import styled from 'styled-components';
import { position, PositionProps } from 'styled-system';

import { zIndex } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const NotificationContainerBox = styled(Box)<PositionProps>`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: ${zIndex('notification')};

  ${position}
`;
