import { useContext, useMemo } from 'react';

import { RoutesOverwritesContext } from '../RoutesOverwritesContext';
import { RouteList } from '../types';

/** Retrieve a list of routes for which their path should be overwritten */
export const useRoutesOverwrites = <
  RouteNames extends string,
  Routes extends RouteList<RouteNames>,
>(
  routes: Routes,
): Routes => {
  const overwrites = useContext(RoutesOverwritesContext);
  return useMemo(() => {
    // Mandatory Object.entries type casting
    const routeList = Object.entries(routes) as [
      RouteNames,
      Routes[RouteNames],
    ][];
    return routeList.reduce(
      (acc, [routeName, route]) => ({
        ...acc,
        [routeName]:
          route === null
            ? route
            : {
                ...route,
                path: overwrites[routeName] || route.path,
              },
      }),
      { ...routes },
    );
  }, [overwrites, routes]);
};
