import React, { VFC } from 'react';
import { Helmet } from 'react-helmet-async';

import { Image, useGetCoreAssetPath } from '@gaming1/g1-assets-management/web';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Text } from '@gaming1/g1-ui';

import { Page } from '../../layout/components/Page';

import { PageContent } from './styles';

export const NotFoundPage: VFC = () => {
  const { t } = useTranslation('core');
  const getAssetPath = useGetCoreAssetPath();

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <Page
        title={{ tkey: 'notFound.pageTitle' }}
        description={{ tkey: 'notFound.pageDescription' }}
        testId="notfoundpage-container"
      >
        <Page.Wrapper>
          <PageContent>
            <Box mx="md" my="xxxl" display="flex" alignItems="center">
              <Image
                url={getAssetPath('notFound')}
                alt={t('alt.notFound')}
                id="not-found"
              />
              <Text fontSize="xl" fontWeight="bold" textAlign="center">
                {t('notFound.message')}
              </Text>
            </Box>
          </PageContent>
        </Page.Wrapper>
      </Page>
    </>
  );
};
