import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useIsFullscreenLayout } from '@gaming1/g1-routing';
import {
  DrawerType,
  LayoutContext,
  MODAL_BETTINGSLIPFAST,
  useMediaBreakPoint,
} from '@gaming1/g1-ui';
import { usePrevious, useWindowSize } from '@gaming1/g1-utils';

import {
  BOTTOM_NAV_HEIGHT,
  FOOTER_HEADER_HEIGHT,
  LIGHT_FOOTER_HEIGHT,
  TOP_BAR_HEIGHT,
  TOP_NAV_LARGE_HEIGHT,
  TOP_NAV_SMALL_HEIGHT,
} from '../styles';

import {
  DRAWER_URL_SEARCH_PARAMETER,
  MODAL_URL_SEARCH_PARAMETER,
} from './constants';

const isDrawerType = (type: string): type is DrawerType => type in DrawerType;

const modalTypes: { [key: string]: string } = {
  bettingSlipFast: MODAL_BETTINGSLIPFAST,
};
const isModal = (id: string): string => modalTypes[id];

/** Returns the minimum height of the page content */
export const usePageMinHeight = (
  footerVisible: boolean,
): {
  minHeight: number;
} => {
  const isFullscreen = useIsFullscreenLayout();
  const hasBottomNav = useMediaBreakPoint({ max: 'md' });
  const { width, height } = useWindowSize();

  const [minHeight, setMinHeight] = useState(0);

  useLayoutEffect(() => {
    const { innerHeight } = window;

    if (isFullscreen) {
      setMinHeight(innerHeight - LIGHT_FOOTER_HEIGHT);
    } else if (hasBottomNav) {
      setMinHeight(innerHeight - TOP_NAV_SMALL_HEIGHT - BOTTOM_NAV_HEIGHT);
    } else {
      setMinHeight(
        innerHeight -
          TOP_BAR_HEIGHT -
          TOP_NAV_LARGE_HEIGHT -
          (footerVisible ? FOOTER_HEADER_HEIGHT : 0),
      );
    }
  }, [footerVisible, hasBottomNav, isFullscreen, height, width]);

  return { minHeight };
};

/**
 * This hook will use the url search parameter. To see if a drawer has to be
 * opened automatically after a navigation.
 */
export const useDrawerByUrlSearchParam = (drawerType: DrawerType) => {
  const history = useHistory();
  const location = useLocation();

  const { showDrawer, visibleDrawer } = useContext(LayoutContext);
  const previousVisibleDrawer = usePrevious(visibleDrawer);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const drawerUrlSearchParam = searchParams.get(DRAWER_URL_SEARCH_PARAMETER);

    if (
      drawerUrlSearchParam &&
      isDrawerType(drawerUrlSearchParam) &&
      drawerUrlSearchParam === drawerType
    ) {
      showDrawer(drawerUrlSearchParam);
    }
  }, [drawerType, location.search, showDrawer]);

  useEffect(() => {
    if (previousVisibleDrawer !== null && visibleDrawer === null) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(DRAWER_URL_SEARCH_PARAMETER);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [
    history,
    location.pathname,
    location.search,
    previousVisibleDrawer,
    visibleDrawer,
  ]);
};

/**
 * This hook will use the url search parameter. To see if a modal has to be
 * opened automatically after a navigation.
 */
export const useModalByUrlSearchParam = (modalId: string) => {
  const history = useHistory();
  const location = useLocation();

  const { showModal, visibleModal } = useContext(LayoutContext);
  const previousVisibleModal = usePrevious(visibleModal);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const modalUrlSearchParam = searchParams.get(MODAL_URL_SEARCH_PARAMETER);
    if (modalUrlSearchParam && modalId === isModal(modalUrlSearchParam)) {
      showModal(modalUrlSearchParam, true);
    }
  }, [location.search, modalId, showModal]);

  useEffect(() => {
    if (previousVisibleModal !== null && visibleModal === null) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete(MODAL_URL_SEARCH_PARAMETER);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [
    history,
    location.pathname,
    location.search,
    previousVisibleModal,
    visibleModal,
  ]);
};
