import React, { useEffect, useState, VFC } from 'react';

import { useNetwork } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { Box, Button, Icon, Loader } from '@gaming1/g1-ui';

import { LoaderWrapper } from './styles';

/**
 * Toast notification shown to the user when the connection with the web socket
 * has been lost
 */
export const NetworkConnectionLostToast: VFC = () => {
  const { t } = useTranslation('core');
  const { wsAdapter } = useNetwork();
  const [isAutomaticallyReconnecting, setIsAutomaticallyReconnecting] =
    useState(wsAdapter.getIsAutomaticallyReconnecting());
  const [isTryingToReconnect, setIsTryingToReconnect] = useState(
    wsAdapter.getStatus() === 'websocket/connecting',
  );

  useEffect(() => {
    const subscription = wsAdapter.isAutomaticallyReconnecting$.subscribe(
      setIsAutomaticallyReconnecting,
    );

    return () => subscription.unsubscribe();
  }, [wsAdapter]);

  useEffect(() => {
    const subscription = wsAdapter.status$.subscribe((status) =>
      setIsTryingToReconnect(status === 'websocket/connecting'),
    );

    return () => subscription.unsubscribe();
  }, [wsAdapter]);

  const clickHandler = () => {
    wsAdapter.reconnect();
  };

  return (
    <Box
      alignItems="center"
      flex={1}
      flexDirection="row"
      height="100%"
      justifyContent="space-between"
    >
      <Box alignItems="center" flexBasis="10%" mr="xs">
        <Icon id="no-wifi" type="NoWifi" height="1.5em" width="1.5em" />
      </Box>
      <Box flex={1}>
        {t(
          isAutomaticallyReconnecting
            ? 'error.connectionLost.tryToConnect'
            : 'error.connectionLost.disconnected',
        )}
      </Box>
      <Box alignItems="center" ml="xs">
        {isAutomaticallyReconnecting ? (
          <LoaderWrapper>
            <Loader smart={false} />
          </LoaderWrapper>
        ) : (
          <Button
            type="tertiary"
            color="textLight"
            onClick={clickHandler}
            loading={isTryingToReconnect}
          >
            {t('error.retryButton')}
          </Button>
        )}
      </Box>
    </Box>
  );
};
