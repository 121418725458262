import { createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import {
  CheckGeoLocationResponse,
  GeoComplyLicenseKeyResponse,
  IsGeoComplyLocationValidResponse,
} from './codecs';

export const connectToGeoComply = createAsyncAction(
  'core/connect_to_geo_comply_request',
  'core/connect_to_geo_comply_success',
  'core/connect_to_geo_comply_failure',
)<void, void, FailurePayload>();

export const getGeoComplyLicenseKey = createAsyncAction(
  'core/get_geo_comply_license_key_request',
  'core/get_geo_comply_license_key_success',
  'core/get_geo_comply_license_key_failure',
)<void, GeoComplyLicenseKeyResponse, FailurePayload>();

export const checkIsCachedGeolocationValid = createAsyncAction(
  'core/check_geo_location_validity_request',
  'core/check_geo_location_validity_success',
  'core/check_geo_location_validity_failure',
)<void, IsGeoComplyLocationValidResponse, FailurePayload>();

export const requestGeolocation = createAsyncAction(
  'core/geo_location_request',
  'core/geo_location_success',
  'core/geo_location_failure',
)<void, string, FailurePayload>();

export const decryptGeolocation = createAsyncAction(
  'core/decrypt_geolocation_request',
  'core/decrypt_geolocation_success',
  'core/decrypt_geolocation_failure',
)<string, CheckGeoLocationResponse, FailurePayload>();
