import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BUSINESS_SESSION_ID_KEY,
  coreActions,
  geoComplyLicenseKeySelector,
  GeolocationCustomErrorCode,
  GeolocationReason,
  interopMessages,
  userIdSelector,
  userLoggedInSelector,
  userSessionIdSelector,
} from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';

import { logger } from '../../../logger';
import { sendIframeMessage } from '../../../rninterop/iframeMessageManager/helpers';

import { useGeoComplySDK } from './useGeoComplySDK';

/** Returns a function used for triggering a geolocation request to the
 * GeoComply SDK */
export const useRequestGeolocation = ({
  onReasonUpdate,
}: {
  onReasonUpdate: (params: GeolocationReason) => void;
}) => {
  const geoComplySDK = useGeoComplySDK();

  const geoComplyLicenseKey = useSelector(geoComplyLicenseKeySelector);
  const userId = useSelector(userIdSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const sessionId = useSelector(userSessionIdSelector);
  const dispatch = useDispatch();

  // Those refs are used so that callbacks always have the most up-to-date value
  // from the store.
  const userIdRef = useRef(userId);
  userIdRef.current = userId;
  const isUserLoggedInRef = useRef(isUserLoggedIn);
  isUserLoggedInRef.current = isUserLoggedIn;

  return useCallback(
    (reason: GeolocationReason) => {
      const userIdForGeolocation = userIdRef.current;
      /**
       * Early return that handles the fact that either
       * - the user is not logged in
       * - or the userId does not exist
       */
      if (!isUserLoggedIn || !userIdForGeolocation) {
        if (isUserLoggedIn) {
          logger.error(
            `[Geolocation] A geolocation request was made with reason ${reason} but it was refused due to an empty userId`,
          );
        } else {
          logger.error(
            `[Geolocation] A geolocation request was made with reason ${reason} and userId ${userIdForGeolocation} but it was refused because the user isn't logged in`,
          );
        }
        return;
      }

      if (getWrapperEnv() !== 'rn' && geoComplySDK && geoComplyLicenseKey) {
        // TODO: move those geoComplySDK.Client calls to the
        // useGeoComplyConnection that will expose those methods, so we deal
        // with the geoComplySDK in one place
        geoComplySDK.Client.setLicense(geoComplyLicenseKey);
        geoComplySDK.Client.setGeolocationReason(reason);
        geoComplySDK.Client.setUserId(userIdForGeolocation);

        if (sessionId) {
          geoComplySDK.Client.customFields.set(
            BUSINESS_SESSION_ID_KEY,
            sessionId,
          );
        }

        dispatch(coreActions.requestGeolocation.request());
        geoComplySDK.Client.requestGeolocation();

        onReasonUpdate(reason);

        logger.info(
          `[Geolocation] [Request] [Web] userId: ${userIdForGeolocation}, reason: ${reason}, ${BUSINESS_SESSION_ID_KEY}: ${sessionId}`,
        );
      } else if (getWrapperEnv() === 'rn' && geoComplyLicenseKey) {
        onReasonUpdate(reason);

        // Send the request geolocation to the native app instead of the PLC
        sendIframeMessage(interopMessages.geolocationRequest, {
          reason,
          userId: userIdForGeolocation,
          businessSessionId: sessionId || undefined,
          license: geoComplyLicenseKey,
        });

        logger.info(`[Geolocation] [Request] [Native] reason: ${reason}`);
      } else {
        const errorMessage = `${
          geoComplySDK ? 'GeoComply License key' : 'GeoComply SDK'
        } not set`;
        const errorCode = geoComplySDK
          ? GeolocationCustomErrorCode.NO_GC_LICENSE
          : GeolocationCustomErrorCode.NO_GC_SDK;
        dispatch(
          coreActions.requestGeolocation.failure({
            errorMessage,
            status: errorCode,
          }),
        );
        logger.error(`
          [Geolocation] [Request] Error: ${errorMessage}`);
      }
    },
    [
      dispatch,
      geoComplyLicenseKey,
      geoComplySDK,
      isUserLoggedIn,
      onReasonUpdate,
      sessionId,
    ],
  );
};
