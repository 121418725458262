import React, { FC } from 'react';

import { AppProvider, App as CoreApp } from '@gaming1/g1-core-web';
import { geti18n } from '@gaming1/g1-i18n';
import { useInitialAppLanguage } from '@gaming1/g1-routing';

import { AssetsProvider } from './assets/AssetsProvider';
import { AppLayout } from './components/AppLayout';
import { config } from './config';
import i18nConfigJson from './i18n-config.json';
import { Router } from './Router';
import { store } from './store';
import { theme } from './theme';

export const App: FC = () => {
  // Cannot use useLocation here since the router is not yet rendered
  const initialURLLanguage = useInitialAppLanguage(window.location.pathname);

  const i18n = geti18n({
    availableLanguages: config.i18n.availableLanguages,
    awsPrefix: i18nConfigJson.awsPrefix,
    currency: i18nConfigJson.values.currency,
    defaultLanguage: config.i18n.defaultLanguage,
    initialLanguage: initialURLLanguage,
  });

  return (
    <AppProvider
      config={config}
      i18n={i18n}
      i18nValues={{
        availableLanguagesFullCode: i18nConfigJson.languages,
        values: i18nConfigJson.values,
      }}
      store={store}
      theme={theme}
    >
      <AssetsProvider>
        <CoreApp>
          <AppLayout>
            <Router />
          </AppLayout>
        </CoreApp>
      </AssetsProvider>
    </AppProvider>
  );
};
