import { Location } from 'history';
import { pathToRegexp } from 'path-to-regexp';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useConfig } from '@gaming1/g1-config';
import {
  coreActions,
  databaseRoutesByLocaleSelector,
  getAuthTokenRequestStateSelector,
  useI18n,
  userLoggedInSelector,
  userTokenSelector,
} from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import {
  concatLocation,
  extractLocaleFromUrl,
  useGoBackWithFallback,
  useRoutePath,
  useRoutesOverwrites,
} from '@gaming1/g1-routing';
import { useUserPublicRoutePath } from '@gaming1/g1-user-api';
import { useRequestCallback } from '@gaming1/g1-utils';

import { navigateToExternalUrl } from '../rninterop/helpers';
import { getCoreRoutes } from '../routes';

/** Get the core route list */
export const useCoreRoutes = () => {
  const config = useConfig();
  return useRoutesOverwrites(getCoreRoutes(config));
};

/** Get the route path of the core routes */
export const useCoreRoutePath = () => useRoutePath(getCoreRoutes);

/**
 * This hook will return a function that redirect to the login page, with
 * the 'from' parameter configured (for the post login redirection).
 */
export const useGoToLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const getUserRoutePath = useUserPublicRoutePath();
  return useCallback(
    (postRedirectLocation?: Partial<Location>) => {
      history.push(
        getUserRoutePath('login', undefined, {
          from: concatLocation({
            ...location,
            ...postRedirectLocation,
          }),
        }),
      );
    },
    [getUserRoutePath, history, location],
  );
};

/** This hook return a database route matching the given pathname */
export const useDatabaseRouteByPathname = (pathname: string) => {
  const { currentLocale } = useI18n();
  const databaseRoutes = useSelector(databaseRoutesByLocaleSelector)(
    extractLocaleFromUrl(pathname) || currentLocale,
  );

  if (databaseRoutes) {
    const [databaseRoutePageName, databaseRoute] =
      Object.entries(databaseRoutes).find(
        ([, { path }]) => path && pathToRegexp(path).exec(pathname),
      ) || [];

    return databaseRoutePageName && databaseRoute
      ? { pageName: databaseRoutePageName, ...databaseRoute }
      : undefined;
  }

  return null;
};

/** This hook return a database route matching the given page name */
export const useDatabaseRouteByPageName = (pageName: string | null) => {
  const { currentLocale } = useI18n();
  const databaseRoutes = useSelector(databaseRoutesByLocaleSelector)(
    currentLocale,
  );

  if (pageName && databaseRoutes) {
    const [databaseRoutePageName, databaseRoutePath] =
      Object.entries(databaseRoutes).find(
        ([routePageName]) => routePageName === pageName,
      ) || [];

    return databaseRoutePageName && databaseRoutePath
      ? { pageName: databaseRoutePageName, ...databaseRoutePath }
      : undefined;
  }

  return null;
};

/**
 * This hook returns a function that navigates to a specified URL with an
 * appended authentication token (if the user is logged in). After the
 * navigation the previous location will be restored (when the function is
 * called with a react-native wrapper).
 */
export const useNavigateWithAuthToken = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const token = useSelector(userTokenSelector);
  const { status } = useSelector(getAuthTokenRequestStateSelector);

  const getCoreRoutePath = useCoreRoutePath();
  const goBackWithFallback = useGoBackWithFallback(getCoreRoutePath('home'));

  const [targetUrl, setTargetUrl] = useState<string | null>();

  const navigateToUrl = useCallback(
    (url: string) => {
      navigateToExternalUrl(url, {
        web: {
          method: 'replace',
        },
        native: {
          method: 'openDeviceBrowser',
        },
      });

      if (getWrapperEnv() === 'rn') {
        goBackWithFallback();
      }
    },
    [goBackWithFallback],
  );

  useRequestCallback(
    status,
    () => {
      if (targetUrl) {
        navigateToUrl(`${targetUrl}?authenticateToken=${token}`);
      }
    },
    () => {
      if (targetUrl) {
        navigateToUrl(targetUrl);
      }
    },
  );

  return useCallback(
    (url: string) => {
      if (url !== `${origin}${concatLocation(location)}`) {
        if (isUserLoggedIn) {
          setTargetUrl(url);
          dispatch(coreActions.getAuthToken.request());
        } else {
          navigateToUrl(url);
        }
      }
    },
    [dispatch, isUserLoggedIn, location, navigateToUrl],
  );
};
