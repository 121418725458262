import { Link, LinkProps, NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  fontSize,
  FontSizeProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

import {
  colors,
  device,
  elevation,
  fontSizes,
  fontWeight,
  media,
  spaces,
} from '@gaming1/g1-style';

import { Box } from './Box';

type HeadingProps = SpaceProps & {
  mono?: boolean;
};

export const SubtitleDiv = styled.div<HeadingProps>`
  color: ${({ theme }) =>
    theme.dark ? theme.colors.textLight : theme.colors.textDark};
  ${fontWeight('light')};
  font-size: ${fontSizes('md')};
  margin-top: ${spaces('sm')};
  line-height: 1.4;
  ${media.lg} {
    font-size: 1.063rem;
    margin-top: ${spaces('md')};
    ${space}
  }
  ${space}
`;

export const P = styled.p<SpaceProps>`
  ${space}
`;

export const SimpleButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: ${colors('text')};
  -webkit-tap-highlight-color: transparent;

  &:enabled {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export type AppAnchorProps = FontSizeProps & SpaceProps & WidthProps;

export const appAnchorCSS = css<AppAnchorProps>`
  ${space}
  ${width}
  ${fontSize}
  color: ${colors('link')};
  text-decoration: none;
  cursor: pointer;

  ${device.canHover} {
    &:hover,
    &:focus {
      color: ${colors('activeLink')};
    }
  }
`;

export const AppAnchor = styled.a<AppAnchorProps>`
  ${appAnchorCSS}
`;

export const AppLink = styled(Link)<AppAnchorProps & LinkProps>`
  ${appAnchorCSS}
`;

export const horizontalScrollWithoutScrollbarCss = css`
  /* overflow must be 'scroll', if not there are no JS events... */
  overflow-x: scroll;

  /* hide scrollbar for Firefox */
  scrollbar-width: none;

  /* hide scrollbar for IE and Edge */
  -ms-overflow-style: none;

  /* hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuItemProfile = styled(NavLink)`
  margin: ${spaces('xs')};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.buttonSecondaryBackground};
  flex-grow: 1;
`;

export const RoundIconWrapper = styled(Box)`
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  flex-shrink: 0;
  border: 2px solid ${colors('profileIconBorderColor')};

  path {
    fill: ${colors('neutrals', { index: 2 })};
  }
`;

export const RoundIconMenuWrapper = styled(RoundIconWrapper)`
  width: 48px;
  height: 48px;
  ${elevation(2)};
  border: none;
  margin-bottom: ${spaces('xs')};

  path {
    fill: ${colors('text')};
  }
`;

export const MenuIconText = styled.span`
  font-size: ${fontSizes('md')};
  color: ${colors('text')};
  ${fontWeight('bold')};
  line-height: 19px;
  margin: 0 ${spaces('xs')};
  white-space: nowrap;
  text-align: center;
`;
