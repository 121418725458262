import { ContextType, useCallback, useEffect, useMemo } from 'react';

import { useConfig } from '@gaming1/g1-config';
import { useConsentPolicy } from '@gaming1/g1-consent-management/shared';

import { logger } from '../../../logger';
import { TrackingContext } from '../../TrackingContext';
import { FBPProperty, windowWithFBQ } from '../../types';

type MetaPixelContextValue = Pick<
  ContextType<typeof TrackingContext>,
  'isMetaPixelEnabled' | 'trackMetaPixelEvent'
>;

/**
 * This hook simply check the user gave his consent for the advertizing purpose
 * and the
 */
export const useMetaPixel = (): MetaPixelContextValue => {
  const {
    core: { privacySettings },
    seo: { metaPixelId },
  } = useConfig();

  const { getUserConsentStatusForPurpose, getUserConsentStatusForVendor } =
    useConsentPolicy();

  const isMetaPixelEnabled =
    !!getUserConsentStatusForPurpose(privacySettings.purposeIDs.advertizing) &&
    !!getUserConsentStatusForVendor(privacySettings.vendorIDs.metaPixel) &&
    !!metaPixelId;

  const trackMetaPixelEvent = useCallback(
    (event: string) => {
      if (isMetaPixelEnabled && windowWithFBQ.fbq) {
        windowWithFBQ.fbq('track', event);
      }
    },
    [isMetaPixelEnabled],
  );

  /* Adapted from https://developers.facebook.com/docs/meta-pixel/get-started
  and
  https://stackoverflow.com/questions/60928981/implementing-facebook-pixel-using-angular-9
  */
  useEffect(() => {
    if (isMetaPixelEnabled && !windowWithFBQ.fbq) {
      const scriptSource = 'https://connect.facebook.net/en_US/fbevents.js';
      const metaPixelObject = ((...args) => {
        if (metaPixelObject.callMethod) {
          metaPixelObject.callMethod(...args);
        } else {
          metaPixelObject.queue.push(args);
        }
      }) as FBPProperty;
      windowWithFBQ.fbq = metaPixelObject;
      if (!windowWithFBQ._fbq) {
        windowWithFBQ._fbq = metaPixelObject;
      }
      metaPixelObject.push = metaPixelObject;
      metaPixelObject.loaded = true;
      metaPixelObject.version = '2.0';
      metaPixelObject.queue = [];
      const scriptTag = document.createElement('script');
      scriptTag.async = !0;
      scriptTag.src = scriptSource;
      const firstScriptInTheDocument =
        document.getElementsByTagName('script')[0];
      firstScriptInTheDocument?.parentNode?.insertBefore(
        scriptTag,
        firstScriptInTheDocument,
      );

      windowWithFBQ.fbq('init', metaPixelId);
      windowWithFBQ.fbq('track', 'PageView');

      logger.debug(
        '[Tracking] Meta Pixel in initialized. `window.fbq` is set.',
      );
    }
  }, [isMetaPixelEnabled, metaPixelId]);

  return useMemo(
    () => ({ isMetaPixelEnabled, trackMetaPixelEvent }),
    [isMetaPixelEnabled, trackMetaPixelEvent],
  );
};
