import { createContext } from 'react';

import { GeolocationReason } from '@gaming1/g1-core';

type GeolocationContextValue = {
  isGeoComplyReady: boolean;
  requestGeolocation: (reason: GeolocationReason) => void;
  retryLatestGeolocationRequest: () => void;
};

export const geolocationContextDefaultValue: GeolocationContextValue = {
  isGeoComplyReady: false,
  requestGeolocation: () => undefined,
  retryLatestGeolocationRequest: () => undefined,
};

export const GeolocationContext = createContext<GeolocationContextValue>(
  geolocationContextDefaultValue,
);
