import mergeWith from 'lodash/mergeWith';

import { getDeployEnv } from '@gaming1/g1-logger';

import { defaultConfig } from './default';
import { getOverwriteConfigByServerEnv } from './serverEnvOverwrite';
import { AppConfig, ConfigOverwriteByEnv, PartnerConfig } from './types';

/**
 * Create a config by merging the default values with the overwrites provided
 * Array are replaced and not merged
 * @param partnerConfig partial config object that overwrite the default values
 */
export const getConfig = (
  partnerConfig: PartnerConfig = {},
  configOverwriteByEnv?: ConfigOverwriteByEnv,
): Readonly<AppConfig> => {
  const baseConfig = mergeWith(
    {},
    defaultConfig,
    partnerConfig,
    (_, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined),
  );
  const envConfig = mergeWith(
    {},
    baseConfig,
    // Use the config overwrite for current env (code)
    configOverwriteByEnv?.[getDeployEnv()] || {},
    (_, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined),
  );
  return mergeWith(
    {},
    envConfig,
    // Use the config overwrite for current env (server)
    getOverwriteConfigByServerEnv(),
    (_, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined),
  );
};
