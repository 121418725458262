import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';

import { LocaleCode } from '@gaming1/g1-config';

import { ApplicationState } from '../types';

import { DatabaseRoutesOriginAndPath } from './types';

const commonCoreStateSelector = (state: ApplicationState) => state.core.common;

const lastVisitSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.lastVisit,
);

export const hasUserAlreadyVisitedSelector = createSelector(
  lastVisitSelector,
  (lastVisit) => !!lastVisit,
);

export const creationSectionSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.creationSection,
);

export const appInitializedSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.init,
);

export const currentSectionSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.section,
);

export const hasUserAlreadyLoggedInSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.hasUserAlreadyLoggedIn,
);

/** Returns the database routes from the http api */
export const databaseRoutesSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.databaseRoutes,
);

export const databaseRoutesByLocaleSelector = createSelector(
  databaseRoutesSelector,
  (databaseRoutes) =>
    memoize((locale: LocaleCode) => {
      if (databaseRoutes) {
        const databaseRoutesOriginAndPath: DatabaseRoutesOriginAndPath = {};
        Object.entries(databaseRoutes).forEach(([pageName, route]) => {
          databaseRoutesOriginAndPath[pageName] = {
            origin: route.origin,
            path: route.paths[locale],
          };
        });
        return databaseRoutesOriginAndPath;
      }

      return null;
    }),
);

export const databaseRoutesRequestStateSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.requests.getDatabaseRoutes,
);

/** Returns the shell parameters from the http api. Not used at the moment */
export const shellParametersSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.shellParameters,
);

export const shellParametersRequestStateSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.requests.getShellParameters,
);

/** Returns the current mobile app version and build number */
export const mobileVersionSelector = createSelector(
  commonCoreStateSelector,
  ({ mobileBuildNumber, mobileBuildVersion }) => ({
    mobileBuildNumber,
    mobileBuildVersion,
  }),
);

/** Returns the minimum authorised mobile app version from the backend */
export const minimumMobileAppVersionSelector = createSelector(
  commonCoreStateSelector,
  (state) => (platform?: 'ios' | 'android') => {
    if (platform === 'ios' && state.minimumIosVersion) {
      return state.minimumIosVersion;
    }
    if (platform === 'android' && state.minimumAndroidVersion) {
      return state.minimumAndroidVersion;
    }

    return state.minimumMobileAppVersion;
  },
);

/** Returns the captcha site key */
export const captchaSiteKeySelector = createSelector(
  commonCoreStateSelector,
  (state) => state.shellParameters?.RecaptchaSiteKey,
);

/** Returns whether the captcha is enabled or not  */
export const isCaptchaEnabledSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.shellParameters?.IsRecaptchaEnabled,
);
/** Returns true if the debug mode is enabled for the app */
export const isDebugModeEnabledSelector = createSelector(
  commonCoreStateSelector,
  (state) => state.isDebugModeEnabled,
);
