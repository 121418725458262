import { GamingConfig } from '../types/gaming';

/**
 * Gaming default configuration
 */
export const defaultGamingConfig: GamingConfig = {
  arePlayerGameFeaturesEnabled: false,
  arePlayerLimitsEnabled: false,
  defaultCategoryTypeForGameCategoriesMenu: 'all',
  enabled: true,
  isGameInfoPanelEnabled: false,
  isJackpotValueOnThumbnailEnabled: false,
  isMoneyMakerEnabled: false,
  isPlatformJackpotEnabled: false,
  maxPatternRepetition: 10,
  minimalAge: 21,
  shouldDisplayFiltersOnGameListByCategory: false,
  shouldDisplayTitleOnGameListByCategory: false,
};
