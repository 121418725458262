import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { LocaleCode } from '@gaming1/g1-config';
import { useI18n, useTranslation } from '@gaming1/g1-i18n';

import { replaceLocaleInUrl } from '../helpers';

type LocaleItem = {
  /** Name of the locale */
  label: string;
  /** Is the locale currently selected */
  selected: boolean;
  /** Code of the locale */
  value: LocaleCode;
};

type LocalesList = LocaleItem[];

/**
 * Returns a list of available locales , the current locale and a function
 * to change it
 */
export const useLocaleSwitch = () => {
  const { i18n, t } = useTranslation('core');
  const history = useHistory();
  const { currentLocale, availableLanguages } = useI18n();

  const localeShortCode = i18n.language ? i18n.language.slice(0, 2) : null;
  const locale = localeShortCode || currentLocale;

  const locales = availableLanguages;
  const localeList: LocalesList = locales.map((code) => ({
    label: t(`languages.${code}`),
    selected: code === locale,
    value: code,
  }));

  /**
   * Switches the locale
   *
   * @param localeInput the locale to be switched to
   * @param pushInHistory wether the url generated from the language switch should be pushed in the browser history or not
   */
  const switchLocale = useCallback(
    (localeInput: string, pushInHistory = true) => {
      const shouldUseTranslationsDebug = localeInput === 'cimode';
      if (shouldUseTranslationsDebug) {
        i18n.changeLanguage('cimode');
      } else if (locales.includes(localeInput as LocaleCode)) {
        const localeCode = localeInput as LocaleCode;

        // Update the locale used by i18next
        // Push the new route in the history (if needed)
        // when translations are loaded.  This will prevent  the app
        // from showing an unwanted 404 page
        i18n.changeLanguage(localeCode, () => {
          // Push the url in the history if asked to (default being yes)
          // false will mainly be used when the back/forward buttons are pressed
          // This is due to switchLocale not triggering a history push by itself
          // So the back/forward button actually totally skip the new URL.
          if (pushInHistory) {
            const targetedLocation = replaceLocaleInUrl(
              history.location.pathname,
              localeCode,
            );

            if (targetedLocation) {
              history.push(targetedLocation);
            }
          }
        });
      }
    },
    [locales, i18n, history],
  );
  return { locale, localeList, switchLocale };
};
