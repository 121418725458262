import { conditionalWindow } from '../types/conditionalWindow';
import { WrapperEnv } from '../types/wrapperEnv';

import { getEnvVariable } from './getEnvVariable';
import { getSearchParam } from './getSearchParam';

const wrapperSearch = getSearchParam('wrapperEnv');

const wrapperEnvs: WrapperEnv[] = ['spa', 'rn', 'interopwebapp'];

const isWrapperEnv = (envValue?: string): envValue is WrapperEnv =>
  !!envValue && wrapperEnvs.includes(envValue as WrapperEnv);
/**
 * Get the environnement where the code is executed. Either "browser" if the web
 * app is a regular website or "rn" if the web app is served from a webview
 * inside a React Native app
 */
export const getWrapperEnv = (): WrapperEnv => {
  // This is for testing purpose only: the query param can be set to test the
  // app behaviour in a browser while the env param can be used in jest
  const forcedWrapperEnv = wrapperSearch || getEnvVariable('wrapperEnv');
  if (isWrapperEnv(forcedWrapperEnv)) {
    return forcedWrapperEnv;
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    typeof conditionalWindow.ReactNativeWebView !== 'undefined'
  ) {
    return 'rn';
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    typeof conditionalWindow.reactInterop !== 'undefined'
  ) {
    return 'interopwebapp';
  }
  return 'spa';
};
