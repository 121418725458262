// From https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/
// const myEventBus = document.createComment('g1-event-bus');

/** Duration in which events with exactly the same channel, topic and payload
 * will be throttled */
export const EVENT_THROTTLE_TIME_IN_MS = 300;

/**
 * Name of the channel to be used when messages/events need to be dispatched
 * cross-domains. This should be used very rarely and with the least amount of
 * data as possible. Those messages payload should not be complex nor be
 * dependant on domain internal data structure
 */
export const GLOBAL_CHANNEL = 'global';
