import { createAction, createAsyncAction } from 'typesafe-actions';

import { AppConfig, LocaleCode } from '@gaming1/g1-config';
import { WsAdapterOptions } from '@gaming1/g1-network';
import { RESET_STATES_ACTION_NAME } from '@gaming1/g1-store';
import { FailurePayload } from '@gaming1/g1-utils';

import { DatabaseRoutes, ShellParameters } from './codecs';
import { AppSection, CommonCoreState } from './types';

/** Run at the launch of the app */
export const initApp = createAction('core/init')<{
  hasUserAlreadyLoggedIn: boolean;
  isInMobileApp: boolean;
  lastVisitTimestamp: number | null;
  locale: LocaleCode;
  wsAdapterOptions?: Partial<WsAdapterOptions>;
}>();

/** The app will quit because the user closed the tab */
export const exitApp = createAction('core/exit')();

export const setLastVisitTimestamp = createAction(
  'core/set_last_visit_timestamp',
)<number>();

/** Switch the section of the application */
export const switchSection = createAction(
  'core/switch_section',
  (section: AppSection) => section,
)();

/** Used for testing */
export const dummyAction = createAction('core/dummy')();

/**
 * Get a list of routes from the database
 */
export const getDatabaseRoutes = createAsyncAction(
  'core/get_database_routes_request',
  'core/get_database_routes_success',
  'core/get_database_routes_failure',
)<void, DatabaseRoutes, FailurePayload>();

/**
 * Get various value form the http api. None are used at the moment
 */
export const getShellParams = createAsyncAction(
  'core/get_shell_params_request',
  'core/get_shell_params_success',
  'core/get_shell_params_failure',
)<void, ShellParameters, FailurePayload>();

/** Dispatch the current config of the app to the store */
export const setConfig = createAction('core/set_config')<AppConfig>();

/** Enable or disable the debug mode */
export const setDebugMode = createAction('debugging/set_debug_mode')<boolean>();

/** Init mobile app with the OS, version and build numbers */
export const initMobileApp = createAction('core/init_mobile_app')<
  Pick<
    CommonCoreState,
    'mobileAppPlatformOS' | 'mobileBuildNumber' | 'mobileBuildVersion'
  >
>();

/** Resets states to their initial states */
export const resetStates = createAction(RESET_STATES_ACTION_NAME)();
