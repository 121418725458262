/* eslint-disable max-classes-per-file */
import { SimpleTheme, WidthInPixels } from './theme';

/*
  These classes only serve one purpose: to have an array with custom properties
  that serve as aliases.
  e.g. in the 'space' array the value 2 can be obtained either with space[1] or
  space.xs
*/

export class BreakpointsWithAliases extends Array<WidthInPixels> {
  public xs: WidthInPixels;

  public sm: WidthInPixels;

  public md: WidthInPixels;

  public lg: WidthInPixels;

  public xl: WidthInPixels;

  public xxl: WidthInPixels;

  public constructor(breakpoints: SimpleTheme['breakpoints']) {
    super(...breakpoints);
    [this.xs, this.sm, this.md, this.lg, this.xl, this.xxl] = breakpoints;
    Object.setPrototypeOf(this, BreakpointsWithAliases.prototype);
  }
}

export class FontSizesWithAliases extends Array<number> {
  public sm: number;

  public md: number;

  public lg: number;

  public xl: number;

  public xxl: number;

  public xxxl: number;

  public constructor(fontSizes: SimpleTheme['fontSizes']) {
    super(...fontSizes);
    [this.sm, this.md, this.lg, this.xl, this.xxl, this.xxxl] = fontSizes;
    Object.setPrototypeOf(this, FontSizesWithAliases.prototype);
  }
}

export class OpacityWithAliases extends Array<number> {
  public transparent: number;

  public barelyVisible: number;

  public translucent: number;

  public semi: number;

  public seeThrough: number;

  public obscure: number;

  public opaque: number;

  public constructor(opacityLevels: SimpleTheme['opacity']) {
    super(...opacityLevels);
    [
      this.transparent,
      this.barelyVisible,
      this.translucent,
      this.semi,
      this.seeThrough,
      this.obscure,
      this.opaque,
    ] = opacityLevels;
    Object.setPrototypeOf(this, OpacityWithAliases.prototype);
  }
}

export class SpaceWithAliases extends Array<number> {
  public none: number;

  public xxxs: number;

  public xxs: number;

  public xs: number;

  public sm: number;

  public md: number;

  public lg: number;

  public xl: number;

  public xxl: number;

  public xxxl: number;

  public xxxxl: number;

  public constructor(spaces: SimpleTheme['space']) {
    super(...spaces);
    [
      this.none,
      this.xxxs,
      this.xxs,
      this.xs,
      this.sm,
      this.md,
      this.lg,
      this.xl,
      this.xxl,
      this.xxxl,
      this.xxxxl,
    ] = spaces;
    Object.setPrototypeOf(this, SpaceWithAliases.prototype);
  }
}

export class ZIndicesWithAliases extends Array<number> {
  public behind: number;

  public fullscreen: number;

  public bottomnav: number;

  public popover: number;

  public helpWidget: number;

  public cookieMessage: number;

  public backdrop: number;

  public drawer: number;

  public modal: number;

  public notification: number;

  public devTool: number;

  public constructor(zIndices: SimpleTheme['zIndices']) {
    super(...zIndices);
    [
      this.behind,
      this.fullscreen,
      this.bottomnav,
      this.popover,
      this.helpWidget,
      this.cookieMessage,
      this.backdrop,
      this.drawer,
      this.modal,
      this.notification,
      this.devTool,
    ] = zIndices;
    Object.setPrototypeOf(this, ZIndicesWithAliases.prototype);
  }
}

export type ThemeAliases = {
  breakpoints: BreakpointsWithAliases;
  fontSizes: FontSizesWithAliases;
  opacity: OpacityWithAliases;
  space: SpaceWithAliases;
  zIndices: ZIndicesWithAliases;
};
