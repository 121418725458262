import React, { ContextType, FC, memo, ReactNode, useMemo } from 'react';

import { ajaxFetch, wsAdapter } from '@gaming1/g1-network';

import { NetworkContext } from './NetworkContext';

type NetworkProviderProps = { children?: ReactNode };

/** Provide network tools, useful for dependency injection */
export const NetworkProvider: FC<NetworkProviderProps> = memo(
  ({ children }) => {
    const networkContextValue: ContextType<typeof NetworkContext> = useMemo(
      () => ({
        wsAdapter,
        ajaxFetch,
      }),
      [],
    );
    return (
      <NetworkContext.Provider value={networkContextValue}>
        {children}
      </NetworkContext.Provider>
    );
  },
);
