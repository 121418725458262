import React, { useState, VFC } from 'react';
import { Helmet, HelmetTags, LinkProps } from 'react-helmet-async';

type GoogleFontProps = {
  /**
   * The font name found in the Google Font url (under the earch param 'family').
   * Any space will be replaced by the + character
   * Example in the url https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap
   * it will be 'Source+Sans+Pro:300,400,600'
   *
   */
  fontFamily: string;
};

// From https://stackoverflow.com/a/9851769
type WindowWithFirefoxProps = Window & {
  InstallTrigger?: unknown;
};
const windowWithFirefoxProps = window as WindowWithFirefoxProps;
const isFirefox = typeof windowWithFirefoxProps.InstallTrigger !== 'undefined';

/** Load a google font in a non-blocking way */
export const GoogleFont: VFC<GoogleFontProps> = ({ fontFamily }) => {
  const family = fontFamily.replace(/\s/g, '+');
  const [rel, setRel] = useState<LinkProps['rel']>(
    /*
    Unfortunately, firefox doesn't support onLoad on link tags
    See https://stackoverflow.com/questions/45321043/preload-css-file-not-supported-on-firefox-and-safari-mac
    */
    isFirefox ? 'stylesheet preload' : 'preload',
  );
  // From https://github.com/nfl/react-helmet/issues/146#issuecomment-271552211
  const handleScriptInject = ({ linkTags }: HelmetTags) => {
    if (linkTags) {
      const fontLink = linkTags.find(
        (linkTag) => linkTag.href.indexOf(family) !== -1,
      );
      if (fontLink) {
        fontLink.onload = () => {
          setRel('stylesheet');
        };
      }
    }
  };
  return (
    <Helmet
      link={[
        {
          rel,
          href: `https://fonts.googleapis.com/css?family=${family}&display=swap`,
          as: 'style',
        },
      ]}
      onChangeClientState={(_, addedTags) => handleScriptInject(addedTags)}
    />
  );
};
