import { useEffect, useState } from 'react';

import { useConfig } from '@gaming1/g1-config';
import { getWrapperEnv } from '@gaming1/g1-env';

type GeoComply = typeof import('../../scripts/geocomply-client.min');

export const useGeoComplySDK = () => {
  const { core } = useConfig();
  const [geocomplySdk, setGeocomplySdk] = useState<GeoComply | null>(null);

  useEffect(() => {
    if (core.geoComply.activated && getWrapperEnv() !== 'rn' && !geocomplySdk) {
      import('../../scripts/geocomply-client.min').then(
        ({ default: GeoComply }) => {
          setGeocomplySdk(GeoComply);
        },
      );
    }
  }, [core.geoComply.activated, geocomplySdk, setGeocomplySdk]);

  return geocomplySdk;
};
