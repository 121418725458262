import { ApplicationState } from '../types';

/* Login */

export const userLoginRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.login;

export const userSessionIdSelector = (state: ApplicationState) =>
  state.core.login.sessionId;

export const getAuthTokenRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.getAuthToken;

export const restoreAuthenticationRequestStateSelector = (
  state: ApplicationState,
) => state.core.login.requests.restoreAuthentication;

export const userTokenSelector = (state: ApplicationState) =>
  state.core.login.token;

/**
 * Returns true if the user tried to log in using its credential but need to
 * send a code as well to do so (MFA)
 */
export const isMFARequiredForLoginSelector = (state: ApplicationState) =>
  state.core.login.isMFARequiredForLogin;

/**
 * Return the exclusion date returned by the login handler in case an
 * excluded user attempted to login
 */
export const userExclusionDateSelector = (state: ApplicationState) =>
  state.core.login.requests.login?.exclusionDate;

/* Logout */

export const userLogoutRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.logout;

export const isLogoutAskedManuallySelector = (state: ApplicationState) =>
  state.core.login.isLogoutAskedManually;

/* Impersonation */

export const impersonationRequestStateSelector = (state: ApplicationState) =>
  state.core.login.requests.impersonate;

/* Player session */

export const playerSessionStartDateSelector = (state: ApplicationState) =>
  state.core.login.playerSessionStartDate;
