import { useLocation } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-i18n';

import {
  concatLocation,
  getPageNameFromLocation,
  getRoutePath,
  getSearchUrlWithoutPageNameFromLocation,
} from '../helpers';
import { MatchingPageNamesManifest, RouteList } from '../types';

/**
 * Read the 'pageName' query param and return the corresponding path. If
 * matchingPageNamesManifest is provided, the dictionary will be taken into
 * account when searching for a pagename.
 */
export const useRedirectionByPageName = (
  routes: RouteList<string>,
  matchingPageNamesManifest?: MatchingPageNamesManifest,
): string | null => {
  const location = useLocation();
  const { currentLocale: locale } = useI18n();
  const pageNameFromLocation = getPageNameFromLocation(location);

  const [codebasePageName] =
    (matchingPageNamesManifest || []).find(
      ([, databasePageName]) => databasePageName === pageNameFromLocation,
    ) || [];

  const routeForRedirect =
    (pageNameFromLocation && routes[pageNameFromLocation]) ||
    (codebasePageName && routes[codebasePageName]);

  return routeForRedirect
    ? concatLocation({
        ...location,
        pathname: getRoutePath(routeForRedirect, {}, locale),
        search: getSearchUrlWithoutPageNameFromLocation(location),
      })
    : null;
};
