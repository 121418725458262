import styled from 'styled-components';
import { space } from 'styled-system';

export const IconSvg = styled.svg`
  ${space}
  pointer-events: none;

  & path {
    fill: ${({ fill, theme }) => fill || theme.colors.text};
  }
`;
