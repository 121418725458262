import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateFailureRequestState,
  generateLoadingRequestState,
  generateSuccessRequestState,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { slugifySliderPayload } from './helpers';
import { SliderState } from './types';

export const initialState: SliderState = {
  slider: { groupName: null },
  requests: {
    getSlider: {},
  },
};

export const sliderReducer = (
  state: SliderState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.getSlider.request): {
        const slug = slugifySliderPayload(action.payload);
        draftState.requests.getSlider[slug] = generateLoadingRequestState();
        break;
      }

      case getType(actions.getSlider.failure): {
        const slug = slugifySliderPayload(action.payload.request);
        draftState.requests.getSlider[slug] = generateFailureRequestState(
          action.payload,
        );
        break;
      }

      case getType(actions.getSlider.success): {
        const slug = slugifySliderPayload(action.payload.request);
        draftState.requests.getSlider[slug] = generateSuccessRequestState();
        draftState.slider[slug] = action.payload;
        break;
      }

      case getType(actions.loggedInUser):
      case getType(actions.loggedOutUser):
      case getType(actions.switchLocale.success):
        return initialState;

      default: // Immer will automatically return the state
    }
  });
