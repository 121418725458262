import { MediaBreakPointNames, mediaBreakPoints } from './breakpoints';

const customMediaQuery = (minWidth: number) =>
  `@media (min-width: ${minWidth}px)`;

export type Media = { [x in MediaBreakPointNames]: string };

const initialMedia = {};

/**
 * Render the provided css code when the media breakpoint is reached
 */
export const media = mediaBreakPoints.reduce(
  (acc, bp) => ({
    ...acc,
    [bp.name]: customMediaQuery(bp.value),
  }),
  initialMedia as Media,
);
