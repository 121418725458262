import styled, { keyframes } from 'styled-components';

import { colors } from '@gaming1/g1-style';

export const HORIZONTAL_LOADER_WIDTH_IN_PX = 60;
const HORIZONTAL_LOADER_HEIGHT_IN_PX = 8;

const HORIZONTAL_CIRCLE_WIDTH_IN_PX = 24;

const BORDER_RADIUS_HORIZONTAL_LOADER_IN_PX = 8;

const WIDTH_TO_STOP_ANIMATION_IN_PX =
  HORIZONTAL_LOADER_WIDTH_IN_PX - HORIZONTAL_CIRCLE_WIDTH_IN_PX;

export const HorizontalLoaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: ${colors('neutrals', {
    index: 4,
  })};
  border-radius: ${BORDER_RADIUS_HORIZONTAL_LOADER_IN_PX}px;
  width: ${HORIZONTAL_LOADER_WIDTH_IN_PX}px;
  height: ${HORIZONTAL_LOADER_HEIGHT_IN_PX}px;
`;

const horizontalLoaderAnimation = keyframes`
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(${WIDTH_TO_STOP_ANIMATION_IN_PX}px)
  }


  100% {
    transform: translateX(0px)
  }
`;

export const MovingHorizontallyCircle = styled.div`
  background-color: ${colors('primary')};
  width: ${HORIZONTAL_CIRCLE_WIDTH_IN_PX}px;
  height: ${HORIZONTAL_LOADER_HEIGHT_IN_PX}px;
  border-radius: ${BORDER_RADIUS_HORIZONTAL_LOADER_IN_PX}px;
  animation: ${horizontalLoaderAnimation} ${2}s infinite ease-in-out;
`;
