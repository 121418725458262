import { createAction } from 'typesafe-actions';

export const userSearch = createAction(
  'core/user_search',
  (payload: { searchTerm: string }) => payload,
)();

export const setSearchHistory = createAction(
  'core/set_search_history',
  (payload: { history: string[] }) => payload,
)();
