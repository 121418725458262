import * as t from 'io-ts';

const userStatusIdsCodec = t.type({
  disabled: t.array(t.string),
  enabled: t.array(t.string),
});

export const userConsentMessagePayloadCodec = t.type({
  isConsentPolicyEnabled: t.boolean,
  purposesConsent: userStatusIdsCodec,
  vendorsConsent: userStatusIdsCodec,
});

export const consentPolicyCodec = t.null;
