export enum GeolocationReason {
  Deposit = 'Deposit',
  Withdrawal = 'Withdrawal',
  AccountOpening = 'Account Opening',
  Login = 'Login',
  // Sent when recalling as per GeoComply recommended recall interval
  SessionIntervalGeo = 'SessionIntervalGeo',
  // Sent when gaming session starts
  SessionStart = 'SessionStart',
}

export enum GeoComplyStatusCode {
  /** Operator’s license is not authorized by GeoComply server. */
  CLNT_ERROR_CLIENT_LICENSE_UNAUTHORIZED = 607,
  /**
   * Operator’s account is suspended by administrator at GeoComply Back Office site.
   * Please contact GeoComply representative to solve this problem.
   */
  CLNT_ERROR_CLIENT_SUSPENDED = 604,
  /**
   * Geolocation solution is disabled for this operator’s account by administrator at GeoComply
   * Back Office site. Please contact GeoComply representative to solve this problem.
   */
  CLNT_ERROR_DISABLED_SOLUTION = 605,
  /**
   * Invalid list of custom fields is provided to SDK.
   * This can happen if mandatory custom field is absent in the list,
   * or format of custom field value is invalid.
   */
  CLNT_ERROR_INVALID_CUSTOM_FIELDS = 609,
  /**
   * Invalid license data. License data is malformed or corrupted,
   * please try to request license from GeoComply license server
   */
  CLNT_ERROR_INVALID_LICENSE_FORMAT = 606,
  /**
   * License expired. By default, license expires each 24 hours.
   * Each operator’s app should process this error and request a new license from GeoComply License
   * service if it receives this error.
   */
  CLNT_ERROR_LICENSE_EXPIRED = 608,
  /**
   * Communication with PLC (local service) failed. The most likly cause of this is that
   * either some invalid data was sent to PLC or there are some problems with parsing
   * data from the PLC.
   */
  CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION = 613,
  /** The PLC is not installed on the operator's system */
  CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE = 612,
  /**
   * SDK is able to detect PLC (local service) installed on the user’s computer,
   * but installed version is not supported by the current version of the JS library.
   * The main possible reason of this error is that the required version of the service is not installed
   * on the user’s computer.
   */
  CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER = 615,
  /** Network connection from PLC to Server is not available. Please retry the connection later. */
  CLNT_ERROR_NETWORK_CONNECTION = 602,
  /** Signature verification of PLC support binaries fails. Means the binaries have been tampered with. */
  CLNT_ERROR_NOT_CERTIFIED_BINARIES = 601,
  /**
   * Operator’s app receives this error if it runs a new geolocation request while previous
   * geolocation request is in progress. The error is reported if requestGeolocation)
   * called before receiving onGeolocation or onError event from the previous geolocation request.
   */
  CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS = 614,
  /** The server communication failed. */
  CLNT_ERROR_SERVER_COMMUNICATION = 603,
  /** The “create transaction” procedure took more time than what the configuration allows. */
  CLNT_ERROR_TRANSACTION_TIMEOUT = 620,
  /** Unexpected error occurs. */
  CLNT_ERROR_UNEXPECTED = 600,
  /**
   * Web SDK requires some parameters to function properly and those parameters
   * are missing or provided the wrong values.
   * The solution is to read error message thoroughly and specify the necessary parameters for the functions.
   */
  CLNT_ERROR_WRONG_OR_MISSING_PARAMETER = 699,
  /**
   * Geolocation is set to approximative in the user device
   */
  CLNT_ERROR_APPROXIMATE_GEOLOCATION = 637,
  /** No error. */
  CLNT_OK = 0,
}

export enum GeolocationCustomErrorCode {
  /** A request was made but the native app is in the background */
  APP_IN_BACKGROUND = 701,
  /** A request was made but there is no Gecoomply license in the store */
  NO_GC_LICENSE = 702,
  /** A request was made but the Geocomply web SDK is not found in the window object */
  NO_GC_SDK = 703,
  /** The location permission was denied by the user, causing the app not to be able to geolocate correctly */
  LOCATION_PERMISSION_DENIED = 704,
}

/**
 * The name of the businessSessionId key to set in GeoComply
 */
export const BUSINESS_SESSION_ID_KEY = 'BusinessSessionId';
