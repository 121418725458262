import { AppConfig } from '@gaming1/g1-config';
import { createRoute, createRouteList } from '@gaming1/g1-routing';

/** Public core routes getter */
export const getCorePublicRoutes = (config: AppConfig) =>
  createRouteList({
    home: createRoute('/'),
    downloadApp: createRoute('/download-app', config.core.geoComply.activated),
  });

export type CorePublicRoutes = ReturnType<typeof getCorePublicRoutes>;
