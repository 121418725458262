import { FailurePayload } from './errors';
import {
  AnyResult,
  PaginatedRequestState,
  PaginationState,
  RemoteData,
  RequestState,
} from './types';

/**
 * Standard request state for redux state
 * - `errorCode` will be undefined but can also be a number
 * - `errorMessage` will be undefined but can also be a string
 * - `status` will be 'NotAsked' but can also be another string from
 *   the `RemoteData` enum
 * - for the `result` property:
 *   - if `initialResults` is null or undefined, the property will not exists
 *   - if `initialResults` is anything else, result will have its value
 */
export function generateInitialRequestState(
  initialResults?: null,
): RequestState<null>;
export function generateInitialRequestState<Results extends AnyResult>(
  initialResults: Results,
): RequestState<Results>;
export function generateInitialRequestState<Results extends AnyResult>(
  initialResults?: Results | null,
) {
  const baseState = {
    errorCode: undefined,
    errorMessage: undefined,
    status: RemoteData.NotAsked,
  };

  return initialResults == null
    ? {
        ...baseState,
      }
    : {
        ...baseState,
        result: initialResults,
      };
}

/** Standard paginated request state for redux state */
export function generatePaginatedInitialRequestState(
  initialResults?: null,
): PaginatedRequestState<null>;
export function generatePaginatedInitialRequestState<Results extends AnyResult>(
  initialResults: Results,
): PaginatedRequestState<Results>;
export function generatePaginatedInitialRequestState<Results extends AnyResult>(
  initialResults?: Results | null,
) {
  const pagination = {
    PageNumber: 0,
    PageSize: 0,
    Total: 0,
  };

  return initialResults == null
    ? {
        ...generateInitialRequestState(null),
        pagination,
      }
    : {
        ...generateInitialRequestState(initialResults),
        pagination,
      };
}

/**
 * Helper for reducers of RequestState
 * Update the state after an async action of type "request"
 * ⚠️ Meant to be used only inside immer produce function
 *
 */
export const produceLoadingState = (draftState: RequestState<null>) => {
  /* eslint-disable no-param-reassign */
  draftState.errorCode = undefined;
  draftState.errorMessage = undefined;
  draftState.status = RemoteData.Loading;
  /* eslint-enable no-param-reassign */
};

/**
 * Helper for reducers of RequestState -
 * Generate an initial request state (loading)
 */
export function generateLoadingRequestState(result?: null): RequestState<null>;
export function generateLoadingRequestState<Results extends AnyResult>(
  result: Results,
): RequestState<Results>;
export function generateLoadingRequestState<Results extends AnyResult>(
  result?: Results | null,
) {
  return result
    ? {
        errorCode: undefined,
        errorMessage: undefined,
        result,
        status: RemoteData.Loading,
      }
    : {
        errorCode: undefined,
        errorMessage: undefined,
        status: RemoteData.Loading,
      };
}

/**
 * Helper for reducers of RequestState
 * Update the state after an async action of type "success"
 * ⚠️ Meant to be used only inside immer produce function
 *
 */
export function produceSuccessState(
  draftState: RequestState<null>,
  result?: null,
  pagination?: undefined,
): void;
export function produceSuccessState(
  draftState: PaginatedRequestState<null>,
  result: null,
  pagination: PaginationState,
): void;
export function produceSuccessState<
  State extends RequestState<Result>,
  Result extends AnyResult,
>(draftState: State, result: Result, pagination?: undefined): void;
export function produceSuccessState<
  State extends PaginatedRequestState<Result>,
  Result extends AnyResult,
>(draftState: State, result: Result, pagination: PaginationState): void;
export function produceSuccessState<
  State extends RequestState<Result> | PaginatedRequestState<Result>,
  Result extends AnyResult,
>(
  draftState: State,
  result?: Result | null,
  pagination?: PaginationState,
): void {
  /* eslint-disable no-param-reassign */
  draftState.errorMessage = undefined;
  draftState.status = RemoteData.Success;
  if ('result' in draftState && result) {
    (draftState as unknown as RequestState<NonNullable<Result>>).result =
      result as NonNullable<Result>;
  }
  if ('pagination' in draftState && pagination) {
    (draftState as PaginatedRequestState<Result>).pagination = pagination;
  }
  /* eslint-enable no-param-reassign */
}

/*
export const produceSuccessState = <
  State extends RequestState<Result>,
  Result = null
>(
  draftState: State,
  result?: Result,
) => {
  draftState.errorMessage = undefined;
  draftState.status = RemoteData.Success;
  if ('result' in draftState && result) {
    (draftState as RequestState<{}>).result = result;
  }
};
*/

/**
 * Helper for reducers of RequestState -
 * Generate an initial request state (success)
 */
export function generateSuccessRequestState(
  result?: null,
  pagination?: undefined,
): RequestState<null>;
export function generateSuccessRequestState(
  result: null,
  pagination: PaginationState,
): PaginatedRequestState<null>;
export function generateSuccessRequestState<Results extends AnyResult>(
  result: Results,
  pagination?: undefined,
): RequestState<Results>;
export function generateSuccessRequestState<Results extends AnyResult>(
  result: Results,
  pagination: PaginationState,
): PaginatedRequestState<Results>;

export function generateSuccessRequestState<Results extends AnyResult>(
  result?: Results | null,
  pagination?: PaginationState,
):
  | RequestState<Results>
  | PaginatedRequestState<Results>
  | RequestState<null>
  | PaginatedRequestState<null> {
  return {
    errorCode: undefined,
    errorMessage: undefined,
    ...(result && { result }),
    ...(pagination && { pagination }),
    status: RemoteData.Success,
  };
}

/**
 * Helper for reducers of RequestState
 * Update the state after an async action of type "failure"
 * ⚠️ Meant to be used only inside immer produce function
 *
 */
export const produceFailureState = (
  draftState: RequestState<null>,
  payload: FailurePayload,
) => {
  /* eslint-disable no-param-reassign */
  draftState.errorCode = payload.status;
  draftState.errorMessage = payload.errorMessage;
  draftState.status = RemoteData.Error;
  /* eslint-enable no-param-reassign */
};

/**
 * Helper for reducers of RequestState -
 * Generate an initial request state (failure)
 */
export function generateFailureRequestState(
  payload: FailurePayload,
  result?: null,
): RequestState<null>;
export function generateFailureRequestState<Results extends AnyResult>(
  payload: FailurePayload,
  result: Results,
): RequestState<Results>;
export function generateFailureRequestState<Results extends AnyResult>(
  payload: FailurePayload,
  result?: Results | null,
) {
  return result
    ? {
        errorCode: payload.status,
        errorMessage: payload.errorMessage,
        result,
        status: RemoteData.Error,
      }
    : {
        errorCode: payload.status,
        errorMessage: payload.errorMessage,
        status: RemoteData.Error,
      };
}

type SimpleValue =
  | string
  | number
  | boolean
  | undefined
  | null
  | number[]
  | string[];

/** The request action payload */
type SimplePayload = {
  [key: string]: SimpleValue;
};

const stringifyPayloadValue = (value: SimpleValue): string => {
  if (typeof value === 'undefined') {
    return 'undefined';
  }
  if (value === null) {
    return 'null';
  }
  if (Array.isArray(value)) {
    return value.join('-');
  }
  return value.toString();
};

/**
 * Returns a string created from a simple request action payload. Paylod
 * containing objects or arrays are not supported
 * @param payload the payload of the request action
 * @param blackList an optiona array of keys not to be included in the slug
 */
export const slugifyPayload = <P extends SimplePayload>(
  payload: P,
  blackList: (keyof P)[] = [],
): string => {
  const entries = Object.entries(payload);
  if (!entries.length) {
    return 'default';
  }
  return entries
    .filter(([key]) => !blackList.includes(key))
    .sort(([keyA], [keyB]) => (keyA > keyB ? 1 : -1))
    .map(([key, value]) => `${key}:${stringifyPayloadValue(value)}`)
    .join('-')
    .toLowerCase();
};
