import React, { FC } from 'react';
import { PositionProps, SpaceProps } from 'styled-system';

import { NotificationContainerBox } from './styles';

export const NOTIFICATION_CONTAINER_CLASSNAME = 'notification-container';

/**
 * When rendered, notifications will be positioned inside this component.
 * Use it only for pages that doesn't render Page.Wrapper as there is already
 * a NotificationContainer inside it
 */
export const NotificationContainer: FC<PositionProps & SpaceProps> = ({
  ...props
}) => (
  <NotificationContainerBox
    className={NOTIFICATION_CONTAINER_CLASSNAME}
    {...props}
  />
);
