import React, { FC, ReactNode } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-i18n';

import { concatLocation } from '../../helpers';

export const MultiLanguageRouter: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();
  const { availableLanguages, currentLocale } = useI18n();

  const separatedLanguages = availableLanguages.join('|');
  const pathWithLanguages = `/:lng(${separatedLanguages})`;

  return (
    <Switch>
      <Route path={pathWithLanguages}>{children}</Route>
      <Route>
        <Redirect to={concatLocation(location, currentLocale)} />
      </Route>
    </Switch>
  );
};
