export { BOTTOM_NAV_HEIGHT, MINIMIZED_BOTTOM_NAV_HEIGHT } from '@gaming1/g1-ui';

export const TOP_NAV_SMALL_HEIGHT = 58;
export const TOP_NAV_LARGE_HEIGHT = 80;

export const HEADER_HEIGHT = 56;
export const HEADER_LARGE_HEIGHT = 113;
export const HEADER_LOGO_HEIGHT = 40;

export const TOP_BAR_HEIGHT = 34;

export const FOOTER_HEADER_HEIGHT = 96;
export const LIGHT_FOOTER_HEIGHT = 56;

export const SCROLL_NAV_HEIGHT = 66;
