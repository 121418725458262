import { createSelector } from 'reselect';

import { STORE_SUPPORT_WIDGET_NAME } from './constants';
import { ApplicationState } from './types/store';

const supportWidgetStateSelector = (state: ApplicationState) =>
  state[STORE_SUPPORT_WIDGET_NAME];

/** Returns the current jwt token value */
export const supportJwtTokenSelector = createSelector(
  supportWidgetStateSelector,
  (state) => state.jwtToken,
);
