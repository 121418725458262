import React, {
  createContext,
  forwardRef,
  ImgHTMLAttributes,
  useContext,
} from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';

import {
  OptimizationImageOptions,
  useGetOptimizedImageSourcePath,
} from '../../../shared';

type ResourcePath<T> = T | ({ [locale in LocaleCode]?: T } & { default: T });

export type ImageProps = {
  /** alt of the Image */
  alt: string;
  /** url of the Image */
  url: ResourcePath<string | null>;
  /** unique id of the image */
  id: string;
};

type ImageContextType = { [id: string]: ResourcePath<string> };

export const ImageContext = createContext<ImageContextType>({});

/**
 * Display an image that can be overwritten thanks to id and ImageContext
 *  Also accept multiple images for different locales
 */
export const Image = forwardRef<
  HTMLImageElement,
  ImageProps &
    ImgHTMLAttributes<HTMLImageElement> & {
      /** Options for image optimization */
      optimizationOptions?: OptimizationImageOptions;
    }
>(({ alt, id, loading = 'eager', optimizationOptions, url, ...rest }, ref) => {
  const { currentLocale } = useI18n();
  const getOptimizedImageSourcePath = useGetOptimizedImageSourcePath();

  /* Overwrite context */
  const contextImages = useContext(ImageContext);

  /* Overwrite by id */
  const imageById = contextImages[id] || url;

  const imagePath =
    typeof url === 'string' ? url : url && (url[currentLocale] || url.default);

  const imagePathById =
    typeof imageById === 'string'
      ? imageById
      : imageById && (imageById[currentLocale] || imageById.default);

  return imagePath !== null && imagePathById !== null ? (
    <img
      alt={alt}
      data-testid={`${id}-image`}
      loading={loading}
      ref={ref}
      src={getOptimizedImageSourcePath(
        imagePathById ?? imagePath,
        optimizationOptions,
      )}
      {...rest}
    />
  ) : null;
});
