import React, {
  ContextType,
  FC,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Helmet, HelmetTags } from 'react-helmet-async';
import { interval, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { createGlobalStyle } from 'styled-components';

import { ConfigContext } from '@gaming1/g1-config';
import { media, spaces } from '@gaming1/g1-style';

import { logger } from '../../logger';

import { CanShowWidget } from './types';
import { ZendeskWidgetClassic } from './ZendeskWidgetClassic';
import { ZendeskWidgetContext } from './ZendeskWidgetContext';
import { ZendeskWidgetMessenger } from './ZendeskWidgetMessenger';

const ZendeskWidgetStyle = createGlobalStyle`
  iframe#launcher, iframe#webWidget {
    margin-bottom: 0 !important;
    left: 0 !important;
    ${media.xl} {
      bottom: ${spaces('xs')} !important;
    }
}`;

export const ZE_SCRIPT_ID = 'ze-snippet';

const WIDGET_TYPE_INTERVAL_IN_MS = 100;
export const WIDGET_TYPE_TIMEOUT_IN_MS = 5000;

type WindowWithZendeskSDKType = Window & {
  zE?: { widget: 'classic' | 'messenger' };
};
const windowWithZendeskSDK = window as WindowWithZendeskSDKType;

/**
 * Add the zendesk widget
 */
export const ZendeskWidgetProvider: FC<{ children?: ReactNode }> = memo(
  ({ children }) => {
    const config = useContext(ConfigContext);

    const [shouldLoadWidget, setShouldLoadWidget] = useState(false);
    const [isWidgetLoaded, setIsWidgetLoaded] = useState(false);
    const [shouldWidgetBeVisible, setShouldWidgetBeVisible] = useState(false);
    const [widgetType, setWidgetType] = useState<
      'classic' | 'messenger' | undefined
    >(undefined);

    const zendeskWidgetTypeRef = useRef<CanShowWidget>(null);

    /**
     * Checks whether the widget type is `webwidget` (classic)
     * or `messenger`
     */
    useEffect(() => {
      if (isWidgetLoaded) {
        const zEWidgetTypeSubscription = interval(WIDGET_TYPE_INTERVAL_IN_MS)
          .pipe(
            map(() => windowWithZendeskSDK?.zE?.widget),
            takeWhile((zEWidgetType) => zEWidgetType === undefined, true),
            takeUntil(
              timer(WIDGET_TYPE_TIMEOUT_IN_MS).pipe(
                tap(() => {
                  throw new Error(
                    `Couldn't manage to set the Zendesk widget type in time (${
                      WIDGET_TYPE_TIMEOUT_IN_MS / 1000
                    }s)`,
                  );
                }),
              ),
            ),
          )
          .subscribe({
            next: (zEWidgetType) => {
              if (zEWidgetType) {
                logger.info('[Zendesk] Widget type is :', zEWidgetType);
                setWidgetType(zEWidgetType);
              }
            },
            error: (err: Error) => {
              logger.error('[Zendesk] [Error]', err.message);
            },
          });

        return () => zEWidgetTypeSubscription.unsubscribe();
      }

      return () => undefined;
    }, [isWidgetLoaded]);

    /**
     * Show and open the widget.
     * This hack is used to call a method from child element
     * because `showWidget` implements its own logic per Zendesk widget type
     * but has to be available in the context so we can call it from anywhere
     * and using any widget type (`classic` or `messenger`).
     *
     * source : https://stackoverflow.com/a/69464092/6083234 for the logic
     * source : https://stackoverflow.com/a/66363668/6083234 for the typing
     */
    const showWidget = useCallback(() => {
      setShouldWidgetBeVisible(true);

      if (!shouldLoadWidget) {
        setShouldLoadWidget(true);
      } else {
        zendeskWidgetTypeRef?.current?.showWidget();
      }
    }, [shouldLoadWidget]);

    /** Indicates whether the widget is loaded or not */
    const loadWidget = useCallback(() => {
      setShouldLoadWidget(true);
    }, []);

    /** Close and hide the widget */
    const hideWidget = useCallback(() => {
      setShouldWidgetBeVisible(false);
    }, []);

    /** Handle the <script> load event */
    const handleScriptInject = ({ scriptTags }: HelmetTags) => {
      if (scriptTags && scriptTags.length) {
        const zdTag = scriptTags.find(
          (scriptTag) => scriptTag.id === ZE_SCRIPT_ID,
        );

        if (zdTag) {
          zdTag.onload = () => {
            setIsWidgetLoaded(true);
          };
        }
      }
    };

    const zendeskWidgetContextValue: ContextType<typeof ZendeskWidgetContext> =
      useMemo(
        () => ({ showWidget, hideWidget, loadWidget, isWidgetLoaded }),
        [hideWidget, isWidgetLoaded, loadWidget, showWidget],
      );

    return (
      <>
        {config.core.zendeskWidgetKey && shouldLoadWidget && (
          <>
            <ZendeskWidgetStyle />
            <Helmet
              onChangeClientState={(_, addedTags) =>
                handleScriptInject(addedTags)
              }
            >
              <script
                id={ZE_SCRIPT_ID}
                type="text/javascript"
                data-testid={ZE_SCRIPT_ID}
                src={`https://static.zdassets.com/ekr/snippet.js?key=${config.core.zendeskWidgetKey}`}
                defer
              />
            </Helmet>
          </>
        )}
        <ZendeskWidgetContext.Provider value={zendeskWidgetContextValue}>
          {widgetType === 'classic' && (
            <ZendeskWidgetClassic
              ref={zendeskWidgetTypeRef}
              shouldWidgetBeVisible={shouldWidgetBeVisible}
            />
          )}
          {widgetType === 'messenger' && (
            <ZendeskWidgetMessenger
              ref={zendeskWidgetTypeRef}
              shouldWidgetBeVisible={shouldWidgetBeVisible}
            />
          )}
          {children}
        </ZendeskWidgetContext.Provider>
      </>
    );
  },
);
