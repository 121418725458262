export const IS_REACT_NATIVE =
  typeof navigator !== 'undefined' && navigator.product === 'ReactNative';

export type ElevationLevel = 0 | 1 | 2 | 3 | 4; // keyof Theme['shadows']

// Numbers taken from https://ethercreative.github.io/react-native-shadow-generator/
export const ELEVATION_SHADOW_VALUES: Record<
  ElevationLevel,
  { height: number; opacity: number; radius: number }
> = {
  '0': {
    height: 0,
    opacity: 0,
    radius: 0,
  },
  '1': {
    height: 1,
    opacity: 0.18,
    radius: 1,
  },
  '2': {
    height: 1,
    opacity: 0.2,
    radius: 1.41,
  },
  '3': {
    height: 1,
    opacity: 0.22,
    radius: 2.22,
  },
  '4': {
    height: 2,
    opacity: 0.23,
    radius: 2.62,
  },
};
