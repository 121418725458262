/* ! This file can only import:
  - AppConfig from @gaming1/g1-config
  - Any get*PublicRoutes from g1 api packages
  - createParamRoute, createRoute and createRouteList from @gaming1/g1-routing
*/
import { AppConfig } from '@gaming1/g1-config';
import { getCorePublicRoutes } from '@gaming1/g1-core-api';
import { createRouteList } from '@gaming1/g1-routing';

export const getCoreRoutes = (config: AppConfig) =>
  createRouteList({
    ...getCorePublicRoutes(config),
  });

export type CoreRoutes = ReturnType<typeof getCoreRoutes>;
