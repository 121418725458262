import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { getDeployEnv, getWrapperEnv } from '@gaming1/g1-env';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import { CoreActions } from '../types';

import * as actions from './actions';
import { formatGetDatabaseRoutes } from './format';
import { CommonCoreState } from './types';

export const initialState: CommonCoreState = {
  creationSection: 'default',
  databaseRoutes: null,
  hasUserAlreadyLoggedIn: false,
  init: false,
  isDebugModeEnabled:
    getWrapperEnv() === 'rn' && getDeployEnv() === 'development',
  isInMobileApp: false,
  lastVisit: null,
  minimumMobileAppVersion: null,
  minimumAndroidVersion: null,
  minimumIosVersion: null,
  mobileAppPlatformOS: 'web',
  mobileBuildNumber: '',
  mobileBuildVersion: '',
  requests: {
    getDatabaseRoutes: generateInitialRequestState(),
    getShellParameters: generateInitialRequestState(),
  },
  section: 'default',
  shellParameters: null,
};

export const commonCoreReducer = (
  state: CommonCoreState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.initApp):
        draftState.init = true;
        draftState.lastVisit = action.payload.lastVisitTimestamp;
        draftState.hasUserAlreadyLoggedIn =
          action.payload.hasUserAlreadyLoggedIn;
        draftState.isInMobileApp = action.payload.isInMobileApp;
        break;

      case getType(actions.switchSection):
        draftState.section = action.payload;
        draftState.creationSection =
          action.payload !== 'registration'
            ? action.payload
            : state.creationSection;
        break;

      case getType(actions.getDatabaseRoutes.request):
        produceLoadingState(draftState.requests.getDatabaseRoutes);
        break;

      case getType(actions.getDatabaseRoutes.failure):
        produceFailureState(
          draftState.requests.getDatabaseRoutes,
          action.payload,
        );
        break;

      case getType(actions.getDatabaseRoutes.success):
        produceSuccessState(draftState.requests.getDatabaseRoutes);
        draftState.databaseRoutes = formatGetDatabaseRoutes(action.payload);
        break;

      case getType(actions.getShellParams.request):
        produceLoadingState(draftState.requests.getShellParameters);
        break;

      case getType(actions.getShellParams.failure):
        produceFailureState(
          draftState.requests.getShellParameters,
          action.payload,
        );
        break;

      case getType(actions.getShellParams.success):
        produceSuccessState(draftState.requests.getShellParameters);
        draftState.shellParameters = action.payload;
        draftState.minimumMobileAppVersion =
          action.payload.MinimumMobileAppVersion;
        draftState.minimumAndroidVersion = action.payload.MinimumAndroidVersion;
        draftState.minimumIosVersion = action.payload.MinimumIOsVersion;
        break;

      case getType(actions.initMobileApp):
        Object.assign(draftState, action.payload);
        break;

      case getType(actions.setDebugMode):
        draftState.isDebugModeEnabled = action.payload;
        break;

      default: // Immer will automatically return the state
    }
  });
