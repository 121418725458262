import styled, { css } from 'styled-components';

import { elevation, spaces } from '@gaming1/g1-style';

export const SuspenseLoaderContainer = styled.div<{ minHeight?: number }>`
  ${elevation(0)};
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  min-height: 100%;
  padding: ${spaces('xxl')} 0;
  width: 100%;

  ${({ minHeight }) =>
    minHeight !== undefined &&
    css`
      min-height: ${minHeight}px;
    `}
`;
