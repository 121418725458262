import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';

import { useI18n } from '@gaming1/g1-core';
import {
  getCoreRouter,
  NotFoundPage,
  RouterWrapper,
  RouteWrapper,
  useCoreRoutes,
} from '@gaming1/g1-core-web';
import { ErrorBoundary } from '@gaming1/g1-error-management/web';
import { RouteList } from '@gaming1/g1-routing';
import { lazifyComponent } from '@gaming1/g1-utils';

const LazyHomePage = lazifyComponent(
  'HomePage',
  () => import('./pages/HomePage'),
);

export const Router: FC = () => {
  const { values } = useI18n();

  const coreRoutes = useCoreRoutes();

  // Those routes will be accessible by their page name ('/?pagename=deposit' -> redirect to '/deposit')
  const routes: RouteList<string> = useMemo(
    () => ({
      ...coreRoutes,
    }),
    [coreRoutes],
  );

  return (
    <ErrorBoundary>
      <RouterWrapper routes={routes}>
        <Helmet titleTemplate={`%s | ${values.siteName}`} />
        <Switch>
          {/* HomePage + redirection by page name */}
          <Route exact path={`/:locale${coreRoutes.home.path}`}>
            <RouteWrapper section="default">
              <LazyHomePage />
            </RouteWrapper>
          </Route>
          {/* Imported routers */}
          {getCoreRouter(coreRoutes)}
          {/* Nothing found or catched, this is a 404 */}
          <Route path={undefined}>
            <RouteWrapper section="default">
              <NotFoundPage />
            </RouteWrapper>
          </Route>
        </Switch>
      </RouterWrapper>
    </ErrorBoundary>
  );
};
