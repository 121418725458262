import { createSelector } from 'reselect';

import { RemoteData } from '@gaming1/g1-utils';

import { ApplicationState } from '../types';

const geolocationStateSelector = (state: ApplicationState) =>
  state.core.geolocation;

/** Get the License used to init GeoComply */
export const geoComplyLicenseKeySelector = createSelector(
  geolocationStateSelector,
  (state) => state.geoComplyLicenseKey,
);

/**
 * Show the geolocation loading toast if :
 *
 * - No geolocation information exist and a check on the cached geolocation is performing (=> at Authentication)
 * OR
 * - (Previous) Geolocation information exist, are not valid and a geolocation request is performing
 *   (either the request to the PLC or the decrypt request)
 *
 * IF showing the loading toast is needed even when not authenticated, add this to the condition after the first '||' statement
 *
 * ((!currentGeolocation || (currentGeolocation && !currentGeolocation.IsValid)) &&
 *   requestGeolocationStatus === RemoteData.Loading) ||
 *
 * and make sure to adapt the relevant unit tests
 */
export const shouldShowGeolocationCheckIndicationSelector = createSelector(
  geolocationStateSelector,
  (state) => {
    const { currentGeolocation } = state;
    const checkIsCachedGeolocationValidStatus =
      state.requests.checkIsCachedGeolocationValid.status;
    const requestGeolocationStatus = state.requests.requestGeolocation.status;
    const decryptGeolocationStatus = state.requests.decryptGeolocation.status;

    return !!(
      (!currentGeolocation &&
        checkIsCachedGeolocationValidStatus === RemoteData.Loading) ||
      (currentGeolocation &&
        !currentGeolocation.IsValid &&
        (requestGeolocationStatus === RemoteData.Loading ||
          decryptGeolocationStatus === RemoteData.Loading))
    );
  },
);

/**
 * Returns whether the current geolocation exists and is fetched or not
 */
export const isCurrentGeolocationDataValid = createSelector(
  geolocationStateSelector,
  ({ currentGeolocation }) => !!currentGeolocation?.IsValid,
);

/** The most recently stored geolocation information */
export const currentGeolocationSelector = createSelector(
  geolocationStateSelector,
  (state) => state.currentGeolocation,
);

/** Geolocation trouble shooters */
export const geolocationTroubleshootersSelector = createSelector(
  geolocationStateSelector,
  (state) => state.troubleshooters || [],
);

// State requests
export const connectionToGeoComplyRequestStateSelector = createSelector(
  geolocationStateSelector,
  (state) => state.requests.connectToGeoComply,
);

export const geoComplyLicenseKeyRequestStateSelector = createSelector(
  geolocationStateSelector,
  (state) => state.requests.getGeoComplyLicenseKey,
);

export const geolocationRequestStateSelector = createSelector(
  geolocationStateSelector,
  (state) => state.requests.requestGeolocation,
);

export const checkIsCachedGeolocationValidRequestStateSelector = createSelector(
  geolocationStateSelector,
  (state) => state.requests.checkIsCachedGeolocationValid,
);

export const decryptGeolocationRequestStateSelector = createSelector(
  geolocationStateSelector,
  (state) => state.requests.decryptGeolocation,
);
