/* Needed for mobile safari */
import 'intersection-observer';
import 'raf/polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { setLoggerLevel } from '@gaming1/g1-logger';

import { App } from './App';
import './devTools';

const rootEl = document.getElementById('root');
if (!rootEl) {
  throw new Error('Could find root element #root');
}
const root = createRoot(rootEl);

// Set initial log level based on url
setLoggerLevel();

root.render(<App />);
