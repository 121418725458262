import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { TrackingState } from './types';

export const initialState: TrackingState = {
  blackBoxes: {
    ioBlackBox: null,
    fpBlackBox: null,
  },
};

export const trackingReducer = (
  state: TrackingState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.setBlackBoxes):
        draftState.blackBoxes.ioBlackBox = action.payload.ioBlackBox;
        draftState.blackBoxes.fpBlackBox = action.payload.fpBlackBox || null;
        break;

      default: // Immer will automatically return the state
    }
  });
