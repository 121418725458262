import * as t from 'io-ts';

/* CONVENIENCE TYPES */

export type SerializableScalar = boolean | Date | null | number | string;
export type SerializableObject = {
  [key: string]: SerializableArray | SerializableObject | SerializableScalar;
};
export type SerializableArray =
  | SerializableArray[]
  | SerializableObject[]
  | SerializableScalar[];
export type Serializable =
  | SerializableScalar
  | SerializableObject
  | SerializableArray;

/* MAPPED TYPES */

// could also be found here https://github.com/piotrwitek/utility-types

// From https://stackoverflow.com/questions/47914536/use-partial-in-nested-property-with-typescript
/** Partial<T> utility but nested */
export type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

// From https://github.com/Microsoft/TypeScript/issues/20965#issuecomment-354858633
/** Extract types contained in an array */
export type ValuesOf<T extends U[] | readonly U[], U> = T[number];

/** Partial<T> but only for some properties listed in K */
export type ApplyPartial<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Omit<T, K> & Partial<Pick<T, K>>;

/** Required<T> but only for some properties listed in K */
export type ApplyRequired<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Omit<T, K> & Required<Pick<T, K>>;

/** Type an object with keys from T but not from U */
export type Only<T, U> = {
  [P in keyof T]: T[P];
} & {
  [P in keyof U]?: never;
};

/** Type an object with either keys from T or from U */
export type Either<T, U> = Only<T, U> | Only<U, T>;

/* ACTIONS */

// https://github.com/redux-utilities/flux-standard-action/blob/master/src/index.d.ts

/**
 * A Flux Standard action with optional payload and metadata properties.
 */
export interface FluxStandardAction<
  Type extends string = string,
  Payload = undefined,
  Meta = undefined,
> {
  /**
   * The `type` of an action identifies to the consumer the nature of the action that has occurred.
   * Two actions with the same `type` MUST be strictly equivalent (using `===`)
   */
  type: Type;
  /**
   * The optional `payload` property MAY be any type of value.
   * It represents the payload of the action.
   * Any information about the action that is not the type or status of the action should be part of the `payload` field.
   * By convention, if `error` is `true`, the `payload` SHOULD be an error object.
   * This is akin to rejecting a promise with an error object.
   */
  payload?: Payload;
  /**
   * The optional `error` property MAY be set to true if the action represents an error.
   * An action whose `error` is true is analogous to a rejected Promise.
   * By convention, the `payload` SHOULD be an error object.
   * If `error` has any other value besides `true`, including `undefined`, the action MUST NOT be interpreted as an error.
   */
  error?: boolean;
  /**
   * The optional `meta` property MAY be any type of value.
   * It is intended for any extra information that is not part of the payload.
   */
  meta?: Meta;
}
/**
 * An extension of the Flux Standard action that represents an action containing an error as its payload.
 */
export interface ErrorFluxStandardAction<
  Type extends string = string,
  CustomErr extends Error = Error,
  Meta = undefined,
> extends FluxStandardAction<Type, CustomErr, Meta> {
  /**
   * The required `error` property MUST be set to `true` if the action represents an error.
   */
  error: true;
}

/* STATE */

/** Different status of a API request. Inspired by Elm RemoteData type */
export enum RemoteData {
  NotAsked = 'NotAsked',
  Loading = 'Loading',
  Error = 'Error',
  Success = 'Success',
}

/** Simple object to store entities. The key is the id of the entity */
export type EntityOf<T> = {
  [id: string]: T;
};
/** Results of a normalized request stored in the request state */
export type AnyResult =
  | null
  | number
  | number[]
  | string[]
  | Record<string, number[] | string[] | number | null>;

/**
 * Default network request redux state. For API that don't return any useful
 * data, result can be typed to "null": RequestState<null>
 */
export type RequestState<Result extends AnyResult = null> = Result extends null
  ? {
      /** status code from the request */
      errorCode: number | undefined;
      /** localisation key to show to the user */
      errorMessage: string | undefined;
      /** Status of the request */
      status: RemoteData;
    }
  : {
      /** status code from the request */
      errorCode: number | undefined;
      /** localisation key to show to the user */
      errorMessage: string | undefined;
      /** id(s) of the entities sent back from the request */
      result: Result;
      /** Status of the request */
      status: RemoteData;
    };

/** Pagination metadata from the API */
export type PaginationState = {
  PageNumber: number;
  PageSize: number;
  Total: number;
};

/** List of generic items + pagination state */
export type PaginationList<T> = {
  list: T[];
  pagination: PaginationState;
};

/** Network request state + pagination */
export type PaginatedRequestState<Result extends AnyResult = string[]> =
  RequestState<Result> & {
    pagination: PaginationState;
  };

/** Object with partial Status */
export type PartialStatus<T> = T extends { Status: unknown }
  ? Omit<T, 'Status'> & Partial<Pick<T, 'Status'>>
  : T & { Status?: number };

/** Object with omitted Status */
export type OmitStatus<T> = T extends { Status: unknown }
  ? Omit<T, 'Status'>
  : T;

/** Codec object with omitted Status */
export type CodecTypeWithoutStatus<T extends t.Any> = OmitStatus<t.TypeOf<T>>;

/** Unit available for formatDistanceStrict */
export type TimeDistanceUnit =
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'month'
  | 'year';

export type WindowOrientation = 'portrait' | 'landscape';

// For now we'll use isActionOf([asyncActions.request, asyncActions.failure, asyncActions.success]);
// See https://github.com/piotrwitek/typesafe-actions/issues/95

/*
export type SimpleAsyncAction = { getType: () => string };

export type AsyncActionStates = 'request' | 'failure' | 'success';

export type SimpleAsyncActions = {
  [k in AsyncActionStates]: SimpleAsyncAction
};

export const isFromAsyncAction = (asyncActions: SimpleAsyncActions) => (
  action: FluxStandardAction,
) =>
  Object.values(asyncActions).some(
    (asyncAction) => asyncAction.getType() === action.type,
  );
*/
