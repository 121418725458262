import URL from 'url-parse';

import { LocaleCode } from '@gaming1/g1-config';

import { DatabaseRoutes } from './codecs';
import { DatabaseRoutesOriginAndPaths } from './types';

const DATABASE_ROUTE_PARAMETER_REGEX = /\/?:([a-zA-Z]+?)(\/|$)/g;
const DATABASE_ROUTE_OPTIONAL_PARAMETER_REGEX = /(\/\(|\(\/)?:([a-zA-Z]+?)\)/g;
const DATABASE_ROUTE_CATCH_ALL_PARAMETER_REGEX = /\*(.+)$/g;

export const formatGetDatabaseRoutes = (databaseRoutes: DatabaseRoutes) => {
  const formattedDatabaseRoutes: DatabaseRoutesOriginAndPaths = {};
  Object.entries(databaseRoutes).forEach(([pageName, routes]) => {
    const { origin } = new URL(Object.values(routes)[0]);
    const formattedRoutes: Partial<Record<LocaleCode, string | undefined>> = {};
    Object.entries(routes).forEach(([locale, route]) => {
      formattedRoutes[locale as LocaleCode] = route
        .replace(origin, '')
        .replace(DATABASE_ROUTE_PARAMETER_REGEX, '/:$1')
        .replace(DATABASE_ROUTE_OPTIONAL_PARAMETER_REGEX, '/:$2?')
        .replace(DATABASE_ROUTE_CATCH_ALL_PARAMETER_REGEX, '/:$1*');
    });
    formattedDatabaseRoutes[pageName] = { origin, paths: formattedRoutes };
  });

  return formattedDatabaseRoutes;
};
