import { defineMessage, publishMessage } from '@gaming1/g1-message-bus';

import { consentPolicyCodec, userConsentMessagePayloadCodec } from './codecs';
import { ConsentPolicyContextValue } from './types';

export const CONSENT_MANAGEMENT_BUS_CHANNEL = 'consent-management';

/** Message sent from RN to the web each time the user consent is updated */
export const userConsentMessage = defineMessage(
  'consent-policy/set',
  userConsentMessagePayloadCodec,
);

export const sendConsentPolicy = (
  userConsentMessageValue: ConsentPolicyContextValue,
) => {
  publishMessage(CONSENT_MANAGEMENT_BUS_CHANNEL)(
    userConsentMessage,
    userConsentMessageValue,
  );
};

/** Message sent from the web to RN when the user wants to update its consent */
export const showConsentModalMessage = defineMessage(
  'consent-policy/show',
  consentPolicyCodec,
);

export const openMobileConsentPolicyModal = () => {
  publishMessage(CONSENT_MANAGEMENT_BUS_CHANNEL)(showConsentModalMessage, null);
};
