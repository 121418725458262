import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Rooms, useConfig } from '@gaming1/g1-config';
import { useI18n, userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { useTranslation } from '@gaming1/g1-i18n';
import { BOTTOM_NAV_HEIGHT, useMediaBreakPoint } from '@gaming1/g1-ui';
import { useGetIsMounted, usePrevious } from '@gaming1/g1-utils';

import * as ZendeskActions from '../../../shared/actions';
import { supportJwtTokenSelector } from '../../../shared/selectors';

import { brandTags, CanShowWidget, windowWithZendeskSDK } from './types';

export const WIDGET_OPENING_TIMEOUT_IN_MS = 100;
export const FULLTIME_LIVE_CHAT_VIP_LEVEL = 8;

const getUserData = ({
  Email,
  FirstName,
  LastName,
  PhoneNumber,
  PrefixPhoneNumber: Prefix,
  Pseudo,
}: NonNullable<ReturnType<typeof userSelector>>) => ({
  name: FirstName && LastName ? `${FirstName} ${LastName}` : '',
  email: Email || '',
  phone: Prefix && PhoneNumber ? `+${Prefix}${PhoneNumber}` : '',
  username: Pseudo || '',
});

type ZendeskWidgetClassicType = {
  shouldWidgetBeVisible: boolean;
};

const BOTTOM_NAV_SPACING_IN_PX = 4;

const zendeskHeightOffset = `${BOTTOM_NAV_HEIGHT + BOTTOM_NAV_SPACING_IN_PX}px`;

export const ZendeskWidgetClassic = forwardRef<
  CanShowWidget,
  ZendeskWidgetClassicType
>(({ shouldWidgetBeVisible }, ref) => {
  const zendeskMethod = windowWithZendeskSDK?.zE;
  const { currentLocale: locale } = useI18n();
  const getIsMounted = useGetIsMounted();
  const zendeskJwtToken = useSelector(supportJwtTokenSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const config = useConfig();
  const isVipPlayer =
    user?.VipStatus !== undefined &&
    user?.VipStatus >= FULLTIME_LIVE_CHAT_VIP_LEVEL;
  const isMobileMedia = useMediaBreakPoint({ max: 'md' });
  const { t } = useTranslation(['user']);
  const location = useLocation();
  const currentLocation = location.pathname;
  const previousLocation = usePrevious(currentLocation);

  /**
   * Get JWT Token from the backend
   */
  const getZendeskJwtToken = useCallback(() => {
    dispatch(ZendeskActions.getJwtToken.request());
  }, [dispatch]);

  useEffect(() => {
    if (!zendeskMethod) {
      return;
    }

    /**
     * Update widget title
     */
    zendeskMethod('webWidget', 'updateSettings', {
      webWidget: {
        launcher: {
          label: {
            '*': t('support.widget.bubbleText'),
          },
        },
        contactForm: {
          title: {
            '*': t('support.widget.title'),
          },
        },
        chat: {
          title: {
            '*': t('support.widget.title'),
          },
        },
        helpCenter: {
          title: {
            '*': t('support.widget.title'),
          },
        },
      },
    });
  }, [t, zendeskMethod]);

  /**
   * Add margin for mobile app
   */
  useEffect(() => {
    if (!zendeskMethod) {
      return;
    }
    if (isMobileMedia) {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            vertical: zendeskHeightOffset,
            mobile: { vertical: zendeskHeightOffset },
          },
        },
      });
    } else {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            vertical: 'unset',
            mobile: { vertical: 'unset' },
          },
        },
      });
    }
  }, [zendeskMethod, isMobileMedia]);

  /**
   * Provide the JWT Token to zendesk and authenticate the current user
   */
  useEffect(() => {
    if (zendeskMethod && zendeskJwtToken) {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn(callback: (token: string) => void) {
                callback(zendeskJwtToken);
              },
            },
          },
        },
      });
      zendeskMethod('webWidget', 'chat:reauthenticate');
    }
  }, [zendeskJwtToken, zendeskMethod]);

  /**
   * Dirty hack to force the widget to fully open. Calling the 'open' action
   * when the widget is hidden is the same as calling the 'show' action, which
   * only shows the tiny widget (not very visible). Calling a second time the
   * 'open' action after the widget has been shown has the intended effect.
   * Unfortunately there is no event handler for the 'show' event available,
   * thus we use a simple timeout.
   */
  const forceOpenWidget = useCallback(() => {
    if (!zendeskMethod) {
      return;
    }
    zendeskMethod('webWidget', 'show');
    setTimeout(() => {
      if (getIsMounted() && zendeskMethod) {
        zendeskMethod('webWidget', 'open');
      }
    }, WIDGET_OPENING_TIMEOUT_IN_MS);
  }, [getIsMounted, zendeskMethod]);

  /**
   * Show the widget (on a button click for instance)
   */
  const showWidget = useCallback(() => {
    forceOpenWidget();
  }, [forceOpenWidget]);

  // Manages to call a child method from a parent
  useImperativeHandle(ref, () => ({
    showWidget() {
      showWidget();
    },
  }));

  /*
   * Hide the widget when the url changes if there is no current chat session
   * and if the widget is not on maximized size
   */
  useEffect(() => {
    if (zendeskMethod && currentLocation !== previousLocation) {
      const isChatting = zendeskMethod('webWidget:get', 'chat:isChatting');
      const displayType = zendeskMethod('webWidget:get', 'display');
      if (
        isChatting ||
        (displayType !== 'launcher' && displayType !== 'hidden')
      ) {
        zendeskMethod('webWidget', 'close');
      } else {
        zendeskMethod('webWidget', 'hide');
      }
    }
  }, [currentLocation, previousLocation, zendeskMethod]);

  /**
   * Display the live chat
   */
  const displayLiveChat = useCallback(() => {
    if (zendeskMethod) {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            suppress: false,
          },
          contactForm: {
            suppress: true,
          },
        },
      });
    }
  }, [zendeskMethod]);

  /**
   * Hide live chat
   */
  const hideLiveChat = useCallback(() => {
    if (zendeskMethod) {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          chat: {
            suppress: true,
          },
          contactForm: {
            suppress: false,
          },
        },
      });
    }
  }, [zendeskMethod]);

  /**
   *  Display the live chat when an operators are online
   *  Display the live chat for VIP player when operators are away
   *  Hide live chat and display contact form when operators are offline
   */

  const switchLiveChatStatus = useCallback(
    (status: string) => {
      if (
        isUserLoggedIn &&
        (status === 'online' ||
          (status === 'away' &&
            config.user.isFulltimeLiveChatForVipEnabled &&
            isVipPlayer))
      ) {
        displayLiveChat();
      } else {
        hideLiveChat();
      }
    },
    [
      config.user.isFulltimeLiveChatForVipEnabled,
      displayLiveChat,
      hideLiveChat,
      isUserLoggedIn,
      isVipPlayer,
    ],
  );

  /** Fill or clear the contact form */
  const prefillContactForm = useCallback(() => {
    if (!zendeskMethod) {
      return;
    }
    if (user) {
      /**
       * Get user data and prefill readonly fields
       */
      const { name, email, phone, username } = getUserData(user);
      zendeskMethod('webWidget', 'identify', { name, email });
      zendeskMethod('webWidget', 'prefill', {
        name: { value: name, readOnly: !!name },
        email: { value: email, readOnly: !!email },
        phone: { value: phone, readOnly: !!phone },
      });

      /**
       * Prefill custom zendesk fields based on field ID
       * Zendesk does not allow to make custom fields readonly
       */

      if (config.user.zendeskUserNameFieldId) {
        zendeskMethod('webWidget', 'updateSettings', {
          webWidget: {
            contactForm: {
              fields: [
                {
                  id: config.user.zendeskUserNameFieldId,
                  prefill: { '*': username },
                },
              ],
            },
          },
        });
      }
    }
  }, [config.user.zendeskUserNameFieldId, user, zendeskMethod]);

  /** Hides and shows the widget */
  useEffect(() => {
    if (shouldWidgetBeVisible && zendeskMethod) {
      forceOpenWidget();
    } else if (zendeskMethod) {
      zendeskMethod('webWidget', 'hide');
    }
  }, [forceOpenWidget, shouldWidgetBeVisible, zendeskMethod]);

  /** Set the current language for the widget */
  useEffect(() => {
    if (zendeskMethod) {
      zendeskMethod('webWidget', 'setLocale', locale);
    }
  }, [locale, zendeskMethod]);

  /** Add brand tag for live chat and contact form */
  useEffect(() => {
    const currentBrand = brandTags[config.room.roomName as Rooms];
    if (zendeskMethod) {
      zendeskMethod('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            tags: currentBrand,
          },
        },
      });
      zendeskMethod('webWidget', 'chat:addTags', [currentBrand]);
    }
  }, [config.room.roomName, zendeskMethod]);

  /** Identifies or logout the user when a login or logout event is triggered */
  useEffect(() => {
    if (!zendeskMethod) {
      return;
    }

    if (isUserLoggedIn) {
      if (config.user.isLiveChatEnabled) {
        getZendeskJwtToken();
        zendeskMethod('webWidget:on', 'chat:status', (status: string) =>
          switchLiveChatStatus(status),
        );
      }
      /**
       * Display the contact form and fill in the form with user data
       * when no operator connected and hide the live chat
       */
      prefillContactForm();
    } else {
      /**
       * Hide live chat and logout current user from zendesk
       */
      zendeskMethod('webWidget:on', 'chat:status', () => hideLiveChat());

      zendeskMethod('webWidget', 'prefill', {
        name: { readOnly: false },
        email: { readOnly: false },
        phone: { readOnly: false },
      });
      zendeskMethod('webWidget', 'logout');
      zendeskMethod('webWidget', 'clear');
    }
  }, [
    config.user.isLiveChatEnabled,
    displayLiveChat,
    getZendeskJwtToken,
    hideLiveChat,
    isUserLoggedIn,
    prefillContactForm,
    switchLiveChatStatus,
    zendeskMethod,
  ]);

  /**
   * Populates the widget search suggestions with default entries.
   * Entries will be linked to topics by the customer support in zendesk.
   * If an entry is not found nothing is displayed.
   * Example : "label1" can be linked to "How to make a deposit ?"
   */
  useEffect(() => {
    if (zendeskMethod) {
      zendeskMethod('webWidget', 'helpCenter:setSuggestions', {
        labels: ['label1', 'label2', 'label3', 'label4', 'label5'],
      });
    }
  }, [zendeskMethod]);

  return null;
});
