import {
  Action,
  AnyAction,
  combineReducers,
  Reducer,
  ReducersMapObject,
} from 'redux';
import { enableBatching } from 'redux-batched-actions';

import { RESET_STATES_ACTION_NAME } from './constants';
import { AnyState } from './types';

const enableBatchingTyped = <S, A extends AnyAction>(
  reducer: Reducer<S, A>,
): Reducer<S, A> => enableBatching(reducer as Reducer<S, AnyAction>);

export type ReducerManager<
  State extends Record<string, unknown>,
  Actions extends Action,
> = {
  getReducerMap: () => ReducersMapObject<State, Actions>;
  reduce: Reducer<State, Actions>;
  add: <StoreKey extends string, StateSlice extends AnyState>(
    storeKey: StoreKey,
    reducer: Reducer<StateSlice, Actions>,
  ) => void;
  remove: (storeKey: string) => void;
};

// From https://redux.js.org/recipes/code-splitting

/**
 * Manage a list of reducers that can be added or removed after the store initialization
 * @param initialReducers
 */
export const createReducerManager = <
  State extends Record<string, AnyState>,
  Actions extends Action,
>(
  initialReducers: ReducersMapObject<State, Actions>,
): ReducerManager<State, Actions> => {
  // Create an object which maps keys to reducers
  const reducers = { ...initialReducers };

  // Create the initial combinedReducer
  let combinedReducer = enableBatchingTyped(
    combineReducers<State, Actions>(reducers),
  );

  // An array which is used to delete state keys when reducers are removed
  let keysToRemove: string[] = [];

  return {
    getReducerMap: () => reducers,

    // The root reducer function exposed by this object
    // This will be passed to the store
    reduce: (state: State | undefined, action: Actions) => {
      let newState = state;

      if (typeof state !== 'undefined') {
        newState = { ...state };

        // If any reducers have been removed, clean up their state first
        if (keysToRemove.length > 0) {
          keysToRemove.forEach((key) => {
            if (newState) {
              Reflect.deleteProperty(newState, key);
            }
          });
          keysToRemove = [];
        }

        // If the action is core/reset_states, clean up every reducer's state.
        if (action.type === RESET_STATES_ACTION_NAME) {
          Object.keys(newState).forEach((key) => {
            if (newState) {
              (newState[key] as AnyState) = undefined;
            }
          });
        }
      }

      // Delegate to the combined reducer
      return combinedReducer(newState, action);
    },

    // Adds a new reducer with the specified key
    add: <StoreKey extends string, StateSlice extends AnyState>(
      storeKey: StoreKey,
      reducer: Reducer<StateSlice, Actions>,
    ) => {
      if (!storeKey || reducers[storeKey]) {
        return;
      }

      // Add the reducer to the reducer mapping

      // TODO: find a way to get a proper typing
      reducers[storeKey] = reducer as Reducer<State[StoreKey], Actions>;

      // Generate a new combined reducer
      combinedReducer = enableBatchingTyped(combineReducers(reducers));
    },

    // Removes a reducer with the specified key
    remove: (storeKey: string) => {
      if (!storeKey || !reducers[storeKey]) {
        return;
      }

      // Remove it from the reducer mapping
      Reflect.deleteProperty(reducers, storeKey);

      // Add the key to the list of keys to clean up
      keysToRemove.push(storeKey);

      // Generate a new combined reducer
      combinedReducer = enableBatchingTyped(combineReducers(reducers));
    },
  };
};
