import { createLogger, Logger, LoggingMethod } from '@gaming1/g1-logger';

export const logger: Logger = createLogger('g1-network');

type LoggerArgs = Parameters<LoggingMethod>;

export const getLoggerWithPrefix = (
  prefix: string,
): Pick<Logger, 'error' | 'trace' | 'debug' | 'warn' | 'info'> => ({
  error: (logString: string, ...args: LoggerArgs) =>
    logger.error(`${prefix} ${logString}`, ...args),
  trace: (logString: string, ...args: LoggerArgs) =>
    logger.trace(`${prefix} ${logString}`, ...args),
  debug: (logString: string, ...args: LoggerArgs) =>
    logger.debug(`${prefix} ${logString}`, ...args),
  warn: (logString: string, ...args: LoggerArgs) =>
    logger.warn(`${prefix} ${logString}`, ...args),
  info: (logString: string, ...args: LoggerArgs) =>
    logger.info(`${prefix} ${logString}`, ...args),
});
