import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  display,
  DisplayProps,
  overflow,
  OverflowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { colors } from '@gaming1/g1-style';

export type TextProps = {
  ellipsis?: boolean;
};

/** Basic text component with default color and helpers from styled-system */
export const Text = styled.p<
  (ColorProps | TypographyProps | DisplayProps | SpaceProps | OverflowProps) &
    TextProps
>`
  color: ${colors('text')};
  ${color}
  ${display}
  ${typography}
  ${space}
  ${overflow}

    ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `};
`;
