import {
  defaultMethodFactory,
  getLoggers,
  isLoggingAllowed,
  LoggingMethod,
  MethodFactory,
} from '../loglevel';

/**
 * Basically MethodFactory but with the ability to return "false" to prevent
 * the log to be made
 */
export type LogInterceptor = (
  ...logFactoryParams: Parameters<MethodFactory>
) => (...logParams: Parameters<LoggingMethod>) => boolean | void;

/** List of intereceptors */
export const interceptors: LogInterceptor[] = [];

/**
 * This method factory will call the default one then call the interceptors
 * with all the same params. The original method will be called unless the
 * interceptors prevent it by returning false
 */
const createMethodFactoryWithInterceptors =
  (): MethodFactory => (logMethodName, currentLogLevel, loggerName) => {
    const rawMethod = defaultMethodFactory(
      logMethodName,
      currentLogLevel,
      loggerName,
    );
    return (...messages) => {
      // Call the interceptors in all cases
      const results = interceptors.map((interceptor) =>
        interceptor(logMethodName, currentLogLevel, loggerName)(...messages),
      );
      if (
        results.every((interceptor) => interceptor === true) ||
        (results.every((interceptor) => interceptor === undefined) &&
          isLoggingAllowed(logMethodName, currentLogLevel))
      ) {
        // Call the real log method if one of the interceptors didn't prevent it
        rawMethod(...messages);
      }
    };
  };

/**
 * Process log interceptors and add them to each loggers
 * @param {string} loggerName: the optional logger name on which the
 * interceptors should be processed. If empty, will be processed for
 * all existing loggers
 */
export const processLogInterceptor = (loggerName?: string) => {
  const loggers = loggerName
    ? [getLoggers()[loggerName]].filter(Boolean)
    : Object.values(getLoggers());
  loggers.forEach((logger) => {
    // This will allow the factory to be called even if the log is muted
    logger.setShouldAlwaysCallMethod(interceptors.length > 0);
    logger.setMethodFactory(createMethodFactoryWithInterceptors());
  });
};

/**
 * Add a log interceptor that will receive all logs sent through loggers
 * @param {LogInterceptor} logInterceptor: a curried log interceptor that
 * will receive the method name, current level and logger name, then in the
 * second call every arguments sent through the log. Can return "false" to
 * prevent the print of the log to the console
 * @param {string} loggerName: the optional logger name on which the interceptor
 * should be added. If empty, will be added on all existing loggers
 */
export const addLogInterceptor = (
  logInterceptor: LogInterceptor,
  loggerName?: string,
): LogInterceptor => {
  interceptors.push(logInterceptor);
  processLogInterceptor(loggerName);
  return logInterceptor;
};

/**
 * Remove a log interceptor from loggers
 * @param {LogInterceptor} logInterceptor: log interceptor that will be removed
 */
export const removeLogInterceptor = (logInterceptor: LogInterceptor) => {
  interceptors.splice(interceptors.indexOf(logInterceptor), 1);
  processLogInterceptor();
};
