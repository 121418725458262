import * as t from 'io-ts';

import { defineMessage } from '@gaming1/g1-message-bus';

const USER_LOGGED_IN_TOPIC = 'session/user-logged-in';

/** Message sent when the user has been successfully logged in */
export const userLoggedInMessage = defineMessage(USER_LOGGED_IN_TOPIC);

const USER_LOGGED_OUT_TOPIC = 'session/user-logged-out';

/** Message sent when the user has been logged out */
export const userLoggedOutMessage = defineMessage(USER_LOGGED_OUT_TOPIC);

const LOGIN_INIT_TOPIC = 'session/login-init';

/** Message sent by a non-react host when the user has logged in */
export const loginInitMessage = defineMessage(LOGIN_INIT_TOPIC);

const LOGOUT_INIT_TOPIC = 'session/logout-init';

/** Message sent by a non-react host when the user has logged out */
export const logoutInitMessage = defineMessage(LOGOUT_INIT_TOPIC);

const LANGUAGE_SWITCH_INIT_TOPIC = 'i18n/language-switch';

const languageSwitchPayloadCodec = t.string;

/** Message sent by a non-react host when the user changed its language */
export const languageSwitchMessage = defineMessage(
  LANGUAGE_SWITCH_INIT_TOPIC,
  languageSwitchPayloadCodec,
);

const LEGAL_POPUP_ACCEPTANCE = 'legalPopup/acceptance';

/** Message sent by a non-react host when the user accepted the legal popup */
export const legalPopupAcceptanceMessage = defineMessage(
  LEGAL_POPUP_ACCEPTANCE,
);

const LOCATION_CHANGED_TOPIC = 'routing/location-changed';

const locationCodec = t.intersection([
  t.type({
    hash: t.string,
    search: t.string,
    pathname: t.string,
  }),
  t.partial({
    state: t.union([t.record(t.string, t.any), t.null]),
    key: t.union([t.string, t.undefined]),
  }),
]);

const historyActionCodec = t.union([
  t.literal('PUSH'),
  t.literal('REPLACE'),
  t.literal('POP'),
]);

export const locationChangedMessageCodec = t.type({
  action: historyActionCodec,
  currentLocation: locationCodec,
  previousLocation: t.union([locationCodec, t.null]),
});

/**
 * Message sent to react-native and non-react host when the route has been
 * changed by the react router (HistoryProvider)
 */
export const locationChangedMessage = defineMessage(
  LOCATION_CHANGED_TOPIC,
  locationChangedMessageCodec,
);

const APPSFLYER_EVENT_TOPIC = 'tracking/appsflyer-event';

const appsFlyerEventPayloadCodec = t.type(
  {
    eventName: t.string,
    eventValues: t.record(t.string, t.union([t.string, t.number])),
  },
  'AppsFlyerEventPayload',
);

/**
 * Message sent by business domains to report events to AppsFlyer
 * Event name is mandatory
 * Event values are needed for some events (see here
 * https://support.appsflyer.com/hc/en-us/articles/4410481112081#predefined-event-names).
 * If not, an empty object should be used
 */
export const appsFlyerEventMessage = defineMessage(
  APPSFLYER_EVENT_TOPIC,
  appsFlyerEventPayloadCodec,
);

const APPSFLYER_AFFILIATION_CAMPAIGN_TOPIC =
  'tracking/appsflyer-affiliation-campaign';

const appsFlyerAffiliationCampaignPayloadCodec = t.type(
  {
    mediaSource: t.string,
    campaignName: t.string,
  },
  'AppsFlyerAffiliationCampaignPayload',
);

/**
 * Message sent by the wrapper of AppsFlyer SDK after receiving the conversation
 * data info. It will ONLY be dispatched if the "media source" is a known
 * affiliation platform, so this will not fire for regular aquisition
 * https://github.com/AppsFlyerSDK/appsflyer-react-native-plugin/blob/master/Docs/API.md#onInstallConversionData
 */
export const appsFlyerAffiliationEventMessage = defineMessage(
  APPSFLYER_AFFILIATION_CAMPAIGN_TOPIC,
  appsFlyerAffiliationCampaignPayloadCodec,
);

const ANALYTICS_PUSH_EVENT_TOPIC = 'analytics/push-event';

export const gtmEventCodec = t.intersection(
  [
    t.record(t.string, t.union([t.string, t.number])),
    t.type({
      category: t.string,
      action: t.string,
    }),
    t.partial({
      label: t.string,
      value: t.union([t.number, t.string]),
    }),
  ],
  'GTMEvent',
);

export const googleAnalyticsEventCodec = t.intersection(
  [
    t.record(t.string, t.union([t.string, t.number])),
    t.type({
      event: t.string,
    }),
  ],
  'GoogleAnalyticsEvent',
);

export const analyticsEventPayloadCodec = t.type(
  {
    data: t.union([gtmEventCodec, googleAnalyticsEventCodec]),
  },
  'AnalyticsEventPayload',
);

/** Event pushed by analytics features */
export const analyticsEventMessage = defineMessage(
  ANALYTICS_PUSH_EVENT_TOPIC,
  analyticsEventPayloadCodec,
);

const ANALYTICS_INJECTED_EVENT_TOPIC = 'analytics/injected-event';

export const analyticsInjectedEventPayloadCodec = t.type(
  {
    data: t.union([gtmEventCodec, googleAnalyticsEventCodec]),
  },
  'AnalyticsInjectedEventPayload',
);

/** Event injected by analytics features */
export const analyticsInjectedEventMessage = defineMessage(
  ANALYTICS_INJECTED_EVENT_TOPIC,
  analyticsInjectedEventPayloadCodec,
);

// Correspond to "AppStateStatus" type from react-native
const appStateCodec = t.union(
  [t.literal('active'), t.literal('background'), t.literal('inactive')],
  'AppState',
);

const APP_STATE_TOPIC = 'app/state';

/**
 * Message published when the mobile app state changes. Can be:
 * - active: The app is running in the foreground
 * - background: The app is running in the background. The user is either:
 *   - in another app
 *   - on the home screen
 *   - [Android] on another Activity (even if it was launched by your app)
 * - [iOS] inactive: This is a state that occurs when transitioning between foreground
 *   & background, and during periods of inactivity such as entering the
 *   multitasking view, opening the Notification Center or in the event of an
 *   incoming call.
 * More info on app state: https://reactnative.dev/docs/appstate
 */
export const appStateMessage = defineMessage(APP_STATE_TOPIC, appStateCodec);
