import { Observable, of } from 'rxjs';
import { ajax, AjaxConfig, AjaxTimeoutError } from 'rxjs/ajax';
import { map, switchMapTo, tap } from 'rxjs/operators';

import { logger } from './logger';

const DEFAULT_TIMEOUT_IN_MS = 5000;

export type AjaxFetchConfig = Partial<AjaxConfig>;

/**
 *
 * @param url endpoint
 * @param fetchOptions options for the request
 * @param fetchOptions[body] payload
 * @param fetchOptions[method] method (default GET)
 * @param fetchOptions[params] url params
 * @param ajaxConfig additional ajax options
 */
export const ajaxFetch = (
  url: string,
  {
    body,
    headers,
    method,
    queryParams,
    ...otherAjaxConfigs
  }: AjaxFetchConfig = {
    method: 'GET',
  },
): Observable<unknown> => {
  const requestUrl = url;
  let requestBody = null;
  if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
    requestBody = body;
  }
  const ajaxConfig: AjaxConfig = {
    body: requestBody,
    // Send the cookies on each request
    // TODO: add this back when the backend set cors origin to some domain but not *
    // withCredentials: true,
    headers: headers || {
      'Content-Type': 'application/json',
    },
    method,
    responseType: 'json',
    queryParams,
    url: requestUrl,
    timeout: otherAjaxConfigs.timeout ?? DEFAULT_TIMEOUT_IN_MS,
    ...otherAjaxConfigs,
  };
  // Start an Observable from "nothing" so the logger is only called when the
  // pipe is actually subscribed to.
  return of(true).pipe(
    tap(() => logger.debug(`[Ajax] Request to ${url}`, ajaxConfig)),
    switchMapTo(ajax(ajaxConfig)),
    map((ajaxResponse) => ajaxResponse.response),
    tap(
      (response) =>
        logger.debug(
          `[Ajax] Response from ${url} with query params`,
          queryParams,
          'have content:',
          response,
        ),
      (error) => {
        if (error instanceof AjaxTimeoutError) {
          logger.error(
            `[Ajax] Timeout from ${url} with query params`,
            queryParams,
            error,
          );
        } else {
          logger.error(
            `[Ajax] Error from ${url} with query params`,
            queryParams,
            error,
          );
        }
      },
    ),
  );
};

export type AjaxFetch = typeof ajaxFetch;
