import { TimeoutError } from 'rxjs';

import { Logger } from '@gaming1/g1-logger';

import { CodecGuardError } from './guards';
import { logger as utilsLogger } from './logger';

export type FailurePayload = {
  status: number;
  errorMessage: string;
};

type ValidResponse = {
  Status: number;
};

// object is needed, otherwise a string will be accepted
// eslint-disable-next-line @typescript-eslint/ban-types
type ErrorMessages = object & Record<number, string>;

export type DefaultErrorStatus = -1;
export const DEFAULT_SERVER_ERROR_STATUS: DefaultErrorStatus = -1;
export const DEFAULT_SERVER_ERROR_MESSAGE = 'core:error.unknownServer';

export type TimeoutErrorStatus = -100;
export const TIMEOUT_ERROR_STATUS: TimeoutErrorStatus = -100;
export const TIMEOUT_ERROR_MESSAGE = 'core:error.timeout';

const isValidReponse = (response: unknown): response is ValidResponse =>
  typeof response === 'object' &&
  !!response &&
  'Status' in response &&
  typeof (response as ValidResponse).Status === 'number';

export const isCodecGuardError = (error: unknown): error is CodecGuardError => {
  if (error instanceof CodecGuardError) {
    return true;
  }
  return (
    typeof error === 'object' &&
    !!error &&
    'message' in error &&
    'name' in error &&
    'report' in error &&
    'type' in error &&
    'value' in error
  );
};

/**
 * Log an explicit error to the console from a CodecGuardError stating what
 * the value is, what type was expected, and which values didn't match
 */
export const logCodecError = (
  error: CodecGuardError,
  {
    errorMessages,
    logger = utilsLogger,
    defaultErrorMessage = DEFAULT_SERVER_ERROR_MESSAGE,
  }: {
    errorMessages?: ErrorMessages;
    logger?: Logger;
    defaultErrorMessage?: string;
  } = {},
) => {
  if (isValidReponse(error.value)) {
    const status = error.value.Status;
    const errorMessage =
      (!!errorMessages && errorMessages[status]) || defaultErrorMessage;
    logger.error(
      error.message,
      'the type',
      error.type,
      'could not be infered with value:',
      error.value,
      'details:',
      { errorMessage, report: error.report, status },
    );
  } else {
    logger.error(
      error.message,
      'the type',
      error.type,
      'could not be infered with value:',
      error.value,
      'details:',
      { report: error.report },
    );
  }
};

/**
 * generate a valid FailurePayload for async actions, based on a dictionnary of
 * strings linked to the status the server is responding with
 * The default message require the "core" namespace to be loaded by i18next
 */
export const createFailurePayload = (
  input?: unknown,
  errorMessages?: ErrorMessages,
  logErrors = true,
  defaultErrorMessage = DEFAULT_SERVER_ERROR_MESSAGE,
): FailurePayload => {
  let status: number = DEFAULT_SERVER_ERROR_STATUS;
  /** If the error is a TimeoutError, send a specific FailurePayload */
  if (input instanceof TimeoutError) {
    return {
      status: TIMEOUT_ERROR_STATUS,
      errorMessage: TIMEOUT_ERROR_MESSAGE,
    };
  }
  if (isValidReponse(input)) {
    status = input.Status;
  }
  if (isCodecGuardError(input)) {
    if (isValidReponse(input.value)) {
      status = input.value.Status;
    }
    if (logErrors) {
      logCodecError(input);
    }
  }
  const errorMessage: string =
    (!!errorMessages && errorMessages[status]) || defaultErrorMessage;
  return {
    status,
    errorMessage,
  };
};
