import React, { FC, useCallback, useEffect, useState } from 'react';
import { Observable, Subscription, timer } from 'rxjs';
import { first } from 'rxjs/operators';

import { useGetIsMounted, usePrevious } from '@gaming1/g1-utils';

import {
  NOTIFICATION_ANIMATION_TIME_IN_MS,
  NotificationMessage,
  NotificationWrapper,
} from './styles';
import { NotificationProps } from './types';

let visibilityTimeOut$: Observable<number>;

/** Show an account notification  */
export const Notification: FC<NotificationProps> = ({
  children,
  fixed,
  onClose = () => undefined,
  type,
  timestamp,
}) => {
  const [visible, setVisible] = useState(true);

  const previousTimestamp = usePrevious(timestamp);

  const getIsMounted = useGetIsMounted();

  const fadesOut = type !== 'warning' && type !== 'danger';

  const closeNotification = useCallback(() => {
    onClose();
    setVisible(false);
  }, [onClose]);

  /**
   * This useEffect hides automatically the notification if it's a success after X ms
   */
  useEffect(() => {
    let subscription: Subscription;
    if (fadesOut && visible) {
      visibilityTimeOut$ = timer(NOTIFICATION_ANIMATION_TIME_IN_MS).pipe(
        first(),
      );
      subscription = visibilityTimeOut$.subscribe(closeNotification);
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [closeNotification, fadesOut, timestamp, type, visible]);

  /**
   * Quickly hides the notification and displays the new one to refresh its CSS animation
   */
  useEffect(() => {
    if (previousTimestamp !== timestamp) {
      setVisible(false);
      timer(2)
        .pipe(first())
        .subscribe(() => {
          if (getIsMounted()) {
            setVisible(true);
          }
        });
    }
  }, [previousTimestamp, timestamp, getIsMounted]);

  return (
    <NotificationWrapper
      data-testid="notification-wrapper"
      fadesOut={fadesOut}
      fixed={fixed}
      onClick={fadesOut ? onClose : undefined}
      type={type}
      visible={visible}
    >
      <NotificationMessage
        closable={!fadesOut}
        onCloseButtonClicked={onClose}
        testId="notification"
        type={type}
      >
        {children}
      </NotificationMessage>
    </NotificationWrapper>
  );
};
