import { createAction, createAsyncAction } from 'typesafe-actions';

import { FailurePayload } from '@gaming1/g1-utils';

import { JwtTokenResponse } from './types/entities';

/** Used for testing */
export const dummyAction = createAction('support/dummy')();

/**
 * Get JWT token
 */
export const getJwtToken = createAsyncAction(
  'support/get_jwt_token_request',
  'support/get_jwt_token_success',
  'support/get_jwt_token_failure',
)<void, JwtTokenResponse, FailurePayload>();
