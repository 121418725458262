import React, { FC, ReactNode } from 'react';

import { GlobalAssetsProvider } from '@gaming1/g1-assets-management/web';

import { coreAssets } from './core/coreAssets';

export const AssetsProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <GlobalAssetsProvider contextValues={[coreAssets]}>
    {children}
  </GlobalAssetsProvider>
);
