import * as t from 'io-ts';

import { envelopeCodec } from '@gaming1/g1-message-bus';
import { createEnumType } from '@gaming1/g1-utils';

import { GeolocationReason } from '../geolocation/constants';

import { interopMessages } from './constants';

/* Codecs */

/** Generic codec for the messages sent/received from parent to iframe */
export const iframeMessageCodec = t.intersection(
  [
    t.type({
      type: t.string,
    }),
    t.partial({
      payload: t.unknown,
    }),
  ],
  'IframeMessage',
);

export const navigateMessageCodec = t.type(
  {
    type: t.literal(interopMessages.navigate),
    payload: t.type({
      method: t.union([t.literal('push'), t.literal('replace')]),
      path: t.string,
    }),
  },
  'NavigateMessage',
);

export const exitFullScreenLocationMessageCodec = t.type(
  {
    type: t.literal(interopMessages.exitFullscreenLocation),
  },
  'ExitFullScreenLocationMessage',
);

export const blacklistedRoutesMessageCodec = t.type(
  {
    type: t.literal(interopMessages.blacklistedRoutes),
    payload: t.array(t.string),
  },
  'BlackListedRouteMessage',
);

export const geolocationMessageCodec = t.type(
  {
    type: t.literal(interopMessages.geocomplyStatus),
    payload: t.type({
      isValid: t.boolean,
    }),
  },
  'GeolocationMessage',
);

export const geolocationRequestParamsCodec = t.intersection(
  [
    t.type({
      reason: createEnumType<GeolocationReason>(GeolocationReason),
      userId: t.string,
      license: t.string,
    }),
    t.partial({
      businessSessionId: t.string,
    }),
  ],
  'GeolocationRequestParams',
);

export const iframeGeolocationRequestMessageCodec = t.type(
  {
    type: t.literal(interopMessages.geolocationRequest),
    payload: geolocationRequestParamsCodec,
  },
  'IframeGeolocationRequestMessage',
);

export const navigationDrawerStateChangeMessageCodec = t.type(
  {
    type: t.literal(interopMessages.navigationDrawerStateChange),
    payload: t.type({
      open: t.boolean,
    }),
  },
  'NavigationDrawerStateChangeMessage',
);

export const reduxActionTwinningMessageCodec = t.type(
  {
    type: t.literal(interopMessages.reduxActionDispatched),
    payload: t.intersection([
      t.type({
        type: t.string,
      }),
      t.partial({
        payload: t.unknown,
        meta: t.type({
          dispatcherId: t.string,
        }),
      }),
    ]),
  },
  'ReduxActionTwinningMessage',
);

export const openBrowserMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openBrowser),
    payload: t.type({
      url: t.string,
    }),
  },
  'OpenBrowserMessage',
);

export const openExternalLinkViewMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openExternalLinkView),
    payload: t.intersection([
      t.type({
        url: t.string,
      }),
      t.partial({
        method: t.union([t.literal('GET'), t.literal('POST')]),
        headers: t.record(t.string, t.string),
        body: t.record(t.string, t.string),
        title: t.union([t.string, t.null]),
      }),
    ]),
  },
  'OpenExternalLinkViewMessage',
);

export const openAppSettingsMessageCodec = t.type(
  {
    type: t.literal(interopMessages.openAppSettings),
  },
  'OpenAppSettingsMessage',
);

export const localeChangedMessageCodec = t.type(
  {
    type: t.literal(interopMessages.localeChanged),
    payload: t.type({
      locale: t.string,
    }),
  },
  'LocaleChangedMessage',
);

// Request type packages/g1-network/src/types.ts
const requestCodec = t.type(
  {
    Id: t.string,
    Type: t.number,
    Identifier: t.string,
    Content: t.string,
  },
  'Request',
);

export const networkRequestMessageCodec = t.type(
  {
    type: t.literal(interopMessages.networkRequest),
    payload: requestCodec,
  },
  'NetworkRequestMessage',
);

export const networkResponseMessageCodec = t.type(
  {
    type: t.literal(interopMessages.networkResponse),
    payload: t.type({
      requestId: t.string,
      content: t.unknown,
    }),
  },
  'NetworkReponseMessage',
);

export const changeConnectionTypeMessageCodec = t.type(
  {
    type: t.literal(interopMessages.changeConnectionType),
    payload: t.type({
      connectionType: t.string,
      previousConnectionType: t.union([t.string, t.null]),
    }),
  },
  'ChangeConnectionTypeMessage',
);

export const shareCurrentPageMessageCodec = t.type(
  {
    type: t.literal(interopMessages.shareCurrentPage),
    payload: t.type({
      title: t.string,
      url: t.string,
    }),
  },
  'ShareCurrentPageMessage',
);

export const switchConfigMessageCodec = t.type(
  {
    type: t.literal(interopMessages.switchConfig),
    payload: t.type({
      configName: t.string,
      config: t.unknown,
    }),
  },
  'SwitchConfigMessage',
);

export const toggleGoBackMessageCodec = t.type(
  {
    type: t.literal(interopMessages.toggleGoBack),
    payload: t.type({
      isEnabled: t.boolean,
    }),
  },
  'ToggleGoBackMessage',
);

export const togglePullToRefreshMessageCodec = t.type(
  {
    type: t.literal(interopMessages.togglePullToRefresh),
    payload: t.type({
      isEnabled: t.boolean,
    }),
  },
  'TogglePullToRefreshMessage',
);

export const messageBusMessageCodec = t.type(
  {
    type: t.literal(interopMessages.messageBus),
    payload: t.type({
      ...envelopeCodec.props,
      // Simplified version, we check the topic structure later
      topic: t.string,
    }),
  },
  'MessageBusMessage',
);
