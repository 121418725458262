import {
  CoreAssetsContext,
  generateAssetsContextValue,
} from '@gaming1/g1-assets-management/web';

import notFound from './illustrations/noresult-404.png';
import logo from './logos/logo.svg';

export const coreAssets = generateAssetsContextValue(CoreAssetsContext)({
  /* UI buttons */
  backToTheTopArrow: '',
  genericError: '',
  networkError: '',
  /* Logos */
  logo,
  logoDark: '',
  logoLight: '',
  logoSmall: '',
  /* Banners */
  defaultSliderLarge: '',
  defaultSliderSmall: '',
  /* Illustrations */
  notFound,
  failure: '',
  questioning: '',
  noResults: notFound,
  repair: '',
  successCashier: '',
  successWelcome: '',
  warning: '',
  /* Geolocation */
  appStoreFr: '',
  appStoreEn: '',
  googlePlayFr: '',
  googlePlayEn: '',
  laptop: '',
  mobile: '',
});
