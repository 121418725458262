/* Facebook Pixel */

// From https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/facebook-pixel/index.d.ts

interface FBPEvent {
  (
    eventType: string,
    eventName: string,
    parameters?:
      | ViewContentParameters
      | SearchParameters
      | AddToCartParameters
      | AddToWishlistParameters
      | InitiateCheckoutParameters
      | AddPaymentInfoParameters
      | PurchaseParameters
      | LeadParameters
      | CompleteRegistrationParameters
      | CustomParameters,
  ): void;
}

interface FBPObject {
  callMethod?: FBPEvent;
  queue: Parameters<FBPEvent>[];
  loaded: boolean;
  version: string;
  push: FBPEvent;
}

interface ViewContentParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  content_type?: string | undefined;
  content_ids?: string[] | undefined;
  content_category?: string | undefined;
  contents?:
    | Array<{
        id: string;
        quantity: number;
      }>
    | undefined;
}

interface SearchParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_category?: string | undefined;
  content_ids?: string[] | undefined;
  search_string?: string | undefined;
}

interface AddToCartParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  content_type?: string | undefined;
  content_ids?: string[] | undefined;
}

interface AddToWishlistParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  content_category?: string | undefined;
  content_ids?: string[] | undefined;
}

interface InitiateCheckoutParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  content_category?: string | undefined;
  content_ids?: string[] | undefined;
  num_items?: number | undefined;
}

interface AddPaymentInfoParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_category?: string | undefined;
  content_ids?: string[] | undefined;
}

interface PurchaseParameters {
  value: number;
  currency: string;
  content_name?: string | undefined;
  content_type?: string | undefined;
  content_ids?: string[] | undefined;
  num_items?: number | undefined;
  order_id?: string | undefined;
}

interface LeadParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  content_category?: string | undefined;
}

interface CompleteRegistrationParameters {
  value?: number | undefined;
  currency?: string | undefined;
  content_name?: string | undefined;
  status?: boolean | undefined;
}

type CustomParameters = Record<string, unknown>;

export type FBPProperty = FBPEvent & FBPObject;

type WindowWithFBQ = Window & {
  _fbq?: FBPProperty;
  fbq?: FBPProperty;
};

export const windowWithFBQ = window as WindowWithFBQ;

/* Screeb */

export type ScreebEventProperties = Record<
  string,
  string | number | boolean | Date
>;

export interface ScreebSdkFunction {
  (type: 'init', websiteId: string): void;
  (
    type: 'identity',
    userId: string,
    userProperties: {
      age: number;
      email: string;
      firstname: string;
      lastname: string;
    },
  ): void;
  (type: 'identity.reset'): void;
  (
    type: 'event.track',
    eventName: string,
    eventProperties?: ScreebEventProperties,
  ): void;
}

export interface ScreebObject {
  q: unknown[];
}

export type WindowWithScreebSDK = Window & {
  $screeb?: ScreebSdkFunction;
  ScreebObject?: unknown;
};
