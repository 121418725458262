import gte from 'lodash/gte';
import lte from 'lodash/lte';
import { ForwardedRef, MutableRefObject, useEffect } from 'react';
import {
  filter,
  first,
  interval,
  mapTo,
  mergeScan,
  mergeWith,
  NEVER,
  Observable,
  of,
} from 'rxjs';

import { MediaBreakPointNames, mediaBreakPoints } from '@gaming1/g1-style';

import { icons, IconType } from './Icon/icons';

const createMediaBreakPointComparison =
  (compareFn: (a: number, b: number) => boolean) =>
  (
    mediaBreakPoint: MediaBreakPointNames,
    mediaBreakPointToCompare: MediaBreakPointNames,
  ): boolean => {
    const mediaBreakPointNames = mediaBreakPoints.map((bp) => bp.name);
    return compareFn(
      mediaBreakPointNames.indexOf(mediaBreakPoint),
      mediaBreakPointNames.indexOf(mediaBreakPointToCompare),
    );
  };

export const isMediaBreakPointAboveOrEqual =
  createMediaBreakPointComparison(gte);
export const isMediaBreakPointBelowOrEqual =
  createMediaBreakPointComparison(lte);

// This combines a forwaredRef and a localRef in order to keep assigning a single ref to the mask input
// Based on : https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export const useCombinedRefs = <T>(
  fwdRef: ForwardedRef<T>,
  localRef: MutableRefObject<T | null>,
) => {
  useEffect(() => {
    if (fwdRef) {
      if (typeof fwdRef === 'function') {
        fwdRef(localRef.current || null);
      } else {
        // eslint-disable-next-line no-param-reassign
        fwdRef.current = localRef.current || null;
      }
    }
    // Not including fwdRef and localRef here because refs are not needed in dependencies arrays
    // ESLint indicates that it's needed because the ref comes from the useCombinedRefs function parameters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return localRef;
};

export const isOfTypeIcons = (keyInput: string): keyInput is IconType =>
  Object.keys(icons).includes(keyInput);

type PausableTimer = (options: PausableTimerOptions) => Observable<void>;

type PausableTimerOptions = {
  /**
   * An Observable which simply emits "pause" when the timer should pause
   * (defaults to NEVER).
   */
  pause$?: Observable<'pause'>;
  /**
   * An Observable which simply emits "resume" when the timer should resume
   * (defaults to NEVER).
   */
  resume$?: Observable<'resume'>;
  /**
   * The total time in milliseconds (without pause time) before emitting
   * (defaults to 5000).
   */
  timeInMs?: number;
};

/**
 * Returns an Observable which emits a single time after the `timeInMs` and then
 * completes. It is pausable and resumable via the `pause$` and `resume$`
 * Observables.
 */
export const createPausableTimer: PausableTimer = ({
  pause$ = NEVER,
  resume$ = NEVER,
  timeInMs = 5000,
}) => {
  // In order to have pausable automatic rotations, a "frame" time is defined
  // and the pause can occur between each frame. With a 100ms value, this means
  // that the rotation is pausable 10 times per second which is sufficient to
  // give the illusion the pause is instantaneous.
  const frameTimeInMs = 100;

  const frames$ = interval(frameTimeInMs).pipe(mapTo(frameTimeInMs));

  return frames$.pipe(
    mergeWith(pause$, resume$),
    mergeScan<
      number | 'pause' | 'resume',
      { duration: number; state: 'playing' | 'paused' }
    >(
      (acc, x) => {
        if (x === 'pause') {
          return of({ ...acc, state: 'paused' });
        }
        if (x === 'resume') {
          return of({ ...acc, state: 'playing' });
        }
        if (acc.state === 'playing') {
          return of({ ...acc, duration: acc.duration + x });
        }

        return of(acc);
      },
      { duration: 0, state: 'playing' },
    ),
    filter(({ duration }) => duration >= timeInMs),
    first(),
    // This makes the Observable signature clearer on how it is intended to be used
    mapTo(undefined),
  );
};
