import { useContext } from 'react';

import { getWrapperEnv } from '@gaming1/g1-logger';

import { ConsentPolicyContext } from '../ConsentPolicyContext';
import { openMobileConsentPolicyModal } from '../messages';

/**
 * Returns functions allowing to get the user consent for a particular
 * purpose/vendor.
 *
 * Due to DidomiSDK loading asynchronously, these 2 functions always return
 * `false` as a restrictive fallback when didomi is configured, `true` otherwise.
 */
export const useConsentPolicy = () => {
  const {
    isConsentPolicyEnabled,
    purposesConsent,
    vendorsConsent,
    openConsentPolicyModal,
  } = useContext(ConsentPolicyContext);

  const getUserConsentStatusForPurpose = (purpose?: string) => {
    if (!isConsentPolicyEnabled) {
      return true;
    }
    if (purpose) {
      return purposesConsent.enabled.includes(purpose);
    }
    return false;
  };

  const getUserConsentStatusForVendor = (vendor?: string) => {
    if (!isConsentPolicyEnabled) {
      return true;
    }
    if (vendor) {
      return vendorsConsent.enabled.includes(vendor);
    }
    return false;
  };

  const openConsentPolicy = () => {
    if (getWrapperEnv() === 'rn') {
      openMobileConsentPolicyModal();
    }
    if (openConsentPolicyModal) {
      openConsentPolicyModal();
    }
  };

  return {
    getUserConsentStatusForPurpose,
    getUserConsentStatusForVendor,
    isConsentPolicyEnabled,
    openConsentPolicy,
  };
};
