import { LoyaltyConfig } from '../types';

export const defaultLoyaltyConfig: LoyaltyConfig = {
  fullShop: false,
  loggedOutCmsHomePage: false,
  mapDefaultValues: {
    center: { lat: 0, lng: 0 },
    zoom: 0,
  },
  miniGamesServerUrls: {
    giftbox: '',
    wheel: '',
  },
  profileRank: false,
  shop: false,
};
