import concat from 'lodash/concat';
import lzString from 'lz-string';
import { interval, Observable, OperatorFunction, pipe } from 'rxjs';
import { concatAll, delayWhen, filter, map, pluck } from 'rxjs/operators';

import { getEnvVariable } from '@gaming1/g1-env';

import { logger } from './logger';
import {
  DataPacket,
  EDataPacketDirection,
  EPacketType,
  IdentificationPacket,
  MetaData,
  PacketWrapper,
  RawDataPacket,
  Request,
  TokenAuthenticationRequestDTO,
  TokenAuthenticationResponseDTO,
  UnknownObject,
  WsAdapterOptions,
} from './types';

// The lib can be used in both dom and non dom env
type GlobalWithConditionalWindow = typeof globalThis & {
  window?: {
    navigator?: { userAgent?: string };
  };
};

const conditionalWindow = (globalThis as GlobalWithConditionalWindow).window;

/* Compression */

const COMPRESSION_HEADER = '--##LZS2##--';

// Needed for guid generation
/* eslint-disable no-bitwise */
// From https://stackoverflow.com/a/2117523

/** Returns true if the given string message contains the compression header,
 * indicating it has been compressed with LZS2 */
const isMessageCompressed = (message: string) =>
  message.slice(0, COMPRESSION_HEADER.length) === COMPRESSION_HEADER;

/** Decompress the string message that was compressed with LZS2 */
const decompressMessage = (message: string) =>
  lzString.decompressFromUTF16(message.slice(12)) ?? '';

/** Parse stringified json message and decompress if needed */
export const parseAndDecompressMessage = (message: string) =>
  JSON.parse(
    isMessageCompressed(message) ? decompressMessage(message) : message,
  );

/* Messages processing */

// Duplicated in g1-utils/src/helpers.ts to avoid extra dependency
/**
 * Generate a uuid v4
 * @returns string
 */
export const generateUuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * Convert a RawDataPacket (with $type and $values in its Requests property) to
 * a proper DataPacket
 * @param packet raw data packet from the API
 * @returns a valid DataPacket object
 */
export const deserializeDataPacket = (
  packet: DataPacket | RawDataPacket,
): DataPacket => {
  const newPacket: DataPacket = {
    Direction: packet.Direction,
    Id: packet.Id,
    Groups: [],
    Requests: [],
  };
  newPacket.Requests = Object.hasOwnProperty.call(packet.Requests, '$values')
    ? (packet as RawDataPacket).Requests.$values
    : (packet as DataPacket).Requests;
  newPacket.Groups = Object.hasOwnProperty.call(packet.Groups, '$values')
    ? (packet as RawDataPacket).Groups.$values
    : (packet as DataPacket).Groups;
  return newPacket;
};

/**
 * Parse a stringified JSON into an object, decompressing it if needed
 * @param messageString the stringified message
 * @returns the (unknown) content of a message
 */
export const processMessage = (messageString: string): unknown => {
  let messageObject = {};

  try {
    messageObject = parseAndDecompressMessage(messageString);
  } catch (e) {
    logger.error(`Could not JSON parse the message content`, messageString);
  }
  return messageObject;
};

/* PACKET WRAPPER */

/**
 * Wrap a packet with a PacketWrapper
 * @param message The message (either stringified or the original object)
 * @param messageType The type of the message (from the EPacketType enum)
 * @returns a PacketWrapper object
 */
export const wrapPacket = (
  message: string | UnknownObject,
  messageType: EPacketType = EPacketType.DataPacket,
): PacketWrapper => ({
  Id: generateUuid(),
  Message: typeof message === 'string' ? message : JSON.stringify(message),
  MessageType: messageType,
  TTL: 10,
});

/* DATA PACKET */

/**
 * Create a DataPacket from a single of multiple Request(s)
 * @param requests Request object or array of Requests
 * @returns a DataPacket object containing the given Request
 */
export const createDataPacket = (requests: Request[] | Request): DataPacket => {
  const requestArray: Request[] = concat([], requests);
  if (!requestArray.length) {
    throw new Error('Empty requests array provided!');
  }
  return {
    Direction: EDataPacketDirection.Request,
    Id: generateUuid(),
    Requests: requestArray,
    Groups: [],
  };
};

/**
 * Create a RawDataPacket from a single of multiple Request(s)
 * ! For testing only! These are sent by the back-end
 * @param requests Request object or array of Requests
 */
export const createRawDataPacket = (
  requests: Request[] | Request,
  type = EDataPacketDirection.Response,
  groups: string[] = [],
): RawDataPacket => {
  const requestArray: Request[] = Array.isArray(requests)
    ? requests
    : [requests];
  if (!requestArray.length) {
    throw new Error('Empty requests array provided!');
  }
  return {
    Direction: type,
    Id: generateUuid(),
    Requests: {
      $type: 'mockServerPacketRequests',
      $values: requestArray,
    },
    Groups: {
      $type: 'mockServerPacketGroups',
      $values: groups,
    },
  };
};

/**
 * Create a DataPacket wrapped inside a PacketWrapper
 * @param requests Request object or array of Requests
 * @returns a PacketWrapper with the given Requests
 */
export const createWrappedDataPacket = (
  requests: Request[] | Request,
): PacketWrapper =>
  wrapPacket(createDataPacket(requests), EPacketType.DataPacket);

/**
 * Create a RawDataPacket wrapped inside a PacketWrapper
 * ! For testing only! These are sent by the back-end
 * @param requests Request object or array of Requests
 */
export const createWrappedRawDataPacket = (
  requests: Request[] | Request,
): PacketWrapper =>
  wrapPacket(createRawDataPacket(requests), EPacketType.DataPacket);

/* MULTICAST PACKETS */

/** Create a multicast registration packet to subscribe to a multicast group */
export const createMulticastRegistrationPacket = (
  groupNames: string | string[],
) => ({
  Direction: EDataPacketDirection.Request,
  Id: generateUuid(),
  Register: Array.isArray(groupNames) ? groupNames : [groupNames],
  Unregister: [],
});

/** Create a wrapped multicast registration packet to subscribe to a multicast group
 * */
export const createWrappedMulticastRegistrationPacket = (
  groupNames: string | string[],
) =>
  wrapPacket(
    createMulticastRegistrationPacket(groupNames),
    EPacketType.MulticastRegistrationPacket,
  );

/** Create a multicast unregister packet to unsubscribe to a multicast group */
export const createMulticastUnregistrationPacket = (
  groupNames: string | string[],
) => ({
  Direction: EDataPacketDirection.Request,
  Id: generateUuid(),
  Register: [],
  Unregister: Array.isArray(groupNames) ? groupNames : [groupNames],
});

/** Create a wrapped multicast unregister packet to unsubscribe to a multicast
 * group */
export const createWrappedMulticastUnregistrationPacket = (
  groupNames: string | string[],
) =>
  wrapPacket(
    createMulticastUnregistrationPacket(groupNames),
    EPacketType.MulticastRegistrationPacket,
  );

/* OTHER SPECIAL PACKETS */

/**
 * Create a wrapped packet used to tell the APR that the user has switched its
 * locale
 * @param locale the locale code (two letters)
 * @returns a wrapped locale update packet
 */
export const createWrappedLanguagePacket = (locale: string) =>
  wrapPacket(
    {
      Language: locale,
    },
    EPacketType.ChangeLanguagePacket,
  );

/**
 * Create a packet with an array of metadata
 * @param metaData a metadata dictionary (string key/values)
 * @returns a wrapped metadata packet
 */
export const createWrappedMetaDataPacket = (metaData: MetaData) =>
  wrapPacket(
    {
      Id: generateUuid(),
      MetaDatas: Object.entries(metaData).map(([key, value]) => ({
        Key: key,
        Value: value,
      })),
    },
    EPacketType.MetaDataPacket,
  );

/**
 * Create a wrapped package used to tell the APR that the user has logged out
 * @returns a wrapped logout packet
 */
export const createWrappedLogoutPacket = () =>
  wrapPacket({}, EPacketType.ResetAuthenticationPacket);

/**
 * Create a wrapped disconnect packet to warn the websocket that the
 * connection should be closed
 * @returns a wrapped disconnect packet
 */
export const createWrappedDisconnectPacket = () =>
  wrapPacket({}, EPacketType.DisconnectPacket);

/* REQUESTS */

/**
 * Stringify the content of a Request. Can throw if the given object is not
 * serializable
 * @param content the Request content object
 * @returns a stringified object
 */
export const stringifyRequestContent = (content: UnknownObject) => {
  let contentString = '';
  try {
    contentString = JSON.stringify(content);
  } catch (e) {
    logger.error('[WsAdapter] Could not stringify request content');
    throw e;
  }
  return contentString;
};

/**
 * Parse the stringified JSON content of a Request, decompressing it if needed.
 * Can throw if the given string is not a valid stringified json
 * @param content the Request content property
 * @returns the parsed message object (unknown) content
 */
export const parseRequestContent = (contentString: string): unknown => {
  let contentObject = {};
  try {
    contentObject = parseAndDecompressMessage(contentString);
  } catch (e) {
    logger.error('[WsAdapter] Could not parse request content');
    throw e;
  }
  return contentObject;
};

// Needed because historically createRequest could take a generic type to
// indicate what the response could be and the g1-*-requests packages were
// generated with it
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Create a Request
 * @param type The numeric type of the request (e.g. 100 for gaming, 200 for betting, etc.)
 * @param identifier The Method you are calling
 * @param content The content of the Request either stringified or the original object
 * @returns a Request object
 */
export const createRequest = <ResponseDTO = UnknownObject>(
  /* eslint-enable @typescript-eslint/no-unused-vars */
  type: number,
  identifier: string,
  content: string | UnknownObject,
): Request => ({
  Id: generateUuid(),
  Type: type,
  Identifier: identifier,
  Content:
    typeof content === 'string' ? content : stringifyRequestContent(content),
});

/* Format helpers */

/**
 * Returns a parsed message from a PacketWrapper from the API
 * @param wsMessage the PacketWrapper from the API
 * @returns the (unknown) content of the message
 */
export const parseMessage = (wsMessage: PacketWrapper) =>
  processMessage(wsMessage.Message);

/**
 * Returns an array of Request objects from a RawDataPacket from the API
 * @param packet the RawDataPacket from the API
 * @returns an array of Requests
 */
export const extractRequests = (
  packet: RawDataPacket | DataPacket,
): Request[] => {
  const processedPacket = deserializeDataPacket(packet);
  return 'Requests' in processedPacket ? processedPacket.Requests : [];
};

/* Filter predicates */

/**
 * Function meant to be used in `filter` array method so that typescript inference is correct.
 * Check if value != null. (duplicate from g1-utils)
 * @returns true if the given value is not null and not undefined
 */
export const isNonNullable = <T>(value: T): value is NonNullable<T> =>
  value != null;

// Packet wrappers

/**
 * Determine if a PacketWrapper contains a DataPacket
 * @param packetWrapper the PacketWrapper from the API
 */
export const isDataPacketWrapper = (packetWrapper: PacketWrapper) =>
  packetWrapper.MessageType === EPacketType.DataPacket;

/**
 * Determine if a PacketWrapper contains a ResetAuthenticationResponsePacket
 * @param packetWrapper the PacketWrapper from the API
 */
export const isLogoutSuccessPacketWrapper = (packetWrapper: PacketWrapper) =>
  packetWrapper.MessageType === EPacketType.ResetAuthenticationResponsePacket;

/**
 * Determine if a PacketWrapper contains a ChangeLanguageResponsePacket
 * @param packetWrapper the PacketWrapper from the API
 */
export const isChangeLanguagePacketWrapper = (packetWrapper: PacketWrapper) =>
  packetWrapper.MessageType === EPacketType.ChangeLanguageResponsePacket;

/**
 * Determine if a PacketWrapper contains a MetaDataAckPacket
 * @param packetWrapper the PacketWrapper from the API
 */
export const isMetadataAckPacketWrapper = (packetWrapper: PacketWrapper) =>
  packetWrapper.MessageType === EPacketType.MetaDataAckPacket;

// Data packets

/**
 * Determine if the DataPacket was sent to the client as a multicast
 * @param dataPacket a DataPacket object
 */
export const isMulticastPacket = (dataPacket: DataPacket | RawDataPacket) =>
  dataPacket.Direction === EDataPacketDirection.Broadcast;

/**
 * Determine if the DataPacket was sent to the client as a push
 * @param dataPacket a DataPacket object
 */
export const isPushPacket = (dataPacket: DataPacket | RawDataPacket) =>
  dataPacket.Direction === EDataPacketDirection.Push;

// Requests

/**
 * Determine if a Request has the id provided
 * @param id the id of the request
 */
export const isFromTheRequestId = (id: string) => (request: Request) =>
  request.Id === id;

/**
 * Determine if the request was injected by the backend
 * @param request a Request object
 */
export const isInjectedRequest = (request: Request) => !!request.Injected;

/* Type guards */

/**
 * Determine if the given object is a valid PacketWrapper
 * @param packetWrapper an unknown object
 */
export const isPacketWrapper = (
  packetWrapper: unknown,
): packetWrapper is PacketWrapper =>
  typeof packetWrapper === 'object' &&
  !!packetWrapper &&
  'Id' in packetWrapper &&
  'Message' in packetWrapper;

/**
 * Check if the input object is a valid RawDataPacket
 * @param packet an unknown object
 */
export const isRawDataPacket = (packet: unknown): packet is RawDataPacket =>
  typeof packet === 'object' &&
  !!packet &&
  'Requests' in packet &&
  '$values' in (packet as RawDataPacket).Requests &&
  !!(packet as RawDataPacket).Requests.$values.length;

/**
 * Check if the input object is a valid DataPacket
 * @param packet an unknown object
 */
export const isDataPacket = (packet: unknown): packet is DataPacket =>
  typeof packet === 'object' &&
  !!packet &&
  'Requests' in packet &&
  Array.isArray((packet as DataPacket).Requests) &&
  !!(packet as DataPacket).Requests.length;

/**
 * Check if the input object is a valid DataPacket or RawDataPacket
 * @param packet an unknown object
 */
export const isDataOrRawDataPacket = (
  packet: unknown,
): packet is DataPacket | RawDataPacket =>
  isRawDataPacket(packet) || isDataPacket(packet);

type AbortedRequest = Omit<Request, 'Id'> & { IdRequest: string };

/**
 * Check if the input object is a request that was aborted because of the legal
 * popup
 * @param input an unknown object
 */
const isAbortedRequest = (input: unknown): input is AbortedRequest =>
  typeof input === 'object' &&
  !!input &&
  'IdRequest' in input &&
  'Type' in input &&
  'Identifier' in input &&
  'Content' in input;

type ShowLegalPopupContent = {
  ShowLegalPopup: boolean;
  RequestsAborted?: AbortedRequest[];
};

/**
 * Check if the input is a the data packet content is asking for the legal popup
 * to be shown to the user
 * @param dataPacketContent the unknown content of a datapacket
 * @returns
 */
export const isShowLegalPopupContent = (
  dataPacketContent: unknown,
): dataPacketContent is ShowLegalPopupContent => {
  const dataPacketTyped = dataPacketContent as ShowLegalPopupContent;
  // Must be an object
  if (typeof dataPacketContent !== 'object' || !dataPacketContent) {
    return false;
  }
  // Must have bool prop ShowLegalPopup
  if (
    !('ShowLegalPopup' in dataPacketContent) ||
    typeof dataPacketTyped.ShowLegalPopup !== 'boolean'
  ) {
    return false;
  }
  // If has RequestsAborted as prop, must be an array
  if (
    !Array.isArray(dataPacketTyped?.RequestsAborted) &&
    dataPacketTyped?.RequestsAborted !== undefined
  ) {
    return false;
  }
  // RequestsAborted must be an array of requests
  if (
    Array.isArray(dataPacketTyped?.RequestsAborted) &&
    !dataPacketTyped.RequestsAborted.every(isAbortedRequest)
  ) {
    return false;
  }
  return true;
};

type AcceptLegalPopupContent = {
  AcceptLegalPopupStatus: number;
};

/**
 * Check if the data packet content is the the acceptation of the legal popup
 * @param dataPacketContent
 */
export const isAcceptLegalPopupContent = (
  dataPacketContent: unknown,
): dataPacketContent is AcceptLegalPopupContent => {
  const dataPacketTyped = dataPacketContent as AcceptLegalPopupContent;
  // Must be an object
  if (typeof dataPacketContent !== 'object' || !dataPacketContent) {
    return false;
  }
  // Must have bool prop ShowLegalPopup
  if (
    !('AcceptLegalPopupStatus' in dataPacketContent) ||
    typeof dataPacketTyped.AcceptLegalPopupStatus !== 'number'
  ) {
    return false;
  }
  return true;
};

/* Mapping */

/**
 * Convert an AbortedRequest to a regular Request
 * @param abortedRequest an AbortedRequest generated when the legal popup was
 * displayed
 * @returns a Request to be sent after the legal popup has been accepted
 */
export const convertAbortedRequestToRequest = (
  abortedRequest: AbortedRequest,
): Request => {
  const { IdRequest, ...rest } = abortedRequest;
  return {
    ...rest,
    Id: IdRequest,
  };
};

/* RXJS */

/**
 * rxjs pipe that transform a packet wrapper to a (raw) data packet
 */
export const packetWrapperToDataPacket = pipe(
  filter(isDataPacketWrapper),
  map(parseMessage),
  filter(isDataOrRawDataPacket),
);

/**
 * rxjs pipe that transform a (raw) data packet to an array of requests
 */
export const dataPacketToRequests = pipe(map(extractRequests), concatAll());

/**
 * rxjs pipe that transform packet wrappers (if they contain data packets) to an
 * array of requests
 */
export const mapPacketWrapperToRequests = pipe(
  // <PacketWrapper, Request>
  filter(isDataPacketWrapper),
  packetWrapperToDataPacket,
  map(deserializeDataPacket),
  pluck('Requests'),
  concatAll(),
);

/**
 * rxjs pipe that transform a packet wrapper to an array of requests
 */
export const packetWrapperToRequests = pipe(
  packetWrapperToDataPacket,
  dataPacketToRequests,
);

/**
 * Rxjs pipe that transform a request into a parsed request content
 */
export const requestToContent = pipe(
  map((req: Request) => parseRequestContent(req.Content)),
);

/**
 * Custom operator function that will delay the observable only when a delay is
 * given
 * @param responseDelay the delay in ms. 0 for disabling the delaying
 */
export const delayResponseIfNeeded =
  <T>(responseDelay = 0): OperatorFunction<T, T> =>
  (source: Observable<T>): Observable<T> =>
    responseDelay
      ? source.pipe(delayWhen(() => interval(responseDelay)))
      : source;

/* Packet creators */

export const createAppCompatMetadata = () => ({
  V4Ready: 'True',
});

export type IdentificationPacketOptions = {
  compression?: string;
  locale: string;
  room: string;
};
/**
 * Create an IdentificationPacket
 * @param compression Type of supported compression (if any)
 * @returns an IdentificationPacket
 */
export const createIdentificationPacket = ({
  compression,
  locale,
  room,
}: IdentificationPacketOptions): IdentificationPacket => ({
  NodeType: 0x01,
  Identity: generateUuid(),
  SupportedCompressions: compression,
  ClientInformations: {
    AppName: ['Front', 'Registration-Origin: empty'].join(';'),
    ClientType: 'React',
    Version: getEnvVariable('appVersion') || '0.0.0',
    UserAgent:
      typeof conditionalWindow !== 'undefined' &&
      conditionalWindow.navigator &&
      conditionalWindow.navigator.userAgent
        ? conditionalWindow.navigator.userAgent
        : 'node',
    LanguageCode: locale,
    RoomDomainName: room,
  },
});

/**
 * Create an IdentificationPacket wrapped inside a PacketWrapper
 * @param compression Type of supported compression (if any)
 * @returns a wrapped IdentificationPacket
 */
export const createWrappedIdentificationPacket = (
  options: IdentificationPacketOptions,
): PacketWrapper =>
  wrapPacket(
    createIdentificationPacket(options),
    EPacketType.IdentificationPacket,
  );

/**
 * Create an request asking for the authentication of the user wrapped inside a
 * PacketWrapper
 * @param options the auth token and the user language
 * @returns a wrapped authentication request
 */
export const createAuthenticateTokenRequest = (
  options: TokenAuthenticationRequestDTO,
) =>
  createRequest<TokenAuthenticationResponseDTO>(
    100,
    'AuthenticateToken',
    options,
  );

/* Options */

const adapterOptionsValidator: {
  [k in keyof WsAdapterOptions]?: { min: number; max: number };
} = {
  defaultTimeout: { min: 500, max: 5 * 60 * 1000 },
  reconnectAttempts: { min: 0, max: Infinity },
  reconnectDelay: { min: 0, max: 60 * 1000 },
  reconnectIncrement: { min: 0, max: 60 * 1000 },
  timeoutValueMultiplicator: { min: 1, max: 10 },
};

/**
 * Remove any incorrect value provided to the wdAdapter init options argument
 */
export const sanitizeWsAdapterOptions = (
  options: WsAdapterOptions,
): WsAdapterOptions => {
  const customOptions = { ...options };
  Object.entries(adapterOptionsValidator).forEach(
    ([key, { min, max } = { min: 0, max: Infinity }]) => {
      const optionKey = key as keyof typeof adapterOptionsValidator;
      if (
        optionKey in options &&
        !!options[optionKey] &&
        ((options?.[optionKey] || 0) < min ||
          (options?.[optionKey] || Infinity) > max)
      ) {
        logger.error(
          `[WS] Invalid value for option ${key}: "${options[optionKey]}". Using default value instead`,
        );
        delete customOptions[optionKey];
      }
    },
  );

  // Transform a single string into an array of string to avoid breaking changes
  customOptions['address'] =
    typeof customOptions['address'] === 'string'
      ? [customOptions['address']]
      : customOptions['address'];

  return customOptions;
};
