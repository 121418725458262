import {
  getDeployEnv,
  getEnvVariable,
  getWorkingEnv,
  getWrapperEnv,
} from '@gaming1/g1-env';

import { getLogger, LogLevel } from '../loglevel';
import { conditionalWindow } from '../types/conditionalWindow';

export const createLogger = (
  loggerName: string,
): ReturnType<typeof getLogger> => {
  const env = getDeployEnv();
  const logger = getLogger(loggerName);
  if (
    !conditionalWindow?.shouldBypassJestDetection &&
    typeof jest !== 'undefined'
  ) {
    // Mute logger for jest tests
    logger.disableAll();
  } else if (
    getEnvVariable('nodeEnv') === 'development' &&
    (getWrapperEnv() === 'rn' || getWorkingEnv() === 'react-native')
  ) {
    // Show all logs when in the RN app
    logger.enableAll();
  } else if (env === 'production') {
    logger.setLevel(LogLevel.WARN);
  } else if (env === 'local') {
    logger.setLevel(LogLevel.DEBUG);
  } else {
    logger.setLevel(LogLevel.INFO);
  }
  return logger;
};
