import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { I18nState } from './types';

export const initialState: I18nState = {
  requests: {
    switchLocale: generateInitialRequestState(),
  },
  webSocketLocale: 'en',
  whitelistedLanguages: [],
};

export const i18nReducer = (
  state: I18nState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.initApp):
        draftState.webSocketLocale = action.payload.locale;
        break;

      case getType(actions.switchLocale.request):
        produceLoadingState(draftState.requests.switchLocale);
        break;

      case getType(actions.switchLocale.success):
        produceSuccessState(draftState.requests.switchLocale);
        draftState.webSocketLocale = action.payload;
        break;

      case getType(actions.switchLocale.failure):
        produceFailureState(draftState.requests.switchLocale, action.payload);
        break;

      case getType(actions.getShellParams.success):
        draftState.whitelistedLanguages =
          action.payload.AvailableLanguages || [];
        break;

      default: // Immer will automatically return the state
    }
  });
