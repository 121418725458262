import { useCallback, useMemo } from 'react';

import { AppConfig, useConfig } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-i18n';

import { getRoutePath } from '../helpers';
import { RouteList, RouteObject, RouteParams, SearchParams } from '../types';

import { useRoutesOverwrites } from './useRoutesOverwrites';

/**
 * Accepts a RouteList object and return a function that returns a path from a
 * route name
 */
export const useRoutePath = <
  RouteNames extends string,
  Routes extends RouteList<RouteNames>,
>(
  routesGetter: (config: AppConfig) => Routes,
) => {
  const { currentLocale: localeCode } = useI18n();
  const config = useConfig();
  const inputRoutes = useMemo(
    () => routesGetter(config),
    [config, routesGetter],
  );
  const routes = useRoutesOverwrites(inputRoutes);
  return useCallback(
    <
      RouteName extends keyof Routes,
      Route extends Routes[RouteName] = Routes[RouteName],
    >(
      routeName: RouteName,
      ...parameters: RouteParams<NonNullable<Route>> extends undefined
        ? [undefined?, SearchParams?]
        : [RouteParams<NonNullable<Route>>, SearchParams?]
    ) => {
      const route = routes[routeName];
      if (!route) {
        // TODO: return null and adapt the code using it
        return `/${localeCode}`;
      }
      const [params, searchParams] = parameters;
      if (params === undefined) {
        return getRoutePath<null, RouteObject<null>>(
          route as RouteObject<null>,
          params,
          localeCode,
          searchParams,
        );
      }
      return getRoutePath(route, params, localeCode, searchParams);
    },
    [localeCode, routes],
  );
};
