import { createContext } from 'react';

import { AssetsContext } from './types';

export const CoreAssetsContext = createContext<AssetsContext>({
  /* UI buttons */
  backToTheTopArrow: '',
  genericError: '',
  networkError: '',
  /* Logos */
  logo: '',
  logoDark: '',
  logoLight: '',
  logoSmall: '',
  /* Banners */
  defaultSliderLarge: '',
  defaultSliderSmall: '',
  /* Illustrations */
  failure: '',
  notFound: '',
  questioning: '',
  noResults: '',
  repair: '',
  successCashier: '',
  successWelcome: '',
  warning: '',
  /* Geolocation */
  androidApkEn: '',
  androidApkFr: '',
  appStoreComingSoonEn: '',
  appStoreComingSoonFr: '',
  appStoreEn: '',
  appStoreFr: '',
  googlePlayComingSoonEn: '',
  googlePlayComingSoonFr: '',
  googlePlayEn: '',
  googlePlayFr: '',
  laptop: '',
  mobile: '',
});
