import { createAction, createAsyncAction } from 'typesafe-actions';

import {
  TokenAuthenticationRequestDTO,
  TokenAuthenticationResponseDTO,
} from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import {
  GetAuthTokenSuccessResponse,
  ImpersonateUserSuccessResponse,
  LoginFormData,
  LoginSuccessPayload,
  LogoutPayload,
} from './types';

/** Get a login token from the ajax API by sending username and password */
export const login = createAsyncAction(
  'core/login_request',
  'core/login_success',
  'core/login_failure',
)<
  LoginFormData,
  LoginSuccessPayload,
  FailurePayload & { exclusionDate?: string | null }
>();

/** Clean login request state */
export const cleanLogin = createAction('core/clean_login')();

/** Auth the user on the websocket by sending a user token */
export const auth = createAsyncAction(
  'core/auth_request',
  'core/auth_success',
  'core/auth_failure',
)<
  Required<TokenAuthenticationRequestDTO>,
  TokenAuthenticationResponseDTO,
  FailurePayload
>();

/** Logout the user from the websocket and the ajax API */
export const logout = createAsyncAction(
  'core/logout_request',
  'core/logout_success',
  'core/logout_failure',
)<LogoutPayload | void, void, FailurePayload>();

/** Keep the session alive using the ajax API (cookie needed) */
export const keepSessionAlive = createAsyncAction(
  'core/keep_session_alive_request',
  'core/keep_session_alive_success',
  'core/keep_session_alive_failure',
)<void, void, FailurePayload>();

/** Get a new token from the ajax API (cookie needed) */
export const getAuthToken = createAsyncAction(
  'core/get_auth_token_request',
  'core/get_auth_token_success',
  'core/get_auth_token_failure',
)<void, GetAuthTokenSuccessResponse, FailurePayload>();

/** Restore the authentication using the ajax API (cookie needed) */
export const restoreAuthentication = createAsyncAction(
  'core/restore_authentication_request',
  'core/restore_authentication_success',
  'core/restore_authentication_failure',
)<void, GetAuthTokenSuccessResponse, FailurePayload>();

/** Login to another user account using a special token */
export const impersonateUser = createAsyncAction(
  'core/impersonate_user_request',
  'core/impersonate_user_success',
  'core/impersonate_user_failure',
)<
  { Login: string; Password: string; Domain: string; ImpersonationId: string },
  ImpersonateUserSuccessResponse,
  FailurePayload
>();
