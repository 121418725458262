import { useEffect } from 'react';

import {
  locationChangedMessage,
  locationChangedMessageCodec,
} from '@gaming1/g1-core-api';
import { publishGlobalMessage } from '@gaming1/g1-message-bus';
import { useHistoryContext } from '@gaming1/g1-routing';

/**
 * Hook that emits a locationChanged-message to the message bus when the router
 * emits a history event in rxjs.
 *
 * (The router does not have direct access to the message bus because of the
 * dependency hierarchy)
 */
export const usePublishLocationChanges = () => {
  const { history$ } = useHistoryContext();
  useEffect(() => {
    const subscription = history$.subscribe((event) => {
      if (!locationChangedMessageCodec.is(event)) {
        return;
      }

      publishGlobalMessage(locationChangedMessage, event);
    });
    return () => subscription.unsubscribe();
  }, [history$]);
};
