import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import {
  actions,
  connectionToGeoComplyRequestStateSelector,
  CoreActions,
  GeoComplyStatusCode,
} from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';
import { RemoteData } from '@gaming1/g1-utils';

import { logger } from '../../../logger';

import { useGeoComplySDK } from './useGeoComplySDK';

/** Connect our app to GeoComply */
export const useGeoComplyConnection = () => {
  const {
    core: {
      geoComply: { environment, installerKey },
    },
  } = useContext(ConfigContext);
  const dispatch = useDispatch();
  const { status: connectionToGeoComplyRequestStatus } = useRequestState(
    connectionToGeoComplyRequestStateSelector,
  );

  const geoComplySDK = useGeoComplySDK();

  /**
   * This hook handles the connection to GeoComply by detecting the status
   * of the connectToGeoComply request state which must be set to 'Loading'
   */
  useEffect(() => {
    if (connectionToGeoComplyRequestStatus === RemoteData.Loading) {
      geoComplySDK?.Client.connect(installerKey, environment);
    }
  }, [
    connectionToGeoComplyRequestStatus,
    environment,
    installerKey,
    geoComplySDK,
  ]);

  /**
   * If the script is loaded and GeoComply was not connected yet,
   * then init GeoComply's subscribers and attempt to connect to the PLC
   */
  useEffect(() => {
    if (!!geoComplySDK && !geoComplySDK.Client.isConnected()) {
      const handleConnection = () => {
        logger.info(
          '[Geolocation] [Connection] GeoComply connected successfully',
        );
        dispatch(actions.connectToGeoComply.success());
      };

      const handleError = (errorCode: string, errorMessage: string) => {
        // Default failure action to be dispatched
        const actionsToDispatch: CoreActions[] = [];
        let log = `[Geolocation] [Connection] failed. Details: ErrCode=[${errorCode}]; ErrMessage=[${errorMessage}]`;

        switch (+errorCode) {
          case GeoComplyStatusCode.CLNT_ERROR_TRANSACTION_TIMEOUT:
            actionsToDispatch.push(
              actions.requestGeolocation.failure({
                status: +errorCode,
                errorMessage,
              }),
            );
            log = `[Geolocation] [Connection] to GeoComply Client failed. Details: ErrCode=[${errorCode}]; ErrMessage=[${errorMessage}]`;
            break;
          /**
           * CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE: The PLC is likely not
           * installed or started
           * CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION: The PLC was detected but
           * there is an issue with its communication (after a computer sleep
           * for example)
           * CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER: The PLC version used is not
           * correct
           */
          case GeoComplyStatusCode.CLNT_ERROR_LOCAL_SERVICE_COMMUNICATION:
          case GeoComplyStatusCode.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE:
          case GeoComplyStatusCode.CLNT_ERROR_LOCAL_SERVICE_UNSUP_VER:
            log = `[Geolocation] Connection to GeoComply Client failed. Details: ErrCode=[${errorCode}]; ErrMessage=[${errorMessage}]`;

            actionsToDispatch.push(
              actions.connectToGeoComply.failure({
                status: +errorCode,
                errorMessage,
              }),
            );
            break;
          /** The license expired or is not valid */
          case GeoComplyStatusCode.CLNT_ERROR_CLIENT_LICENSE_UNAUTHORIZED:
          case GeoComplyStatusCode.CLNT_ERROR_LICENSE_EXPIRED:
          case GeoComplyStatusCode.CLNT_ERROR_INVALID_LICENSE_FORMAT:
            actionsToDispatch.push(
              actions.requestGeolocation.failure({
                status: +errorCode,
                errorMessage,
              }),
              actions.getGeoComplyLicenseKey.request(),
            );
            break;
          case GeoComplyStatusCode.CLNT_ERROR_SERVER_COMMUNICATION:
          case GeoComplyStatusCode.CLNT_ERROR_WRONG_OR_MISSING_PARAMETER:
          default:
            actionsToDispatch.push(
              actions.requestGeolocation.failure({
                status: +errorCode,
                errorMessage,
              }),
            );
            break;
        }

        if (
          +errorCode ===
          GeoComplyStatusCode.CLNT_ERROR_LOCAL_SERVICE_UNAVAILABLE
        ) {
          // Don't error if the PLC is not found
          logger.warn(
            `[Geolocation] The PLC was not found, it is likely not installed. Details: ErrCode=[${errorCode}]; ErrMessage=[${errorMessage}]`,
          );
        } else {
          logger.error(log);
        }
        actionsToDispatch.forEach((action) => dispatch(action));
      };

      const handleGeolocation = (geoComplyPacket: string) => {
        dispatch(actions.requestGeolocation.success(geoComplyPacket));
        logger.info(
          `[Geolocation] Data received from GeoComply's PLC : ${geoComplyPacket}`,
        );
      };

      const handleLog = (log: string) => {
        logger.info(`[Geolocation] New log set : ${log}`);
      };

      geoComplySDK.Client.on('connect', handleConnection)
        .on('error', handleError)
        .on('geolocation', handleGeolocation)
        .on('log', handleLog);

      geoComplySDK.Events.ready(window, () => {
        dispatch(actions.connectToGeoComply.request());
      });

      return () => {
        geoComplySDK?.Client.off('connect');
        geoComplySDK?.Client.off('error');
        geoComplySDK?.Client.off('geolocation');
        geoComplySDK?.Client.off('log');
      };
    }

    return () => undefined;
  }, [dispatch, geoComplySDK]);
};
