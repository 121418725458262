import { I18nConfig } from '../types';

/**
 * i18n configuration
 */
export const defaultI18nConfig: I18nConfig = {
  availableLanguages: ['fr', 'nl', 'en'],
  dateFormat: 'dd/MM/yyyy',
  dateShortFormat: 'dd/MM',
  dateTimeFormat: 'dd/MM/yyyy HH:mm:ss',
  dateTimeLongFormat: 'PPPp',
  dateTimePartialFormat: 'dd/MM/yyyy HH:mm',
  dateTimeShortPartialFormat: 'dd/MM HH:mm',
  dayOfTheWeekFormat: 'EEEE',
  defaultLanguage: 'fr',
  shouldBeRestrictedByShellParams: false,
  timeFormat: 'HH:mm:ss',
  timePartialFormat: 'HH:mm',
};
