/** List of minimum pixels for each media breakpoints */
export enum MediaBreakPointsEnum {
  xs = 320,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1850,
}

export type MediaBreakPointNames = keyof typeof MediaBreakPointsEnum;

export type MediaBreakPoint = {
  name: MediaBreakPointNames;
  value: number;
  width: string;
};

const isValidBreakpointTuple = (
  bp: [string, unknown],
): bp is [MediaBreakPointNames, number] =>
  Object.keys(MediaBreakPointsEnum).includes(bp[0]) &&
  typeof bp[1] === 'number';

/**
 * Create an array of media breakpoints with their name and width
 * [
 *   { name: 'xs', value: 320, width: '320px'},
 *   { name: 'sm', value: 576, width: '576px'},
 *   ...
 * ]
 */
export const mediaBreakPoints: MediaBreakPoint[] = Object.entries(
  MediaBreakPointsEnum,
)
  .filter(isValidBreakpointTuple)
  .sort((bp1, bp2) => (bp1[1] > bp2[1] ? 1 : -1))
  .map(([key, value]) => ({
    name: key,
    value,
    width: `${value}px`,
  }));

/**
 * returns a MediaBreakPoint for the given viewport width
 * @param width the viewport width
 */
export const getBreakpointFromWidth = (width: number): MediaBreakPoint =>
  mediaBreakPoints.reduce(
    (acc, bp) => (width > acc.value ? bp : acc),
    mediaBreakPoints[0],
  );

/** Every value possible for the colun width */
export type ColumnWidth = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
