import { Location } from 'history';

import { isLocaleCode, LocaleCode, LocaleCodeEnum } from '@gaming1/g1-config';

import {
  RouteList,
  RouteObject,
  RouteParams,
  RoutePath,
  SearchParams,
} from './types';

/**
 * Removes trailing slash and regroups multiple characters ('/';'#';'?')
 */
export const trimUrl = (url: string) =>
  url
    .replace(/\/+/g, '/')
    .replace(/#+/g, '#')
    .replace(/\?+/g, '?')
    .replace(/(?!^\/)\/$/, '');

/**
 * Generates a regex to match the locale in the URL
 */
export const getLocaleRegex = (locale: string) =>
  new RegExp(`^((/${locale}/)|(${locale}/)|(/${locale}(?=(#|\\?+)|$)))`);

/**
 * Check if the URL has a locale and extract it to use it later
 * @param url the current URL
 */
export const extractLocaleFromUrl = (
  url: string,
): LocaleCodeEnum | undefined => {
  const key = Object.keys(LocaleCodeEnum).find((locale) =>
    url.match(getLocaleRegex(locale)),
  ) as LocaleCode;
  return key ? LocaleCodeEnum[key] : undefined;
};

/**
 * Remove the given locale from the URL
 */
export const removeLocaleFromUrl = (url: string) => {
  const locale = extractLocaleFromUrl(url);
  return locale ? url.replace(getLocaleRegex(locale), '') : url;
};

/**
 * Replace the given locale in the URL
 */
export const replaceLocaleInUrl = (
  url: string,
  nextLocale: string,
): string | null => {
  const locale = extractLocaleFromUrl(url);
  return locale && isLocaleCode(nextLocale)
    ? trimUrl(url.replace(getLocaleRegex(locale), `/${nextLocale}/`))
    : null;
};

/**
 * Concat a location object (pathname, search, hash and locale code if needed)
 */
export const concatLocation = (
  location: Pick<Location, 'hash' | 'pathname' | 'search'>,
  locale = '',
) => {
  const newPathname = locale
    ? removeLocaleFromUrl(location.pathname)
    : location.pathname;

  const trimedSearch =
    location.search.charAt(0) === '?'
      ? location.search.substr(1)
      : location.search;
  const trimedHash =
    location.hash.charAt(0) === '#' ? location.hash.substr(1) : location.hash;

  const { hash, pathname, search } = {
    hash: trimedHash ? `#${trimedHash}` : '',
    pathname: newPathname ? `/${newPathname}` : '',
    search: trimedSearch ? `?${trimedSearch}` : '',
  };

  return trimUrl(`/${locale}${pathname}${search}${hash}`);
};

type BaseRouteParam = Record<string, string>;

export function createRoute(path: RoutePath): RouteObject<null>;
export function createRoute(
  path: RoutePath,
  condition: boolean,
): RouteObject<null> | null;
/**
 * Create a simple RouteObject (without any parameters)
 * @param {string} path the path to the route
 * @param {boolean} [condition]  an optional condition determining whether the
 * route is active or disabled. If this param is provided, the resulting route
 * will be nullable
 */
export function createRoute(
  path: RoutePath,
  condition?: boolean,
): RouteObject<null> | null {
  return condition === false
    ? null
    : {
        parameters: undefined,
        path,
      };
}

export function createParamRoute<Params extends BaseRouteParam>(
  path: RoutePath,
  parameters: Params,
): RouteObject<string & keyof Params>;
export function createParamRoute<Params extends BaseRouteParam>(
  path: RoutePath,
  parameters: Params,
  condition: boolean,
): RouteObject<string & keyof Params> | null;
/**
 * Create a RouteObject with parameters
 * @param path the path to the route
 * @param parameters the shape of the required parameters
 *
 * @example
 * // Typing parameters with inference (Params = { username: string})
 * const route = createParamRoute('/profile/:username', { username: '' })
 *
 * @example
 * // Typing parameters with explicit typing (Params = { view: 'day' | 'week' | 'month'})
 * const route = createParamRoute<{
 *  view: 'day' | 'week' | 'month'
 * }>('/calendar/:view', { view: 'day' })
 */
export function createParamRoute<Params extends BaseRouteParam>(
  path: RoutePath,
  parameters: Params,
  condition?: boolean,
) {
  return condition === false
    ? null
    : {
        path,
        parameters,
      };
}
/**
 * Create a query string given an object of URL parameters
 * @param searchObject a dictionary of parameters
 * @param [shouldAppendQuestionMark] if true, a ? will be appended to the path
 * no matter what. If false, will never be appended. If undefined, will only
 * append if the query string is not empty
 * @returns a query string
 */
export const getQueryStringFromObject = (
  searchObject: Record<string, string>,
  shouldAppendQuestionMark?: boolean,
) => {
  const queryString = Object.entries(searchObject).reduce(
    (acc, [key, value]) =>
      `${acc}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    '',
  );
  const isEmpty = queryString.length === 0;
  const shouldUseQuestionmark =
    typeof shouldAppendQuestionMark === 'boolean'
      ? shouldAppendQuestionMark
      : !isEmpty;
  return `${shouldUseQuestionmark ? '?' : ''}${
    isEmpty ? '' : queryString.substring(1)
  }`;
};

/** Identity function that just serve as a validation of the object given to it */
export const createRouteList = <
  RouteNames extends string,
  Routes extends RouteList<RouteNames>,
>(
  routes: Routes,
) => routes;

/**
 * Return a path using the given route and params
 * @param route a Route object
 * @param params the params linked to that route (can be undefined if none)
 * @param localeCode the two letter locale code
 * @param [searchParams] an optional query list object
 * @returns string
 */
export const getRoutePath = <
  Params extends string | null,
  Route extends RouteObject<Params>,
>(
  route: Route,
  params: RouteParams<Route>,
  localeCode: LocaleCode,
  searchParams?: SearchParams,
) => {
  const routeParams = route.parameters || {};
  const routePath = route.path;

  let path = '';
  if (!params || !routeParams || !Object.keys(routeParams).length) {
    path = routePath;
  } else {
    // Replace every :param or :param? in the path with the value
    path = Object.entries(routeParams)
      .map(([key]) => [key, params[key]])
      .reduce(
        (acc, [key, value]) => acc.replace(new RegExp(`:${key}\\??`), value),
        routePath as string,
      );
  }

  const search = searchParams ? getQueryStringFromObject(searchParams) : '';
  return trimUrl(`/${localeCode}${path}${search}`);
};

/** Return the page name from the given location */
export const getPageNameFromLocation = (location: Location) =>
  new URLSearchParams(location.search).get('pagename');

/** Return the search url from the given location without page name */
export const getSearchUrlWithoutPageNameFromLocation = (location: Location) => {
  const usp = new URLSearchParams(location.search);
  usp.delete('pagename');
  return usp.toString();
};

/** Return the path without parameters (example: /test/:testParams -> /test) */
export const getRoutePathWithoutParameters = (path: string) =>
  path.indexOf('/:') !== -1 ? path.substring(0, path.indexOf('/:')) : path;
