import { combineEpics } from 'redux-observable';

import { ApplicationState, CoreActions, coreReducer } from '@gaming1/g1-core';
import { getDeployEnv } from '@gaming1/g1-logger';
import { createStore, LazyStore } from '@gaming1/g1-store';

import { config$ } from './config';

const reducers = {
  core: coreReducer,
};

const epics = combineEpics<CoreActions, CoreActions, ApplicationState>();

type WindowWithReduxStore = Window & {
  store?: LazyStore;
};

const windowWithReduxStore = window as WindowWithReduxStore;

/**
 * Create the redux store and attach it to the global window object.
 * This allows the redux state to stay put between HMR.
 * Also allows to dispatch easily redux actions from e2e tests.
 */
const configureStore = () => {
  if (windowWithReduxStore.store == null) {
    const reduxStore = createStore<ApplicationState, CoreActions>({
      reducers,
      epics,
      config$,
    });
    if (getDeployEnv() === 'local') {
      windowWithReduxStore.store = reduxStore;
    }
    return reduxStore;
  }
  return windowWithReduxStore.store;
};

export const store = configureStore();
