import styled, { css } from 'styled-components';
import {
  flexDirection,
  FlexDirectionProps,
  space,
  SpaceProps,
} from 'styled-system';

import { media, MediaBreakPointNames, spaces } from '@gaming1/g1-style';

import { isMediaBreakPointBelowOrEqual } from '../helpers';

/**
 * Content width in px for each breakpoint
 */
export const CONTENT_WIDTH: { [size in MediaBreakPointNames]: number } = {
  // Width will fill the screen
  xs: 0,
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  xxl: 1790,
};

/**
 * Full HD width, this ensure that the vast majority of devices  will have the
 * content stretch to the viewport width
 */
export const FLUID_CONTENT_MAX_WIDTH = 1980;

export const ContentContainer = styled.div<SpaceProps & FlexDirectionProps>`
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  flex-direction: column;
  ${space}
  ${flexDirection}
`;

export const ResponsiveContentContainer = styled(ContentContainer)<{
  max?: MediaBreakPointNames;
}>`
  padding-left: ${spaces('xs')};
  padding-right: ${spaces('xs')};

  ${media.sm} {
    ${({ max }) =>
      (!max || isMediaBreakPointBelowOrEqual('sm', max)) &&
      css`
        max-width: ${CONTENT_WIDTH.sm}px;
        padding-left: ${spaces('sm')};
        padding-right: ${spaces('sm')};
      `}
  }

  ${media.md} {
    ${({ max }) =>
      (!max || isMediaBreakPointBelowOrEqual('md', max)) &&
      css`
        max-width: ${CONTENT_WIDTH.md}px;
      `}
  }

  ${media.lg} {
    ${({ max }) =>
      (!max || isMediaBreakPointBelowOrEqual('lg', max)) &&
      css`
        max-width: ${CONTENT_WIDTH.lg}px;
      `}
  }

  ${media.xl} {
    ${({ max }) =>
      (!max || isMediaBreakPointBelowOrEqual('xl', max)) &&
      css`
        max-width: ${CONTENT_WIDTH.xl}px;
      `}
  }

  ${media.xxl} {
    ${({ max }) =>
      (!max || isMediaBreakPointBelowOrEqual('xxl', max)) &&
      css`
        max-width: ${CONTENT_WIDTH.xxl}px;
      `}
  }

  transition: padding 0.2s, width 0.2s;
`;

export const FluidContentContainer = styled(ContentContainer)`
  padding-left: ${spaces('xs')};
  padding-right: ${spaces('xs')};

  ${media.sm} {
    padding-left: ${spaces('sm')};
    padding-right: ${spaces('sm')};
  }

  ${media.xxl} {
    /* Passed this breakpoint, the content will stop being in full width */
    max-width: ${FLUID_CONTENT_MAX_WIDTH}px;
  }

  transition: padding 0.2s, width 0.2s;
`;
