import debounce from 'lodash/debounce';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  ConfigContext,
  isLocaleCode,
  LocaleCode,
  useConfig,
} from '@gaming1/g1-config';
import { useConsentPolicy } from '@gaming1/g1-consent-management/shared';
import {
  appInitializedSelector,
  coreActions,
  interopMessages,
  isDebugModeEnabledSelector,
  useI18n,
  useNetwork,
  userLoggedInSelector,
} from '@gaming1/g1-core';
import { appStateMessage } from '@gaming1/g1-core-api';
import { getDeployEnv, getEnvVariable, getWrapperEnv } from '@gaming1/g1-env';
import { useTranslation } from '@gaming1/g1-i18n';
import { publishGlobalMessage } from '@gaming1/g1-message-bus';
import { WsAdapterOptions } from '@gaming1/g1-network';
import { concatLocation, extractLocaleFromUrl } from '@gaming1/g1-routing';
import { LayoutContext } from '@gaming1/g1-ui';
import {
  getSearchParam,
  persistIn,
  readFrom,
  useIsTabVisible,
  usePrevious,
} from '@gaming1/g1-utils';

import { logger } from '../../../logger';
import { sendIframeMessage } from '../../../rninterop/iframeMessageManager/helpers';

export const LAST_VISIT_STORAGE_STORAGE_KEY = 'lastVisit';
export const HAS_USER_ALREADY_LOGGED_IN_STORAGE_KEY = 'hasUserAlreadyLoggedIn';

export const LOCALE_SWITCH_DEBOUNCE_TIME_IN_MS = 100;

/**
 * Return true if the user has already logged in a previous session
 * Stored on the localStorage
 */
export const useHasUserAlreadyLoggedIn = (): boolean => {
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const config = useConfig();
  const { getUserConsentStatusForPurpose } = useConsentPolicy();

  const hasUserAlreadyLoggedIn = useMemo(() => {
    const hasUserAlreadyLoggedInStorage = readFrom(
      localStorage,
      HAS_USER_ALREADY_LOGGED_IN_STORAGE_KEY,
    );
    return hasUserAlreadyLoggedInStorage === true;
  }, []);

  const shouldPersistInLocalStorage =
    !!getUserConsentStatusForPurpose(
      config.core.privacySettings.purposeIDs.uxImprovement,
    ) &&
    !!isUserLoggedIn &&
    !hasUserAlreadyLoggedIn;

  /** Store in the browser that the user has already been logged in */
  useEffect(() => {
    if (shouldPersistInLocalStorage) {
      persistIn(localStorage, HAS_USER_ALREADY_LOGGED_IN_STORAGE_KEY, true);
    }
  }, [isUserLoggedIn, shouldPersistInLocalStorage]);
  return hasUserAlreadyLoggedIn;
};

/**
 * Return the last visit timestamp from a previous session (if any)
 * Stored in the localStorage
 */
export const useLastVisitTimeStamp = (): number | null => {
  const config = useConfig();
  const { getUserConsentStatusForPurpose } = useConsentPolicy();
  const shouldUseLastVisit = !!getUserConsentStatusForPurpose(
    config.core.privacySettings.purposeIDs.uxImprovement,
  );

  const lastVisitTimestamp = useMemo(() => {
    const lastVisitTimestampStorage = readFrom(
      localStorage,
      LAST_VISIT_STORAGE_STORAGE_KEY,
    );

    return typeof lastVisitTimestampStorage === 'number' &&
      !!lastVisitTimestampStorage
      ? lastVisitTimestampStorage
      : null;
  }, []);

  useEffect(() => {
    if (shouldUseLastVisit) {
      persistIn(
        localStorage,
        LAST_VISIT_STORAGE_STORAGE_KEY,
        new Date().getTime(),
      );
    }
  }, [shouldUseLastVisit]);
  return lastVisitTimestamp;
};

/** Send an exitApp action when the tab/browser is about to be closed */
export const useAppExit = () => {
  const dispatch = useDispatch();

  /** Send an action that will warn the websocket that the app is exiting */
  const exitApp = useCallback(() => {
    dispatch(coreActions.exitApp());
  }, [dispatch]);

  /** Listen on tab/browser close */
  useEffect(() => {
    if (getWrapperEnv() !== 'rn') {
      window.addEventListener('beforeunload', exitApp);
      return () => {
        window.removeEventListener('beforeunload', exitApp);
      };
    }
    return () => null;
  }, [exitApp]);
};

export const useInitApp = ({
  wsAdapterOptions,
}: {
  wsAdapterOptions?: Partial<WsAdapterOptions>;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const config = useConfig();

  /**
   * For reasons unknown, using the location object in the useCallback methods
   * is buggy, as it has the previous location, even if the location object
   * is in their dependency array
   */
  const locRef = useRef(location);
  locRef.current = location;

  const { i18n } = useTranslation(undefined, { useSuspense: false });

  const hasUserAlreadyLoggedIn = useHasUserAlreadyLoggedIn();
  const lastVisitTimestamp = useLastVisitTimeStamp();

  const isLocaleAvailable = useCallback(
    (locale: LocaleCode) => config.i18n.availableLanguages.includes(locale),
    [config.i18n.availableLanguages],
  );

  /** Extract the desired locale from the url (and fallback to defaultLocale) */
  const getInitialLanguage = useCallback(() => {
    const { pathname } = location;
    const defaultLocale = config.i18n.defaultLanguage;
    const localeUrl = extractLocaleFromUrl(pathname);
    const localeI18n = i18n.language.substr(0, 2);

    /** locale used for initialization is the one from the url or the one from
     * i18n configuration (if no locale forced in url)
     */
    const initLocale = localeUrl || localeI18n;

    return isLocaleCode(initLocale) && isLocaleAvailable(initLocale)
      ? initLocale
      : defaultLocale;
  }, [config.i18n.defaultLanguage, i18n.language, isLocaleAvailable, location]);

  const replaceHistory = useCallback(
    (newUrl: string) => {
      const { pathname, search, hash } = locRef.current;
      const currentUrl = `${pathname}${search}${hash}`;
      if (currentUrl !== newUrl) {
        history.replace(newUrl);
      }
    },
    [history],
  );

  /**
   * Init the app with the user locale, the option of the Web Socket adapter
   * and the last visit timestamp
   * Also redirect the user to a proper url with locale if the current is not
   * valid
   */
  const initApp = useCallback(() => {
    const isInMobileApp = getWrapperEnv() === 'rn';
    const { hash, pathname, search } = location;

    const locale = getInitialLanguage();

    dispatch(
      coreActions.initApp({
        hasUserAlreadyLoggedIn,
        isInMobileApp,
        lastVisitTimestamp,
        locale,
        wsAdapterOptions,
      }),
    );

    replaceHistory(concatLocation({ pathname, search, hash }, locale));
  }, [
    dispatch,
    getInitialLanguage,
    hasUserAlreadyLoggedIn,
    lastVisitTimestamp,
    location,
    replaceHistory,
    wsAdapterOptions,
  ]);

  return initApp;
};

/** Handle the locale change at the init of the app or after */
export const useLanguageUpdate = ({
  initCallback,
}: {
  /** Callback to be called when the app is ready to be initialized */
  initCallback: () => void;
}) => {
  const { i18n } = useTranslation(undefined, { useSuspense: false });

  const config = useContext(ConfigContext);

  const wasTheInitActionDispatched = useRef(false);
  const dispatch = useDispatch();

  /**
   * Get the current locale from i18next, update the url and send an action.
   * No need to replace the url here, since it's done in
   * /packages/g1-core-web/src/i18n/hooks.ts - switchLocale
   */
  const switchLocale = useCallback(() => {
    const defaultLocale = config.i18n.defaultLanguage;
    const localeI18n = i18n.language.substr(0, 2);

    // locale used for the switch is the one from i18n configuration
    const locale = isLocaleCode(localeI18n) ? localeI18n : defaultLocale;

    dispatch(coreActions.switchLocale.request(locale));
  }, [config.i18n.defaultLanguage, dispatch, i18n.language]);

  /** Listen to i18n events */
  useLayoutEffect(() => {
    /**
     * Event listener for the languageChanged event from i18next
     * Should trigger at the start of the app and when the user changes its locale
     * On safari, there is a bug that make it trigger twice on start, which is why
     * we debounce this callback
     */
    const handleLanguageChange = debounce(() => {
      // init the app if not done yet
      if (!wasTheInitActionDispatched.current) {
        wasTheInitActionDispatched.current = true;
        initCallback();
      } else {
        switchLocale();
      }
    }, LOCALE_SWITCH_DEBOUNCE_TIME_IN_MS);

    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, initCallback, switchLocale]);
};

/** Log data found in process.env when the app starts */
export const useInitialLog = () => {
  useEffect(() => {
    logger.info(
      '[Env] Package name:',
      getEnvVariable('appName')?.trim() || '-',
      '| Version:',
      getEnvVariable('appVersion')?.trim() || '-',
      '| Git head:',
      getEnvVariable('gitHead')?.trim() || '-',
      '| Artifact version:',
      getEnvVariable('artifactVersion')?.trim() || '-',
    );
  }, []);
};

/** Dispatch the app config on boot (and eventually on each future change) */
export const useConfigStateUpdate = () => {
  const config = useConfig();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(coreActions.setConfig(config));
  }, [config, dispatch]);
};

export const DEBUG_MODE_LOCAL_STORAGE_KEY = 'debugModeEnabled';

/**
 * Returns true if the debug mode is enabled. Also set it to true if the
 * query param "debugMode" is set to "true" at the start of the app
 */
export const useDebugMode = () => {
  const isInProduction = getDeployEnv() === 'production';
  const initialDebugModeParamRef = useRef(getSearchParam('debugMode'));
  const isDebugModeSetInParamRef = useRef(
    initialDebugModeParamRef.current === 'true',
  );
  const wasDebugModePreviouslyEnabledRef = useRef(
    !isInProduction &&
      readFrom(localStorage, DEBUG_MODE_LOCAL_STORAGE_KEY) === 'true',
  );

  const isDebugModeEnabledInTheStore = useSelector(isDebugModeEnabledSelector);
  const dispatch = useDispatch();
  const { wsAdapter } = useNetwork();

  const shoudDebugModeBeEnabled =
    isDebugModeEnabledInTheStore ||
    isDebugModeSetInParamRef.current ||
    wasDebugModePreviouslyEnabledRef.current;

  /** Enable the debug mode when the param is found in the url or when it was
   * enabled in the latest session (unless in production) */
  useEffect(() => {
    if (
      isDebugModeSetInParamRef.current ||
      wasDebugModePreviouslyEnabledRef.current
    ) {
      dispatch(coreActions.setDebugMode(true));
    }
  }, [dispatch]);

  /** Keep in sync the app and the wsAdapter debug modes  */
  useEffect(() => {
    wsAdapter.setDebugMode(shoudDebugModeBeEnabled);
  }, [shoudDebugModeBeEnabled, wsAdapter, isInProduction]);

  /** Persist the latest value on non-prod envs */
  useEffect(() => {
    if (!isInProduction) {
      persistIn(
        localStorage,
        DEBUG_MODE_LOCAL_STORAGE_KEY,
        String(isDebugModeEnabledInTheStore),
      );
    }
  }, [isInProduction, isDebugModeEnabledInTheStore]);

  return shoudDebugModeBeEnabled;
};

/** Classname for the web splash screen (id="splash") used in the index.html
 * When added to it, it will fade away
 */
export const SPLASH_SCREEN_ANIMATION_CLASSNAME = 'animation';

export const useSplashScreen = () => {
  const appInitialized = useSelector(appInitializedSelector);
  useLayoutEffect(() => {
    const splashScreenElement = document.getElementById('splash');
    // Remove the splash on mobile app (it already have a native splash)
    if (getWrapperEnv() === 'rn') {
      if (splashScreenElement && splashScreenElement.parentElement) {
        splashScreenElement.parentElement.removeChild(splashScreenElement);
      }
    } else if (appInitialized) {
      // Start the fade-out animation on web
      splashScreenElement?.classList.add('animation');
    }
  }, [appInitialized]);
};

/** Inform the react native wrapper that the locale has been updated */
export const useRNLocaleSync = () => {
  const { currentLocale } = useI18n();
  const previousLocale = usePrevious(currentLocale);
  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      sendIframeMessage(interopMessages.localeChanged, {
        locale: currentLocale,
      });
    }
  }, [currentLocale, previousLocale]);
};

/** Informs the RN wrapper that the native back button should be enabled or not */
export const useToggleGoBackOnRNApp = () => {
  const { visibleDrawer, visibleModal } = useContext(LayoutContext);

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      sendIframeMessage(interopMessages.toggleGoBack, {
        isEnabled: visibleDrawer === null && visibleModal === null,
      });
    }
  }, [visibleDrawer, visibleModal]);
};

/** Sends a message to the bus when the web state changes */
export const useWebStateMessage = () => {
  const isVisible = useIsTabVisible();

  useEffect(() => {
    if (getWrapperEnv() !== 'rn') {
      const state = isVisible ? 'active' : 'background';
      publishGlobalMessage(appStateMessage, state);
    }
  }, [isVisible]);
};
