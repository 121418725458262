import { Action, Location } from 'history';
import { createContext } from 'react';
import { Observable, of } from 'rxjs';

import { HistoryEvent } from './types';

export type HistoryContextValue = {
  exitFullscreenLocation: Location;
  latestAction: Action | null;
  isBottomNavVisibleInFullscreen: boolean;
  isFullscreenLayout: boolean;
  history$: Observable<HistoryEvent>;
  previousLocation: Location | null;
  setFullscreenLayout: (
    isFullScreen: boolean,
    isBottomNavVisible?: boolean,
  ) => void;
};

export const historyContextDefaultValue: HistoryContextValue = {
  exitFullscreenLocation: { hash: '', pathname: '/', search: '', state: null },
  latestAction: null,
  isFullscreenLayout: false,
  isBottomNavVisibleInFullscreen: false,
  history$: of(),
  previousLocation: null,
  setFullscreenLayout: () => null,
};

export const HistoryContext = createContext<HistoryContextValue>(
  historyContextDefaultValue,
);
