import { useEffect } from 'react';

import {
  addLogInterceptor,
  getWrapperEnv,
  LogInterceptor,
  MethodFactory,
  removeLogInterceptor,
} from '@gaming1/g1-logger';

export const useWebviewLogs = () => {
  const shouldForwardRNMessages = getWrapperEnv() === 'rn';

  const serializeMessage = (payload: unknown) => {
    try {
      return JSON.stringify(payload);
    } catch (err) {
      return '{}';
    }
  };

  useEffect(() => {
    let interceptor: LogInterceptor | null = null;

    const webviewMethodFactory: MethodFactory =
      (method) =>
      (...data) => {
        window.ReactNativeWebView?.postMessage(
          serializeMessage({ type: 'logger', payload: { data, method } }),
        );
      };

    if (shouldForwardRNMessages) {
      interceptor = addLogInterceptor(webviewMethodFactory);
    }
    return () => {
      if (interceptor) {
        removeLogInterceptor(interceptor);
      }
    };
  }, [shouldForwardRNMessages]);

  return null;
};
