import { createSelector } from 'reselect';

import { ApplicationState } from '../types';

const i18nCoreStateSelector = (state: ApplicationState) => state.core.i18n;

/**
 * Gets current locale from the redux store. This selector is set when back acked language change.
 * Different from useI18n that is immediately set.
 */
export const currentWebSocketLocaleSelector = createSelector(
  i18nCoreStateSelector,
  (i18nConfig) => i18nConfig.webSocketLocale,
);

/**
 * Gets the current request state for the switch locale action.
 */
export const switchLocaleRequestStateSelector = createSelector(
  i18nCoreStateSelector,
  (i18nConfig) => i18nConfig.requests.switchLocale,
);

/** Returns a list of whitelisted locales from the backend. Should be ignored
 * if the shouldBeRestrictedByShellParams config is not set to true */
export const whitelistedLanguagesSelector = createSelector(
  i18nCoreStateSelector,
  (i18nConfig) => i18nConfig.whitelistedLanguages,
);
