import styled, { css } from 'styled-components';

import {
  colors,
  getReadableColor,
  media,
  shadows,
  spaces,
  zIndex,
} from '@gaming1/g1-style';

import { Box } from '../Box';
import { BOTTOM_NAV_HEIGHT } from '../constants';

import { Style, Variant } from './types';

export const RootDiv = styled.div`
  position: fixed;
  z-index: ${zIndex('notification')};
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  margin-bottom: ${BOTTOM_NAV_HEIGHT}px;
  ${media.xl} {
    margin-bottom: spaces('xxl');
  }
`;

type ToastBoxProps = {
  variant: Variant;
  /* Alternative style */
  containerStyle: Style;
};

export const ToastBox = styled(Box)<ToastBoxProps>`
  box-shadow: ${shadows(4)};
  pointer-events: auto;
  color: ${colors('text')};

  ${({ variant }) => {
    const backgroundColor = variant === 'default' ? 'backgrounds' : variant;
    // As variants don't need an index, this logic might seem unnecessary.
    // Anyway, it's used as is to be future proof and avoid breaking variants if
    // they become an array of colors at some point.
    const backgroundIndex = variant === 'default' ? 4 : undefined;

    return css`
      background-color: ${colors(backgroundColor, { index: backgroundIndex })};
      color: ${getReadableColor(backgroundColor, { index: backgroundIndex })};
    `;
  }}

  ${({ containerStyle }) =>
    containerStyle === 'toast'
      ? css`
          min-height: 68px;
          border-radius: 0.5rem;
          margin-left: ${spaces('xs')};
          margin-right: ${spaces('xs')};
          padding: ${spaces('sm')};
        `
      : css`
          border-radius: 30px;
          margin: auto;
          max-width: 250px;
          padding: ${spaces('xs')};
        `}
`;

export const ToastContainer = styled.div`
  pointer-events: auto;
  margin: auto;
  max-width: 532px;
  position: relative;
  margin-bottom: ${spaces('xs')};
`;
