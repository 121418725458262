import React, { FC, ReactNode, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions, AppSection, currentSectionSelector } from '@gaming1/g1-core';

export type RouteWrapperProps = {
  children?: ReactNode;
  section: AppSection | null;
};

/**
 * Wrapper that should be used inside every <Route> when the section needs to be
 * updated. The application section used by default will be 'default'
 */
export const RouteWrapper: FC<RouteWrapperProps> = ({
  children,
  section = 'default',
}) => {
  const dispatch = useDispatch();
  const currentSection = useSelector(currentSectionSelector);

  useLayoutEffect(() => {
    if (section !== null && currentSection !== section) {
      dispatch(actions.switchSection(section));
    }
  }, [currentSection, dispatch, section]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
