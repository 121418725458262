import { isLeft, isRight, left } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { Logger } from '@gaming1/g1-logger';

import { FluxStandardAction } from './types';

/**
 * Function meant to be used in `filter` array method so that typescript inference is correct.
 * Check if value != null.
 */
export const isNonNullable = <T>(value: T): value is NonNullable<T> =>
  value != null;

/**
 * Function meant to be used in `filter` array method to remove dupplicates.
 * Check if the entry of an array is unique
 */
export const isNotDuplicate = <T>(item: T, index: number, array: T[]) =>
  array.indexOf(item) === index;

/**
 * Asserts the input is a string and guard it type wise
 */
export const isString = (str: unknown): str is string =>
  typeof str === 'string';

/**
 * Function meant to be used in `filter` array method so that typescript inference is correct.
 * Checks if a property exists on an object and if this property is != null.
 */
export const curriedHasNonNullableProperty =
  <T extends Record<string, unknown>, P extends keyof T>(property: P) =>
  (data: T): data is T & Required<{ [key in P]: NonNullable<T[P]> }> =>
    // eslint-disable-next-line react/destructuring-assignment
    property in data && data[property] != null;

const validActionProperties = ['type', 'error', 'meta', 'payload'];

/**
 * Type guard for validating redux actions
 * @param action unknown object that could be a valid redux action
 */
export const isFluxStandardAction = (
  action: unknown,
): action is FluxStandardAction =>
  !!action &&
  typeof action === 'object' &&
  action !== null &&
  typeof (action as FluxStandardAction).type === 'string' &&
  Object.keys(action).filter((prop) => !validActionProperties.includes(prop))
    .length === 0;

// From https://blog.angularindepth.com/rxjs-how-to-use-type-guards-with-observables-11cc4d4f380f

/**
 * Projection function that filters a value with a TS type-guard and throw if
 * doesn't pass.
 * Should be used in a rxjs map operator
 * @param typeGuardFunc function that will be called to check the type
 * @param [message] error message when the input does pass the type-guard
 */
export const guard =
  <T, R extends T>(
    typeGuardFunc: (value: T) => value is R,
    errorMessage?: string,
  ): ((value: T) => R) =>
  (value) => {
    if (typeGuardFunc(value)) {
      return value;
    }
    // We use an array to keep track of the value as well as the error
    // eslint-disable-next-line no-throw-literal
    throw [errorMessage || 'Guard rejection', value];
  };

type ResponseDTO = {
  Message?: string;
  Status: number;
};

/**
 * Function that check if the responseDTO is a success by looking at its Status
 * value. If passing, returns the original value, else throws
 * Should be used in a rxjs map operator
 * @param successStatusValue Value of the success Enum (default: 1)
 * @param [message] error message when the input does pass the check and the DTO
 * doesn't have the Message property
 */
export const successGuard =
  (successStatusValue = 1, errorMessage?: string) =>
  (responseDTO: ResponseDTO) => {
    if (responseDTO.Status === successStatusValue) {
      return responseDTO;
    }
    const errMessage =
      'Message' in responseDTO ? responseDTO.Message : errorMessage;
    // We use an array to keep track of the value as well as the error
    // eslint-disable-next-line no-throw-literal
    throw [errMessage || 'Request failure', responseDTO];
  };

/**
 * Simple check for a responseDTO success by looking at its Status value.
 * @param successStatusValue Value of the success Enum (default: 1)
 */
export const isSuccessDTO =
  (successStatusValue = 1) =>
  (responseDTO: ResponseDTO) =>
    responseDTO.Status === successStatusValue;

export class CodecGuardError extends Error {
  public name: string;

  public message: string;

  public report: ReturnType<typeof PathReporter.report>;

  public type: string;

  public value: unknown;

  public constructor(
    typeName: string,
    value: unknown,
    validationErrors?: Parameters<typeof PathReporter.report>[0],
  ) {
    const message = 'Codec Guard Rejection';
    super(message);
    this.name = 'CodecGuardError';
    this.message = message;
    this.report = validationErrors ? PathReporter.report(validationErrors) : [];
    this.type = typeName;
    this.value = value;
  }
}

/**
 * Check if the returned value of io-ts codec.decode() is a validation error
 * @param codecReturnedValue the returned value of codec.decode()
 */
/*
const isCodecErrors = (
  codecReturnedValue: t.Errors | unknown,
): codecReturnedValue is t.Errors =>
  Array.isArray(codecReturnedValue) &&
  codecReturnedValue.length &&
  codecReturnedValue[0].value &&
  codecReturnedValue[0].context; 
  */

/**
 * Rxjs pipeable operator that accepts a codec and then the values passed to it if they match the codec.
 * Throws an error explaining where the two types aren't matching otherwise
 * @param codec an io-ts codec
 */
export const mapGuard = <C extends t.Mixed>(codec: C) =>
  pipe(
    map((input: unknown): t.OutputOf<C> => {
      const decodeResult = codec.decode(input);

      if (isLeft(decodeResult)) {
        throw new CodecGuardError(codec.name, input, left(decodeResult.left));
      }

      return input;
    }),
  );

/**
 * Generic typeguard factory that accepts a io-ts codec and an optional logger
 * The returned typeguard will return true if it passes the coded decode method.
 * If a logger was provided and the decode did not pass, it will be used to log
 * a warning
 * @param codec an io-ts codec
 * @param logger an optional logger
 */
export const codecGuard =
  <C extends t.Mixed>(codec: C, logger?: Logger) =>
  (input: unknown): input is t.TypeOf<C> => {
    const decodeResult = codec.decode(input);
    if (isRight(decodeResult)) {
      return true;
    }
    if (logger) {
      logger.warn(
        `Value for codec ${codec.name} was filtered`,
        input,
        'did not match',
        PathReporter.report(decodeResult),
      );
    }
    return false;
  };

/*
export const codecGuard = <T extends {}>() => <R extends Record<any>>(
  record: R,
) => (input: unknown): input is Static<typeof record> | T => {
  const check = record.validate(input);
  if ('message' in check) {
    console.log('message', check.message);
  }
  return check.success;
};
*/
/*
const dummyRecord = Record({});

export const codecGuard2 = <
  T = null,
  R extends Record<any> = typeof dummyRecord
>(
  record: R,
) => (input: unknown): input is T extends null ? Static<typeof record> : T =>
  record.guard(input);
*/

// TODO: avoid splitting the generic variables in two functions when this is resolved
// https://github.com/Microsoft/TypeScript/issues/20122
// So that the helper can be written as follow
/*
 export const codecGuard = <T = null, R extends t.TypeC<any> | t.IntersectionC<any>>(
  codec: R,
) => (input: unknown)[...]
*/
