import React, {
  ContextType,
  FC,
  memo,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';

import {
  addLogInterceptor,
  isLoggingAllowed,
  LogInterceptor,
  LogLevel,
  removeLogInterceptor,
} from '@gaming1/g1-logger';
import { logger } from '@gaming1/g1-utils';

import { TrackingContext } from '../../TrackingContext';
import { MetaPixelEventsManager } from '../MetaPixelEventsManager';
import { ScreebEventsManager } from '../ScreebEventsManager';

import { DD_LOGS_SEPARATOR, useDataDog } from './useDataDog';
import { useMetaPixel } from './useMetaPixel';
import { useScreeb } from './useScreeb';

/**
 * Calls several hooks (datadog, meta pixel & screeb).
 * Provide the TrackingContext to track events.
 */
export const TrackingProvider: FC<{ children?: ReactNode }> = memo(
  ({ children }) => {
    const {
      isDataDogEnabled,
      logDataDogErrorOrWarning,
      pushDataDogContext,
      pushDataDogAction,
      pushDataDogError,
    } = useDataDog();

    const { isMetaPixelEnabled, trackMetaPixelEvent } = useMetaPixel();

    const { isScreebEnabled, trackScreebEvent } = useScreeb();

    useEffect(() => {
      let interceptor: LogInterceptor | null = null;
      if (isDataDogEnabled) {
        // Forwards all logger.error calls to DD
        interceptor = addLogInterceptor((methodName, logLevel) => (...args) => {
          const isMethodAllowed =
            methodName === 'warn' || methodName === 'error';

          // Only forward errors they aren't printed to the console to avoid
          // duplicates since DataDog already intercept those
          if (methodName === 'error' && logLevel === LogLevel.SILENT) {
            pushDataDogError(args.length === 1 ? args[0] : args);
          }

          // Only log errors and warnings when logging is allowed
          if (isMethodAllowed) {
            try {
              logDataDogErrorOrWarning(
                args
                  .map((arg) =>
                    typeof arg === 'object' ? JSON.stringify(arg) : arg,
                  )
                  .join(DD_LOGS_SEPARATOR),
                {},
                methodName,
              );
            } catch (err) {
              logger.error(
                '[Tracking] [Datadog] [Web app] Unable to log datadog error/warning with error',
                err,
              );
            }
          }
          return isLoggingAllowed(methodName, logLevel);
        });
      }
      return () => {
        if (interceptor) {
          removeLogInterceptor(interceptor);
        }
      };
    }, [pushDataDogError, isDataDogEnabled, logDataDogErrorOrWarning]);

    const contextValue: ContextType<typeof TrackingContext> = useMemo(
      () => ({
        isDataDogEnabled,
        isMetaPixelEnabled,
        isScreebEnabled,
        logDataDogErrorOrWarning,
        pushDataDogAction,
        pushDataDogContext,
        pushDataDogError,
        trackMetaPixelEvent,
        trackScreebEvent,
      }),
      [
        isDataDogEnabled,
        isMetaPixelEnabled,
        isScreebEnabled,
        logDataDogErrorOrWarning,
        pushDataDogAction,
        pushDataDogContext,
        pushDataDogError,
        trackMetaPixelEvent,
        trackScreebEvent,
      ],
    );

    return (
      <TrackingContext.Provider value={contextValue}>
        {isMetaPixelEnabled && <MetaPixelEventsManager />}
        {isScreebEnabled && <ScreebEventsManager />}
        {children}
      </TrackingContext.Provider>
    );
  },
);
