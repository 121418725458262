import { createContext } from 'react';

type ZendeskWidgetContextValue = {
  hideWidget: () => void;
  isWidgetLoaded: boolean;
  loadWidget: () => void;
  showWidget: () => void;
};

export const zendeskWidgetContextDefaultValue: ZendeskWidgetContextValue = {
  hideWidget: () => null,
  isWidgetLoaded: false,
  loadWidget: () => null,
  showWidget: () => null,
};

export const ZendeskWidgetContext = createContext<ZendeskWidgetContextValue>(
  zendeskWidgetContextDefaultValue,
);
