import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { EActorAuthenticationStatus } from '@gaming1/g1-requests';
import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RemoteData,
} from '@gaming1/g1-utils';

import { CoreActions } from '../types';

import * as actions from './actions';
import { LoginState } from './types';

export const initialState: LoginState = {
  token: null,
  sessionId: null,
  isMFARequiredForLogin: false,
  playerSessionStartDate: null,
  isLogoutAskedManually: false,
  requests: {
    impersonate: generateInitialRequestState(),
    keepSessionAlive: generateInitialRequestState(),
    logout: generateInitialRequestState(),
    login: {
      ...generateInitialRequestState(),
      status: RemoteData.NotAsked,
      errorCode: undefined,
      errorMessage: undefined,
    },
    getAuthToken: generateInitialRequestState(),
    restoreAuthentication: generateInitialRequestState(),
  },
};

export const loginReducer = (
  state: LoginState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.login.request):
        produceLoadingState(draftState.requests.login);
        break;

      case getType(actions.login.success):
        if ('SessionId' in action.payload && !!action.payload.SessionId) {
          draftState.sessionId = action.payload.SessionId;
          draftState.isLogoutAskedManually = false;
        }
        break;

      case getType(actions.login.failure):
        draftState.requests.login = {
          ...action.payload,
          status: RemoteData.Error,
          errorCode: action.payload.status,
        };

        draftState.isMFARequiredForLogin = [
          EActorAuthenticationStatus.PinCodeRequired,
          EActorAuthenticationStatus.PinCodeInvalid,
          EActorAuthenticationStatus.MfaRequired,
          EActorAuthenticationStatus.MfaInvalid,
        ].includes(action.payload.status);
        break;

      case getType(actions.auth.request):
        produceLoadingState(draftState.requests.login);
        break;

      case getType(actions.auth.failure):
        produceFailureState(draftState.requests.login, action.payload);
        break;

      case getType(actions.auth.success):
        draftState.playerSessionStartDate =
          action.payload.PlayerSessionStartDate || null;
        produceSuccessState(draftState.requests.login);
        break;

      case getType(actions.cleanLogin):
        return initialState;

      case getType(actions.logout.request):
        produceLoadingState(draftState.requests.logout);
        draftState.isLogoutAskedManually =
          !!action.payload?.isLogoutAskedManually;
        // Reset the keep alive state after a logout
        draftState.requests.keepSessionAlive =
          initialState.requests.keepSessionAlive;
        break;

      case getType(actions.logout.failure):
        produceFailureState(draftState.requests.logout, action.payload);
        break;

      case getType(actions.logout.success):
        produceSuccessState(draftState.requests.logout);
        // Reset the login state after a logout
        draftState.requests.login = initialState.requests.login;
        draftState.sessionId = null;
        break;

      case getType(actions.impersonateUser.request):
        produceLoadingState(draftState.requests.impersonate);
        break;

      case getType(actions.impersonateUser.failure):
        produceFailureState(draftState.requests.impersonate, action.payload);
        break;

      case getType(actions.impersonateUser.success):
        produceSuccessState(draftState.requests.impersonate);
        if (
          'BusinessSessionId' in action.payload &&
          !!action.payload.BusinessSessionId
        ) {
          draftState.sessionId = action.payload.BusinessSessionId;
        }
        break;

      case getType(actions.getAuthToken.request):
        produceLoadingState(draftState.requests.getAuthToken);
        break;

      case getType(actions.getAuthToken.success):
        produceSuccessState(draftState.requests.getAuthToken);
        draftState.token = action.payload.Token;
        break;

      case getType(actions.getAuthToken.failure):
        produceFailureState(draftState.requests.getAuthToken, action.payload);
        break;

      case getType(actions.restoreAuthentication.request):
        produceLoadingState(draftState.requests.restoreAuthentication);
        break;

      case getType(actions.restoreAuthentication.success):
        produceSuccessState(draftState.requests.restoreAuthentication);
        if (
          'BusinessSessionId' in action.payload &&
          !!action.payload.BusinessSessionId
        ) {
          draftState.sessionId = action.payload.BusinessSessionId;
        }
        break;

      case getType(actions.restoreAuthentication.failure):
        produceFailureState(
          draftState.requests.restoreAuthentication,
          action.payload,
        );
        break;

      case getType(actions.keepSessionAlive.request):
        produceLoadingState(draftState.requests.keepSessionAlive);
        break;

      case getType(actions.keepSessionAlive.success):
        produceSuccessState(draftState.requests.keepSessionAlive);
        break;

      case getType(actions.keepSessionAlive.failure):
        produceFailureState(
          draftState.requests.keepSessionAlive,
          action.payload,
        );
        break;

      default: // Immer will automatically return the state
    }
  });
