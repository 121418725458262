import i18next, { FormatFunction, ThirdPartyModule } from 'i18next';
import I18nextBrowserLanguagedetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import {
  getDeployEnv,
  getEnvVariable,
  getPublicPath,
  getWrapperEnv,
} from '@gaming1/g1-env';
import { formatMoney } from '@gaming1/g1-utils';

import { defaultNamespace, namespaces } from './config';
import { customNavigator } from './detectors';

type I18nOptions = {
  // TODO: this was solely used by the game launcher.
  /**
   * The path from where translations should be fetched. Defaults to
   * getPublicPath('assets/translations/{{lng}}/{{ns}}.json').
   *
   * There is no need to specify this throughout the app. It is here to allow
   * other apps depending on this package to set their own path.
   */
  assetsPath?: string;
  /** A list of 2 char iso code languages */
  availableLanguages: string[];
  /** The partner prefix used to store translations on AWS S3 */
  awsPrefix?: string;
  /** The used currency ISO code (e.g. 'EUR' or 'USD') */
  currency: string;
  /** The default language (2 char iso code) */
  defaultLanguage: string;
  /** The initial language (2 char iso code) */
  initialLanguage?: string;
  /** an optional plugin allowing to use the inContext editor */
  locizePlugin?: ThirdPartyModule;
  /** A list of 2 char iso code languages to preload */
  preload?: string[];
  /**
   * If true, i18n will trigger suspense each time a new file has to be loaded.
   * Defaults to false.
   */
  useSuspense?: boolean;
};

const AWS_TIMEOUT_IN_MS = 5000;

export const appI18nAssetPath = getPublicPath(
  `assets/translations/{{lng}}/{{ns}}.json?v=${getEnvVariable('gitHead')}`,
);

/**
 * Create a i18next instance
 * @param options.assetsPath The path from where translations should be fetched. Defaults to getPublicPath('assets/translations/{{lng}}/{{ns}}.json'). There is no need to specify this throughout the app. It is here to allow others apps depending on this package to set their own path (e.g. the Game Launcher)
 * @param options.availableLanguages list of available languages
 * @param options.defaultLanguage default language code
 * @param options.initialLanguage initial language code
 * @param options.preload list of languages to preload
 * @param options.useSuspense if true, i18n will trigger suspense each time a new file has to be loaded (default `false`)
 */
export const geti18n = ({
  assetsPath = appI18nAssetPath,
  availableLanguages,
  awsPrefix = '',
  currency,
  defaultLanguage,
  initialLanguage,
  locizePlugin,
  preload = [],
  useSuspense = false,
}: I18nOptions) => {
  if (locizePlugin) {
    i18next.use(locizePlugin);
  }

  const formatFunction: FormatFunction = (
    value,
    format,
    locale = defaultLanguage,
  ) => {
    if (format === 'currency') {
      return formatMoney({ currency, fractionDigits: 2, locale })(value, true);
    }

    return value;
  };

  // This abortController will send a signal to the i18n fetch used internally
  // by i18next-http-backend to stop it in a decent time interval for the user
  const abortController = new window.AbortController();
  // requestOptions is given as a function so the timeout is actually called at
  // fetch time to be as close as possible to the expected AWS_TIMEOUT_IN_MS value
  const requestOptions = () => {
    setTimeout(() => {
      abortController.abort();
    }, AWS_TIMEOUT_IN_MS);

    return { signal: abortController.signal };
  };

  const backendOptions = [
    // AWS S3 JSON files
    {
      loadPath: `https://s3-prd-platform-i18n-01.gaming1.com/${awsPrefix}/${getDeployEnv()}/{{lng}}/{{ns}}.json?v=${getEnvVariable(
        'gitHead',
      )}`,
      requestOptions,
    },
    {
      loadPath: assetsPath,
    },
  ];

  if (getDeployEnv() === 'local') {
    // local translation resources will be used during local development
    backendOptions.reverse();
  }

  // add custom detectors for i18next-browser-languageDetector plugin
  const languageDetector = new I18nextBrowserLanguagedetector();
  languageDetector.addDetector(customNavigator);

  const i18nDetectionOrder = [
    'localStorage',
    'sessionStorage',
    'customNavigator',
  ];

  if (getWrapperEnv() === 'rn') {
    i18nDetectionOrder.splice(0, 0, 'querystring');
  }

  i18next
    // passes i18n down to react-i18next
    .use(initReactI18next)
    // use the language detection module
    .use(languageDetector)
    // chains backends as a cascade of fallbacks
    .use(ChainedBackend)
    .init({
      fallbackLng: defaultLanguage,
      lng: initialLanguage,
      backend: {
        backends: [i18nextHttpBackend, i18nextHttpBackend],
        backendOptions,
      },
      detection: {
        order: i18nDetectionOrder,
      },
      interpolation: {
        // react is already safe from xss
        escapeValue: false,
        format: formatFunction,
      },
      preload,
      react: {
        // TODO: create a development backend running the locize-build script on
        // demand and find a way to hook it up to react-i18next so it refreshes
        // translations. Using the `addLocizeSavedHandler` from locize would
        // probably work here but without backend, this is as far as this
        // feature will go.
        // see: https://docs.locize.com/more/incontext-editor/migrating-from-the-old-incontext-editor
        bindI18n: 'languageChanged editorSaved',
        // by default don't render react component until the translations are ready
        transSupportBasicHtmlNodes: true,
        useSuspense,
      },
      supportedLngs: availableLanguages,
      load: 'all',
      ns: namespaces,
      defaultNS: defaultNamespace,
      lowerCaseLng: true,
    });

  return i18next;
};

/**
 * Create a i18next instance used specifically for the tests (to avoid Suspense triggering)
 */
export const getTestI18n = () => {
  i18next.use(initReactI18next).init({
    fallbackLng: 'en',
    lng: 'en', // 'cimode' always returns the key
    ns: namespaces,
    defaultNS: defaultNamespace,
    backend: undefined,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        core: {
          test: 'Test',
        },
      },
    },
  });
  return i18next;
};
