import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { CoreActions } from '../types';

import * as actions from './actions';
import { UserSearchState } from './types';

export const initialState: UserSearchState = {
  userSearch: '',
  searchHistory: null,
};

export const userSearchReducer = (
  state: UserSearchState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case getType(actions.userSearch): {
        draftState.userSearch = action.payload.searchTerm;
        break;
      }

      case getType(actions.setSearchHistory): {
        draftState.searchHistory = action.payload.history;
        break;
      }

      default: // Immer will automatically return the state
    }
  });
