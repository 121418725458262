import styled from 'styled-components';

import { spaces } from '@gaming1/g1-style';
import { Box } from '@gaming1/g1-ui';

export const ErrorBoundaryContainer = styled(Box)`
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 ${spaces('sm')};
  width: 100%;
`;
