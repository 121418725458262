import { Context } from 'react';

import { AssetsContextValues } from './types';

export const generateAssetsContextValue =
  <Keys extends string, Values extends Record<Keys, string | null>>(
    context: Context<Values>,
  ) =>
  (values: Values): AssetsContextValues<Keys, Values, Context<Values>> => ({
    context,
    values,
  });
