import { interopMessages } from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';

import { sendIframeMessage } from './iframeMessageManager/helpers';
import { NavigateOptions } from './types';

/**
 * Navigate to an URL :
 * - In a native environment, will send an interop message of type
 * 'openExternalLinkView' (opens an external webview) or 'openDeviceBrowser' (opens the user's device browser),
 * or it will replace the current webview URL
 * - In a web environment, either replaces the current URL with an assign (keeping the history available)
 * or opens a new tab, blank or not.
 *
 * @param options web and native options
 */
export const navigateToExternalUrl = (
  url: string,
  options: NavigateOptions,
): Window | null => {
  const { native, web } = options;
  if (getWrapperEnv() === 'rn' && native) {
    const { method: nativeMethod } = native;

    switch (nativeMethod) {
      case 'replace':
        window.location.replace(url);
        break;
      case 'openExternal':
        sendIframeMessage(interopMessages.openExternalLinkView, {
          url,
          title: native.title,
        });
        break;
      case 'openDeviceBrowser':
      default:
        sendIframeMessage(interopMessages.openBrowser, {
          url,
        });
        break;
    }
  } else if (web) {
    const { method: webMethod } = web;

    switch (webMethod) {
      case 'replace':
        window.location.assign(url);
        break;
      case 'openNewTab':
      default:
        return window.open(url, '_blank');
    }
  }

  return null;
};
