import { getEnvVariable } from './getEnvVariable';
import { getWrapperEnv } from './getWrapperEnv';

/**
 * Returns a path with a proper basename depending on where the web app is
 * deployed (has no effect on external paths)
 */
export const getPublicPath = (path: string) => {
  const appBaseName = getEnvVariable('baseName');
  const interopBaseName = getEnvVariable('interopAssetsPath');
  const isInInteropEnv = getWrapperEnv() === 'interopwebapp';
  const assetsBaseName = isInInteropEnv ? interopBaseName : appBaseName;
  const isExternalPath = path.indexOf('http') === 0;
  if (isExternalPath) {
    return path;
  }
  const baseName =
    assetsBaseName && assetsBaseName.slice(-1) === '/' ? assetsBaseName : '/';
  const relativePath = path.charAt(0) === '/' ? path.slice(1) : path;
  return `${baseName}${relativePath}`;
};
