import { useContext, useEffect, useState } from 'react';

import { getWrapperEnv } from '@gaming1/g1-env';
import { DrawerType, LayoutContext } from '@gaming1/g1-ui';

import { IframeMessageManager } from '../../iframeMessageManager';

/** Return the geolocation status based on the parent app messages */
export const useGeolocationMessages = (iframeManager: IframeMessageManager) => {
  const { geolocation$ } = iframeManager;

  /** Is the geolocation status (sent by the parent app) ok */
  const [isGeolocationOk, setIsGeolocationOK] = useState(false);

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const geolocationSubscription = geolocation$.subscribe(({ payload }) => {
        if (isGeolocationOk !== payload.isValid) {
          setIsGeolocationOK(payload.isValid);
        }
      });
      return () => geolocationSubscription.unsubscribe();
    }
    return () => undefined;
  }, [geolocation$, isGeolocationOk]);
  return {
    isGeolocationOk,
  };
};

/** Return the navigation drawer based on the parent app messages */
export const useNavigationDrawerStateChangeMessage = (
  iframeManager: IframeMessageManager,
) => {
  const { navigationDrawerStateChange$ } = iframeManager;
  const { showDrawer, hideDrawer, visibleDrawer } = useContext(LayoutContext);

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const drawerSubscription = navigationDrawerStateChange$.subscribe(
        ({ payload }) => {
          if (payload.open) {
            showDrawer(DrawerType.mobileMenu, true);
          } else {
            hideDrawer();
          }
        },
      );
      return () => drawerSubscription.unsubscribe();
    }
    return () => undefined;
  });

  return visibleDrawer === DrawerType.mobileMenu;
};
