import React, { FC, ReactNode } from 'react';

import { AssetsContextValues } from '../../types';

// TODO: move back the whole assetsManagement folder to g1-core-web since
// the typing of the assets path doesn't fit the react-native Image component

type AnyAssetsContextValues = AssetsContextValues<
  string,
  Record<string, string>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>[];

/**
 * Render a hierarchy of context provider with its values. Each element in the
 * array will create another level of nesting.
 * For example calling this function with two contexts will produce the
 * resulting tree:
 * <ContextA.Provider value={valuesA}>
 *   <ContextB.Provider value={valuesB}>
 *     {children}
 *   </ContextB.Provider>
 * </ContextA.Provider>
 * @param contextValues an array containg context-values pairs
 * @param children content that will be rendered when the last context is used
 */
const contextProvidersBuilder = (
  contextValues: AnyAssetsContextValues,
  children: ReactNode,
) => {
  if (!contextValues.length) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  const { Provider } = contextValues[0].context;
  const { values } = contextValues[0];
  return (
    <Provider value={values}>
      {contextProvidersBuilder(contextValues.slice(1), children)}
    </Provider>
  );
};

type GlobalAssetsProviderProps = {
  children: ReactNode;
  contextValues: AnyAssetsContextValues;
};

/** Create a render tree of assets context providers */
export const GlobalAssetsProvider: FC<GlobalAssetsProviderProps> = ({
  children,
  contextValues,
}) => contextProvidersBuilder(contextValues, children);
