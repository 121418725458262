import { useConfig } from '@gaming1/g1-config';

export const imageWidths = [240, 360, 480, 600, 720, 940, 1200, 1440];

// https://developers.cloudflare.com/images/image-resizing/url-format
export type OptimizationImageOptions = {
  /** Number between 1 and 100 representing the overall image quality */
  quality?: number;
  /**
   * Image width, if the precise flag is false, the width
   * will be rounded up to an arbitrary value (useful for cache management)
   */
  width?: { value: number; shouldBePrecise: boolean };
};

/**
 * This hook is used to retrieve an optimized path for an image. If the
 * source path is a link to the media server and optimization is enabled,
 * the path will be returned with additional parameters
 * (allowing our cdn to optimize the image).
 */
export const useGetOptimizedImageSourcePath = () => {
  const {
    assetsManagement: { isImageOptimizationWithCdnEnabled },
    network: { mediaUrl },
  } = useConfig();

  return (src: string, options?: OptimizationImageOptions) => {
    if (isImageOptimizationWithCdnEnabled && src?.startsWith(mediaUrl)) {
      const { quality, width: optWidth } = options || {};
      const srcWithoutMediaUrl = src?.replace(`${mediaUrl}/`, '');

      const width =
        optWidth &&
        ((optWidth.shouldBePrecise && optWidth.value) ||
          Math.min(...imageWidths.filter((w) => w >= optWidth.value)));

      const cdnUrlParameters = Object.entries({ quality, width })
        .filter(([, value]) => value !== undefined && value !== Infinity)
        .map(([name, value]) => `${name}=${value?.toFixed(0)}`)
        .join(',');

      return cdnUrlParameters !== ''
        ? `${mediaUrl}/cdn-cgi/image/${cdnUrlParameters}/${srcWithoutMediaUrl}`
        : src;
    }

    return src;
  };
};
