import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { useExitFullscreenLocation } from '@gaming1/g1-routing';

import { logger } from '../../../logger';
import { useIframeMessages } from '../../../rninterop/hooks/useIframeMessages';
/**
 * Listen for the exitFullScreenLocation interop message and redirect the user
 * to the latest non-fullscreen (RN or not) path when received
 */
export const useExitNativeScreenRedirection = () => {
  const history = useHistory();
  const exitFullscreenLocation = useExitFullscreenLocation();

  const { exitFullScreenLocation$ } = useIframeMessages();

  // console.log(message$);

  useEffect(() => {
    const subscription = exitFullScreenLocation$.subscribe({
      next: () => {
        logger.info(
          `[RN Interop] Native app asked to navigate to the latest non-fullscreen path (${exitFullscreenLocation.pathname})`,
        );
        history.push(exitFullscreenLocation || '/');
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  });
};

/**
 * Handles post messages coming from the native app and navigates following them
 */
export const useNavigationFromNativeApp = () => {
  useExitNativeScreenRedirection();
  const history = useHistory();

  const { navigateMessages$ } = useIframeMessages();
  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const subscription = navigateMessages$.subscribe({
        next: ({ payload }) => {
          logger.info(
            `[RN Interop] Native app asked to navigate to ${payload.path} with the "${payload.method}" method.`,
          );
          history[payload.method](payload.path);
        },
      });
      return () => subscription.unsubscribe();
    }
    return undefined;
  }, [history, navigateMessages$]);
};
