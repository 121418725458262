import { createContext } from 'react';

import {
  AjaxFetch,
  ajaxFetch,
  WebSocketAdapter,
  wsAdapter,
} from '@gaming1/g1-network';

export const NetworkContext = createContext<{
  ajaxFetch: AjaxFetch;
  wsAdapter: WebSocketAdapter;
}>({
  ajaxFetch,
  wsAdapter,
});
