import React, { FC, ReactNode } from 'react';

import { Box, Icon, IconType, Text } from '@gaming1/g1-ui';

type GeoComplyToastProps = {
  children?: ReactNode;
  /* Icon */
  icon: IconType;
};

export const GeoComplyToast: FC<GeoComplyToastProps> = ({ children, icon }) => (
  <Box
    alignItems="center"
    flexDirection="row"
    height="100%"
    justifyContent="center"
  >
    <Box mr="xs">
      <Icon id={`geocomply-${icon}`} type={icon} />
    </Box>
    <Box>
      <Text mt="none" fontSize="lg" lineHeight="20px">
        {children}
      </Text>
    </Box>
  </Box>
);
