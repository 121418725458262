// Taken from https://github.com/pofigizm/redux-dynamic-middlewares/blob/master/src/index.js

import { AnyAction, compose, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { AnyState } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
type EmptyDispatchExt = {};

const createDynamicMiddlewares = <
  State extends AnyState,
  Action extends AnyAction,
>() => {
  type CustomMiddleware = Middleware<EmptyDispatchExt, State, Dispatch<Action>>;
  type AppliedCustomMiddleware = ReturnType<CustomMiddleware>;
  let allDynamicMiddlewares: CustomMiddleware[] = [];
  let allApplyedDynamicMiddlewares: ReturnType<CustomMiddleware>[] = [];
  let store: MiddlewareAPI<Dispatch<Action>, State>;

  const dynamicMiddlewaresEnhancer: CustomMiddleware = (
    _store: typeof store,
  ) => {
    store = _store;
    return (next: Dispatch<Action>) => (action: Action) => {
      const composedAppliedMiddleware: AppliedCustomMiddleware =
        compose<AppliedCustomMiddleware>(...allApplyedDynamicMiddlewares);
      return composedAppliedMiddleware(next)(action);
    };
  };

  const addMiddleware = (...middlewares: CustomMiddleware[]) => {
    allApplyedDynamicMiddlewares = [
      ...allApplyedDynamicMiddlewares,
      ...middlewares.map((middleware) => middleware(store)),
    ];
    allDynamicMiddlewares = [...allDynamicMiddlewares, ...middlewares];
  };

  const removeMiddleware = (middleware: CustomMiddleware) => {
    const index = allDynamicMiddlewares.findIndex((mdw) => mdw === middleware);

    if (index === -1) {
      // eslint-disable-next-line no-console
      // console.error('Middleware does not exist!', middleware);

      return;
    }

    allDynamicMiddlewares = allDynamicMiddlewares.filter(
      (_, mdwIndex) => mdwIndex !== index,
    );
    allApplyedDynamicMiddlewares = allApplyedDynamicMiddlewares.filter(
      (_, mdwIndex) => mdwIndex !== index,
    );
  };

  const resetMiddlewares = () => {
    allApplyedDynamicMiddlewares = [];
    allDynamicMiddlewares = [];
  };

  return {
    dynamicMiddlewaresEnhancer,
    addMiddleware,
    removeMiddleware,
    resetMiddlewares,
  };
};

const dynamicMiddlewaresInstance = createDynamicMiddlewares();

export const {
  addMiddleware,
  removeMiddleware,
  resetMiddlewares,
  dynamicMiddlewaresEnhancer,
} = dynamicMiddlewaresInstance;

export { createDynamicMiddlewares };
