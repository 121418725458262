import { SimpleTheme, ThemeOverrides } from './types';

const breakpoints: SimpleTheme['breakpoints'] = [
  '320px',
  '576px',
  '768px',
  '992px',
  '1200px',
  '1850px',
];

/* eslint-disable sort-keys */
const mediaQueries: SimpleTheme['mediaQueries'] = {
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  xxl: `@media screen and (min-width: ${breakpoints[5]})`,
};
/* eslint-enable sort-keys */

export const defaultSimpleTheme: SimpleTheme = {
  breakpoints,
  colors: {
    activeLink: '#1c7590',
    backgrounds: ['#f9f9fa', '#fafafb', '#f9f9fa', '#f9f9fa', '#f9f9fa'],
    backgroundDark: '#1c1c1c',
    backgroundLight: '#fefefe',
    clubStatus: '#92E1FF',
    danger: '#F33D41',
    deposit: '#ffaf40',
    neutrals: ['#ffffffe0', '#ffffffa3', '#ffffff7a', '#ffffff4d', '#ffffff1f'],
    info: '#2b7aaa',
    link: '#169dc5',
    muted: '#6c757d',
    portal: '#57DB98',
    primary: ['#ff4040', '#cf393b', '#a03237', '#702d33', '#41262f', '#1E212B'],
    secondary: [
      '#afbec5',
      '#8f9ea6',
      '#707e87',
      '#505f68',
      '#313f49',
      '#1a2832',
    ],
    success: '#43a31f',
    tertiary: '#c3c3c3',
    text: '#111111',
    textDark: '#111111',
    textLight: '#ffffff',
    warning: '#ec5d00',
  },
  dark: false,
  fontFamilies: {
    content: {
      bold: 'Source Sans Pro',
      light: 'Source Sans Pro',
      regular: 'Source Sans Pro',
    },
    special: {
      bold: 'Source Sans Pro',
      light: 'Source Sans Pro',
      regular: 'Source Sans Pro',
    },
  },
  fontSizes: [12, 14, 16, 20, 24, 32],
  fontWeights: {
    content: {
      bold: '600',
      light: '300',
      regular: '400',
    },
    special: {
      bold: '600',
      light: '300',
      regular: '400',
    },
  },
  mediaQueries,
  name: 'default',
  opacity: [0, 0.05, 0.2, 0.4, 0.6, 0.8, 1],
  options: {
    buttonsFontSize: '14px',
    buttonsFontWeight: 'bold',
    contentBorderWidth: '1px',
    elevationBorderLevel: null,
    gameCardRoundBorder: true,
    layoutStyle: 'regular',
    heading1FontSize: '29px',
    heading1FontWeight: 'bold',
    heading1Uppercase: false,
    heading2FontSize: '21px',
    heading2FontWeight: 'light',
    heading2Uppercase: false,
    heading3FontSize: '18px',
    heading3FontWeight: 'bold',
    heading3Uppercase: false,
    heading4FontSize: '16px',
    heading4FontWeight: 'bold',
    heading4Uppercase: false,
    roundBorders: true,
    sliderRoundBorder: false,
  },
  sizes: {
    contentPadding: 24,
    gutter: 8,
  },
  shadows: [
    'none',
    '0 2px 1px -1px rgba(69,69,69,0.12), 0 1px 3px 0 rgba(79,79,79,0.20)',
    '0 2px 2px 0 rgba(0,0,0,0.14)',
    '0 3px 4px 0 rgba(0,0,0,0.14)',
    '0 4px 5px 0 rgba(0,0,0,0.14)',
  ],
  space: [0, 2, 4, 8, 16, 24, 32, 40, 48, 56, 64],
  zIndices: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
};

export const createDefaultOverrides = (theme: SimpleTheme): ThemeOverrides => ({
  colors: {
    bettingActivityStatusLive: '#CC0E00',
    bettingBettingSlipMenuBackground: theme.colors.secondary[0],
    bettingBoostus: '#FF4040',
    bettingCashout: '#03A834',
    bettingDailyOffers: '#10A8E6',
    bettingFreebet: '#16B9BC',
    bettingNewPromotion: '#FECB4D',
    bettingOptimalBet: '#E87C24',
    bettingOptiOddsLabel: '#5FA3D4',
    bettingOutcome: theme.colors.neutrals[1],
    bettingOutcomeBorder: theme.colors.neutrals[1],
    bettingOutcomeActive: theme.colors.primary[0],
    bettingOutcomeActiveBorder: theme.colors.primary[0],
    bettingOutcomeActiveBorderHovered: theme.colors.primary[0],
    bettingOutcomeActiveHovered: theme.colors.primary[0],
    bettingOutcomeHovered: theme.colors.neutrals[2],
    bettingOutcomeLocked: theme.colors.neutrals[0],
    bettingOutcomeLockedBorder: theme.colors.neutrals[1],
    bettingPromotions: '#D3637B',
    bettingSlipHeader: '#1e2b35',
    bettingSlipKey: theme.colors.neutrals[0],
    bettingSuperOdds: '#B078F6',
    biggestWinningsBorder: theme.colors.primary[0],
    bottomNavActiveText: theme.colors.primary[0],
    bottomNavBackground: theme.colors.backgrounds[2],
    bottomNavHighlightedBackground: theme.colors.primary[0],
    bottomNavHighlightedText: theme.colors.textDark,
    bottomNavText: theme.colors.textDark,
    buttonDepositText: theme.colors.neutrals[0],
    buttonPrimaryBackground: theme.colors.primary[0],
    buttonPrimaryBackgroundActive: '#D43232',
    buttonPrimaryBorder: theme.colors.primary[0],
    buttonPrimaryBorderActive: '#D43232',
    buttonPrimaryText: theme.colors.textLight,
    buttonPrimaryTextActive: theme.colors.textLight,
    buttonSecondaryBackground: 'transparent',
    buttonSecondaryBackgroundActive: 'transparent',
    buttonSecondaryBorder: theme.colors.primary[0],
    buttonSecondaryBorderActive: theme.colors.primary[0],
    buttonSecondaryText: theme.colors.primary[0],
    buttonSecondaryTextActive: theme.colors.primary[0],
    buttonTertiaryBackground: 'transparent',
    buttonTertiaryBackgroundActive: theme.colors.primary[4],
    buttonTertiaryBorder: 'transparent',
    buttonTertiaryBorderActive: 'transparent',
    buttonTertiaryText: theme.colors.primary[0],
    buttonTertiaryTextActive: theme.colors.primary[0],
    chips: '#fdeff0',
    chipsActive: '#ffffff',
    chipsHover: '#ffffff',
    contentBorder: theme.colors.neutrals[2],
    drawerNavActiveText: theme.colors.primary[0],
    drawerNavBackground: theme.colors.neutrals[0],
    drawerNavText: theme.colors.textDark,
    favouriteIconColor: theme.colors.danger,
    footerAltText: theme.colors.neutrals[2],
    footerBackground: ['#000000', '#333333', '#333333'],
    footerBorder: theme.colors.secondary[4],
    footerIconColor: theme.colors.secondary[2],
    footerLink: theme.colors.link,
    footerActiveLink: theme.colors.activeLink,
    footerText: theme.colors.textLight,
    headerBackground: theme.colors.backgrounds[2],
    heading: theme.colors.textDark,
    inputActive: '#4885f8',
    inputBackground: theme.colors.neutrals[4],
    inputBorder: theme.colors.neutrals[4],
    inputLabel: theme.colors.neutrals[2],
    jackpot: theme.colors.primary[0],
    jackpotBronze: '#794401',
    jackpotCopper: '#C83B1D',
    jackpotDiamond: '#b9f2ff',
    jackpotGold: '#F7AF00',
    jackpotPlatinum: '#e6e6e6',
    jackpotSilver: '#848484',
    jackpotColor: theme.colors.neutrals[0],
    tournamentor: '#6978FF',
    tournamentLeaderBoardHighlight: '#f7af00',
    label: '#666666',
    mainBackground: theme.colors.backgrounds[0],
    profileBorder: theme.colors.tertiary,
    profileIconBackgroundColor: theme.colors.backgrounds[2],
    profileIconBorderColor: theme.colors.neutrals[0],
    pricing: theme.colors.deposit,
    scrollNavBackground: theme.colors.backgrounds[0],
    scrollNavBorder: theme.colors.neutrals[2],
    scrollNavButtonBackground: theme.colors.secondary[0],
    scrollNavButtonForeground: theme.colors.backgrounds[2],
    scrollNavItemBorderActive: theme.colors.primary[0],
    scrollNavItemBorderHovered: theme.colors.primary[1],
    scrollNavItemForeground: theme.colors.muted,
    scrollNavItemForegroundActive: theme.colors.primary[0],
    scrollNavItemForegroundHighlighted: theme.colors.primary[1],
    scrollNavTileBackground: theme.colors.backgrounds[2],
    scrollNavTileBackgroundActive: theme.colors.backgrounds[3],
    scrollNavFlexTileBackground: theme.colors.backgrounds[2],
    scrollNavFlexTileBackgroundActive: theme.colors.backgrounds[3],
    separator: theme.colors.neutrals[4],
    topBarActiveText: theme.colors.primary[0],
    topBarBackground: theme.colors.neutrals[0],
    topBarBorder: theme.colors.neutrals[2],
    topBarText: theme.colors.textDark,
    topNavActiveText: theme.colors.primary[0],
    topNavBackground: theme.colors.neutrals[0],
    topNavText: theme.colors.textDark,
    tournamentCardBorder: theme.colors.neutrals[2],
    tournamentCardSecondaryText: '#c0c0c0',
    tournamentCategoryTag: '#088ee8',
    tournamentCategoryTagBestOdd: theme.colors.warning,
    tournamentLabelBackground: theme.colors.neutrals[3],
  },
  headerLogoHeight: 20,
});
