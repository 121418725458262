import { createContext } from 'react';

import { ConsentPolicyContextValue } from './types';

const emptyUserStatusId = {
  disabled: [],
  enabled: [],
};

export const consentPolicyContextDefaultValue = {
  isConsentPolicyEnabled: false,
  purposesConsent: emptyUserStatusId,
  vendorsConsent: emptyUserStatusId,
};

/**
 * This context allows a third party app (e.g. Didomi) to initialize before
 * making a third party tool available. So when initializing the third
 * party app, this context should be updated with permissions for said tool.
 */
export const ConsentPolicyContext = createContext<ConsentPolicyContextValue>(
  consentPolicyContextDefaultValue,
);
