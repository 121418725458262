import { addDevelopmentOnlyCSPLine } from '../helpers';
import { SecurityConfig } from '../types';
/**
 * Parameters linked to the security.
 *
 * In case you need to add or overwrite this default config (which should be present on every partner),
 * use it that way in the partner's config :
 *
 * `
 * const defaultCsp = getConfig().security.csp;
 * security: {
 *   csp: {
 *     ...defaultCsp,
 *     'default-src': [
 *       ...defaultCsp['default-src'],
 *       'https://additional-url.com',
 *       'https://additional-url2.com'
 *     ]
 *   }
 * }
 * `
 */
export const defaultSecurityConfig: SecurityConfig = {
  csp: {
    'connect-src': [
      ...addDevelopmentOnlyCSPLine([
        'ws://localhost:3000',
        'http://localhost:5251',
        'ws://127.0.0.1:10002/',
      ]),
      'self',
      'https://time.akamai.com',
      'https://dash.akamaized.net',
      'https://lc-live-http-ipv4.akamaized.net',
      'https://*.appsflyer.com',
      'https://*.browser-intake-datadoghq.eu',
      'https://*.betradar.com',
      'https://*.connextra.com',
      'https://*.datadoghq.eu',
      'https://*.fullstory.com',
      'https://*.gaming1.com',
      'wss://*.gaming1.com',
      'wss://*.gaming1.com:*',
      'https://*.googleapis.com',
      'https://*.google-analytics.com',
      'https://*.analytics.google.com',
      'https://*.hotjar.com',
      'wss://*.hotjar.com',
      'wss://mpsnare.iesnare.com',
      'https://*.lcpportal.com/HostedFunding',
      'https://*.llnwd.net',
      'https://*.mathtag.com',
      'https://paynearme.com',
      'https://*.paynearme.com',
      'https://paynearme-sandbox.com',
      'https://*.paynearme-sandbox.com',
      'https://js-api.otherlevels.com',
      'https://js-rich.otherlevels.com',
      'https://wss.plc-gc.com:*',
      'wss://wss.plc-gc.com:*',
      'https://*.cdn.prismic.io',
      'https://*.prismic.io',
      'https://api.privacy-center.org',
      'https://*.screeb.app',
      'wss://*.screeb.app',
      'https://*.slpuat.com',
      'https://*.smooch.io',
      'wss://*.smooch.io',
      'https://*.sportradar.com',
      'https://*.zdassets.com',
      'https://*.zendesk.com',
      'https://*.zendesk.com/*',
      'https://zendesk-eu.my.sentry.io',
      'https://*.zopim.com',
      'wss://*.zopim.com',
      'wss://*.zendesk.com',
    ],
    'default-src': ['none'],
    'font-src': [
      'self',
      'data:',
      'http://fonts.googleapis.com',
      'https://fonts.googleapis.com',
      'https://fonts.gstatic.com',
      'https://script.hotjar.com',
      'https://*.smooch.io',
      'https://*.zendesk.com',
    ],
    'form-action': [
      'https://*.facebook.com',
      'https://*.lcpportal.com',
      'https://*.ogone.com',
      'https://paywithmybank.com',
      'https://*.paywithmybank.com',
      'https://*.slpuat.com',
      'https://trustly.one',
      'https://*.trustly.one',
    ],
    'frame-src': [
      ...addDevelopmentOnlyCSPLine(['http://localhost:3010']),
      'self',
      'https://*.facebook.com',
      'https://*.gaming1.com',
      'https://www.google.com',
      'https://pay.google.com',
      'https://www.google.com/maps/embed',
      'http://*.hipay-tpp.com',
      'https://*.hotjar.com',
      'https://*.online-metrix.net',
      'https://paynearme.com',
      'https://*.paynearme.com',
      'https://paynearme-sandbox.com',
      'https://*.paynearme-sandbox.com',
      'https://paywithmybank.com',
      'https://*.paywithmybank.com',
      'https://player.twitch.tv',
      'https://*.prismic.io',
      'https://trustly.one',
      'https://*.trustly.one',
      'https://www.youtube.com',
    ],
    'img-src': ['*', 'blob:', 'data:'],
    'manifest-src': ['self'],
    'media-src': ['*', 'blob:'],
    'object-src': ['none'],
    'script-src': [
      ...addDevelopmentOnlyCSPLine(['unsafe-inline', 'unsafe-eval']),
      'self',
      'https://*.appsflyer.com',
      'https://cdnjs.cloudflare.com',
      'https://static.cloudflareinsights.com',
      'https://*.connextra.com',
      'https://connect.facebook.net',
      'https://*.fullstory.com',
      'https://www.google.com',
      'https://*.googleapis.com',
      'https://www.google-analytics.com',
      'https://www.googletagmanager.com',
      'https://*.gstatic.com',
      'https://*.hertzen.com',
      'https://*.hotjar.com',
      'https://*.iesnare.com',
      'https://*.iovation.com',
      'https://cdn.jsdelivr.net',
      'https://www.locize.app',
      'https://*.mathtag.com',
      'https://cdn.otherlevels.com',
      'https://c.paypal.com',
      'https://paynearme.com',
      'https://*.paynearme.com',
      'https://paynearme-sandbox.com',
      'https://*.paynearme-sandbox.com',
      'https://paywithmybank.com',
      'https://*.paywithmybank.com',
      'https://prismic.io',
      'http://static.cdn.prismic.io',
      'https://sdk.privacy-center.org',
      'https://*.screeb.app',
      'https://*.smooch.io',
      'https://*.sportradar.com',
      'https://trustly.one',
      'https://*.trustly.one',
      'https://*.twitch.tv',
      'https://www.youtube.com',
      'https://*.zdassets.com',
      'https://*.zendesk.com',
      'https://*.zendesk.com/*',
      'https://zendesk-eu.my.sentry.io',
      'https://*.zopim.com',
      'wss://*.zendesk.com',
      'wss://*.zopim.com',
    ],
    'style-src': [
      'self',
      'unsafe-inline',
      'http://fonts.googleapis.com',
      'https://fonts.googleapis.com',
      'https://fonts.gstatic.com',
      'https://paynearme.com',
      'https://*.paynearme.com',
      'https://paynearme-sandbox.com',
      'https://*.paynearme-sandbox.com',
      'https://*.smooch.io',
      'https://*.sportradar.com',
      'https://*.zendesk.com',
    ],
    'worker-src': ['blob:'],
  },
};
