import { createContext } from 'react';

import { LocaleCode } from '@gaming1/g1-config';

/** Object containing i18n replacement values that will be used inside Locize
 * translation strings. Those values will be different between each partner and
 * located inside a i18n-config.json file
 * */
export type I18nValues = {
  currency: string;
  siteName: string;
  siteUrl: string;
  state?: string;
  supportEmail: string;
};

export type I18nContextValue = {
  availableLanguages: LocaleCode[];
  availableLanguagesFullCode: string[];
  currentLocale: LocaleCode;
  currentLocaleFullCode: string;
  values: I18nValues;
};

const i18nContextDefaultValues: Record<keyof I18nValues, string> = {
  currency: '[CURRENCY]',
  siteName: '[SITENAME]',
  siteUrl: '[SITEURL]',
  supportEmail: '[SUPPORTEMAIL]',
  state: '[STATE]',
};

/** Context for i18n */
export const I18nContext = createContext<I18nContextValue>({
  availableLanguages: ['en'],
  availableLanguagesFullCode: ['en-US'],
  currentLocale: 'en',
  currentLocaleFullCode: 'en-US',
  values: i18nContextDefaultValues,
});
