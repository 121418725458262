import React, {
  ContextType,
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import {
  MediaBreakPoint,
  MediaBreakPointNames,
  mediaBreakPoints,
} from '@gaming1/g1-style';
import { usePrevious } from '@gaming1/g1-utils';

import { SCROLL_LOCK_CLASSNAME } from '../constants';

import {
  DrawerType,
  LayoutContext,
  layoutContextDefaultValue,
} from './LayoutContext';

type ModalQueue = {
  modalId: string;
  isClosable: boolean;
}[];

type LayoutSize = {
  listener: (event: MediaQueryListEvent) => void;
  mediaQueryList: MediaQueryList;
  name: MediaBreakPointNames;
  query: string;
};

/**
 * Generate a media query string from a width in px (for the 'min-width')
 * and the next (wider) MediaBreakPoint (for the 'max-width')
 */
const generateQuery = (width: string, nextBP?: MediaBreakPoint) => {
  let query = `(min-width: ${width})`;

  if (nextBP) {
    query += ` and (max-width: ${nextBP.value - 1}px)`;
  }

  return query;
};

export const LayoutProvider: FC<{
  children?: ReactNode;
}> = memo(({ children }) => {
  const [modalQueue, setModalQueue] = useState<ModalQueue>([]);
  const [isDrawerClosableByUser, setIsDrawerClosableByUser] =
    useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<DrawerType | null>(null);
  const [media, setMedia] = useState<MediaBreakPointNames>('xs');

  const { breakpoints: themeBreakpoints } = useTheme();

  const visibleModal = modalQueue.length > 0 ? modalQueue[0].modalId : null;

  const showDrawer = useCallback((type: DrawerType, isClosable = true) => {
    setVisibleDrawer(type);
    setIsDrawerClosableByUser(isClosable);
  }, []);

  const hideDrawer = useCallback(() => {
    setVisibleDrawer(null);
  }, []);

  /**
   * It will remove the modal with the given id from the queue
   */
  const hideModal = useCallback(
    (id: string) => {
      const filteredModalQueue = modalQueue.filter(
        (modal) => modal.modalId !== id,
      );
      setModalQueue(filteredModalQueue);
    },
    [modalQueue],
  );

  /**
   * It will add the modal into the "modalQueue" and WON'T replace the current one directly.
   */
  const showModal = useCallback(
    (id: string, isClosable = true) => {
      const duplicateModalFound = modalQueue.find(
        (modal) => modal.modalId === id,
      );
      if (!duplicateModalFound) {
        const modalToAdd = { modalId: id, isClosable };
        setModalQueue((prev) => [...prev, modalToAdd]);
      }
    },
    [modalQueue],
  );

  const backdropClickHandler = useCallback(() => {
    const isModalOpen = modalQueue.length > 0 && modalQueue[0].isClosable;
    if (isDrawerClosableByUser && !isModalOpen) {
      setVisibleDrawer(null);
    } else if (isModalOpen) {
      hideModal(modalQueue[0].modalId);
    }
  }, [modalQueue, hideModal, isDrawerClosableByUser]);

  const handleKeydown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (modalQueue.length > 0 && modalQueue[0].isClosable) {
          hideModal(modalQueue[0].modalId);
        }
        if (visibleDrawer && isDrawerClosableByUser) {
          hideDrawer();
        }
      }
    },
    [hideDrawer, hideModal, isDrawerClosableByUser, modalQueue, visibleDrawer],
  );

  const isBackdropVisible = useMemo(
    () => !!visibleDrawer || modalQueue.length > 0,
    [visibleDrawer, modalQueue.length],
  );

  const wasBackdropVisible = usePrevious(isBackdropVisible);

  /**
   * LayoutSizes: array of objects that contains
   * - The CSS query of the layout
   * - The name of the layout
   * - The event listener of the layout
   * - The window matchMedia invocation corresponding to the layout
   */
  const layoutSizes = useMemo<LayoutSize[]>(
    () =>
      mediaBreakPoints
        .map(
          ({ name }): MediaBreakPoint => ({
            name,
            value: Number(themeBreakpoints[name].replace('px', '')),
            width: themeBreakpoints[name],
          }),
        )
        .map(({ name, width }, index, breakpoints) => {
          const breakpoint = breakpoints[index + 1];
          const query = generateQuery(width, breakpoint);

          return {
            /**
             * When we receive an event of type 'change' and it matches our media query,
             * fire the component callback 'switchMedia' with its name
             */
            listener: (event: MediaQueryListEvent) => {
              if (event.type === 'change' && event.matches) {
                setMedia(name);
              }
            },
            mediaQueryList: window.matchMedia(query),
            name,
            query,
          };
        }),
    [themeBreakpoints],
  );

  /**
   * For each layout size, set up the Listener to the MediaQueryList
   * If one of the layouts is matched, fire the callback immediately
   */
  useEffect(() => {
    layoutSizes.forEach((layoutSize) => {
      layoutSize.mediaQueryList.addListener(layoutSize.listener);
      if (layoutSize.mediaQueryList.matches) {
        setMedia(layoutSize.name);
      }
    });

    return () => {
      layoutSizes.forEach((layoutSize) => {
        layoutSize.mediaQueryList.removeListener(layoutSize.listener);
      });
    };
  }, [layoutSizes]);

  useLayoutEffect(() => {
    let didLockBodyScroll = false;
    if (isBackdropVisible && !wasBackdropVisible) {
      didLockBodyScroll = true;
      /**
       * Prevents the body to be scrolled when the modal or the drawer is
       * visible but keep the scroll position
       */
      const { scrollY } = window;
      document.body.classList.add(SCROLL_LOCK_CLASSNAME);
      document.body.style.top = `-${scrollY}px`;

      // Allow to hide on Escape
      window.addEventListener('keydown', handleKeydown);
    }

    return () => {
      /**
       * Remove the position "fixed" on the body and restore the scroll position
       * See https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
       */
      if (didLockBodyScroll) {
        document.body.classList.remove(SCROLL_LOCK_CLASSNAME);
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

        window.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [handleKeydown, isBackdropVisible, wasBackdropVisible]);

  const layoutContextValue: ContextType<typeof LayoutContext> = useMemo(
    () => ({
      backdropClickHandler,
      backdropVisibility: isBackdropVisible,
      hideDrawer,
      hideModal,
      media,
      showDrawer,
      showModal,
      visibleDrawer,
      visibleModal,
    }),
    [
      backdropClickHandler,
      isBackdropVisible,
      hideDrawer,
      hideModal,
      media,
      showDrawer,
      showModal,
      visibleDrawer,
      visibleModal,
    ],
  );

  return (
    <LayoutContext.Provider value={layoutContextValue}>
      {children}
    </LayoutContext.Provider>
  );
});

export { DrawerType, LayoutContext, layoutContextDefaultValue };
