import { conditionalWindow } from '../types/conditionalWindow';
import { WorkingEnv } from '../types/workingEnv';

/**
 * Returns the current working environment (where the code is executed). Values
 * can be
 * - 'web'
 * - 'react-native'
 * - 'jest'
 * - 'node-js'
 */
export const getWorkingEnv = (): WorkingEnv => {
  if (
    typeof jest !== 'undefined' &&
    !conditionalWindow?.shouldBypassJestDetection
  ) {
    return 'jest';
  }
  if (
    typeof conditionalWindow !== 'undefined' &&
    conditionalWindow.navigator &&
    conditionalWindow.navigator.product === 'ReactNative'
  ) {
    return 'react-native';
  }
  if (typeof conditionalWindow !== 'undefined' && conditionalWindow.document) {
    return 'web';
  }
  return 'node-js';
};
