/** Media queries collection which targets different devices capabilities */
export const device = {
  /** Targets devices that can hover an element AND don't have a touch screen as
   * a main input mechanism. This additional check was done because some Samsung
   * phones reports that they can handle hover wich creates weird behaviours on
   * some gestures */
  canHover: '@media (hover: hover) and (pointer: fine)',
  /** Targets devices that cannot hover an element */
  cannotHover: '@media (hover: none)',
  /** Targets devices with a touch screen as a main input mechanism */
  hasTouchscreen: '@media (pointer: coarse)',
  /** Targets devices with a mouse/stylus as a main input mechanism */
  hasMouse: '@media (pointer: fine)',
};
