import { Context } from '@datadog/browser-core';
import { StatusType } from '@datadog/browser-logs';
import { createContext } from 'react';

import { ScreebEventProperties } from './types';

export type TrackingContextValue = {
  isDataDogEnabled: boolean;
  isMetaPixelEnabled: boolean;
  isScreebEnabled: boolean;
  logDataDogErrorOrWarning: (
    message: string,
    messageContext?: object,
    status?: StatusType,
    error?: Error,
  ) => void;
  pushDataDogAction: (name: string, context?: Context) => void;
  pushDataDogContext: (key: string, value?: Context) => void;
  pushDataDogError: (error: unknown, context?: Context) => void;
  trackMetaPixelEvent: (event: string) => void;
  trackScreebEvent: (name: string, properties?: ScreebEventProperties) => void;
};

export const TrackingContext = createContext<TrackingContextValue>({
  isDataDogEnabled: false,
  isMetaPixelEnabled: false,
  isScreebEnabled: false,
  logDataDogErrorOrWarning: () => undefined,
  pushDataDogAction: () => undefined,
  pushDataDogContext: () => undefined,
  pushDataDogError: () => undefined,
  trackMetaPixelEvent: () => undefined,
  trackScreebEvent: () => undefined,
});
