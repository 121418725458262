import { GLOBAL_CHANNEL } from './constants';
import { createEnvelope } from './helpers';
import { eventInput$ } from './observables';
import {
  Envelope,
  MessageFilter,
  MessagePayload,
  MessageTopic,
  Serializable,
} from './types';

/**
 * Returns a function for dispatching messages to the given message bus channel
 * @deprecated
 * @param channel The channel in which the message would be sent
 */
export const publish =
  (channel: string) =>
  /**
   * Dispatch an async message event to the message bus
   * @param topic The topic of the message
   * @param payload the payload of the message. Must be serializable. Can be
   * null in case of an empty payload
   * @param options
   * @param options.additionalMetadata An optional dictionary of metadata to be
   * appended to the message envelope
   */
  <Payload extends Serializable>(
    topic: MessageTopic,
    payload: Payload,
    options?: {
      additionalMetadata?: Record<string, string>;
    },
  ) => {
    const envelope: Envelope<Payload> = createEnvelope(
      channel,
      topic,
      payload,
      options?.additionalMetadata,
    );
    eventInput$.next(envelope);
  };

/**
 * Returns a function to publish a message to the message bus
 * @param channel On which channel should the message be published
 */
export const publishMessage =
  (channel: string) =>
  /**
   * publish an async message event to the message bus
   * @param messageDefinition The message contract created using "defineMessage"
   * @param payload the payload of the message. Must be serializable. Can be
   * null in case of an empty payload
   * @param options
   * @param options.additionalMetadata An optional dictionary of metadata to be
   * appended to the message envelope
   */
  <Topic extends MessageTopic, Payload extends Serializable | undefined>(
    messageDefinition: MessageFilter<Topic, Payload>,
    payload: MessagePayload<MessageFilter<Topic, Payload>>,
    options?: {
      additionalMetadata?: Record<string, string>;
    },
  ) => {
    const envelope: Envelope<MessagePayload<MessageFilter<Topic, Payload>>> =
      createEnvelope(
        channel,
        messageDefinition.topic,
        payload,
        options?.additionalMetadata,
      );
    eventInput$.next(envelope);
  };

/** Publish a message to the message bus on the global channel */
export const publishGlobalMessage = publishMessage(GLOBAL_CHANNEL);
