import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  VFC,
} from 'react';
import { SpaceProps } from 'styled-system';

import { useGetIsMounted } from '@gaming1/g1-utils';

import { Box } from '../Box';
import { LOADER_DELAY_IN_MS } from '../constants';

import { loaders, LoaderType } from './loaders';

export type LoaderProps = SpaceProps & {
  /**
   * Whether the loader only shows after {smartDelay} ms.
   * Default: true
   */
  smart?: boolean;
  /**
   * Delay in ms before the loader is displayed. Only usedful when smart is true.
   * Default: LOADER_DELAY_IN_MS (500ms)
   */
  smartDelay?: number;
  /**
   * Type of the loader.
   * Default: 'default'
   */
  type?: LoaderType;
};

export const LoaderContext = createContext<{ [k in LoaderType]?: VFC }>({});

export const Loader: VFC<LoaderProps> = ({
  smart = true,
  smartDelay = LOADER_DELAY_IN_MS,
  type = 'default',
  ...props
}) => {
  const [loaderVisibility, setLoaderVisibility] = useState(!smart);
  const getIsMounted = useGetIsMounted();

  const contextLoaders = useContext(LoaderContext);
  const mergedLoaders = { ...loaders, ...contextLoaders };
  const LoaderComponent = mergedLoaders[type];

  useEffect(() => {
    const handle = setTimeout(() => {
      if (getIsMounted()) {
        setLoaderVisibility(true);
      }
    }, smartDelay);
    return () => clearTimeout(handle);
  }, [getIsMounted, smartDelay]);

  return loaderVisibility ? (
    <Box alignItems="center" data-testid="loader" {...props}>
      <LoaderComponent />
    </Box>
  ) : null;
};
