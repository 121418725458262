import { LocaleCode, Rooms } from '@gaming1/g1-config';

export type WidgetCallback = () => void;

export type CanShowWidget = {
  showWidget: () => void;
};

/* Zendesk API */

type WidgetPrefillValue = { value?: string; readOnly?: boolean; id?: number };

type WidgetUserData = {
  name?: string | WidgetPrefillValue;
  email?: string | WidgetPrefillValue;
  phone?: string | WidgetPrefillValue;
  pseudo?: string | WidgetPrefillValue;
};

type WidgetAction =
  | 'clear'
  | 'helpCenter:setSuggestions'
  | 'hide'
  | 'identify'
  | 'logout'
  | 'open'
  | 'close'
  | 'prefill'
  | 'reset'
  | 'setLocale'
  | 'show'
  | 'chat:reauthenticate'
  | 'chat:status'
  | 'updateSettings'
  | 'chat:addTags'
  | 'chat:end'
  | 'chat:isChatting'
  | 'display';

type WidgetSuggestionData = {
  labels: ('label1' | 'label2' | 'label3' | 'label4' | 'label5')[];
};

type WidgetTarget = 'webWidget' | 'webWidget:on' | 'webWidget:get';

type WidgetDisplayType =
  | 'launcher'
  | 'helpCenter'
  | 'chat'
  | 'contactForm'
  | 'talk'
  | 'contactOptions'
  | 'answerBot'
  | 'hidden';

type WidgetEventCallback = (userEvent?: Record<string, unknown>) => void;

type WidgetFunction = {
  (
    target: WidgetTarget,
    action: WidgetAction,
    data?: LocaleCode | WidgetUserData | WidgetSuggestionData | WidgetCallback,
  ): void;
  (target: 'webWidget', action: 'setLocale', data: LocaleCode): void;
  (
    target: 'webWidget',
    action: 'identify' | 'prefill',
    data: WidgetUserData,
  ): void;
  (target: 'webWidget:on', action: 'open', data: WidgetCallback): void;
  (
    target: 'webWidget:on',
    action: 'userEvent',
    data: WidgetEventCallback,
  ): void;
  (
    target: 'webWidget:on',
    action: 'chat:status',
    data: (status: 'offline' | 'online') => void,
  ): void;
  (
    target: 'webWidget',
    action: 'updateSettings',
    data: WebWidgetSettings,
  ): void;
  (target: 'webWidget', action: 'chat:addTags', data: string[]): void;
  (target: 'webWidget:get', action: 'chat:isChatting'): boolean;
  (target: 'webWidget:get', action: 'display'): WidgetDisplayType;
};

type ZendeskSettingsAuth = {
  chat: { jwtFn: (callback: (token: string) => void) => void };
};

type WebWidgetSettings = {
  webWidget: {
    launcher?: {
      label: unknown;
    };
    offset?: {
      vertical: string;
      mobile: {
        vertical: string;
      };
    };
    helpCenter?: { title?: unknown };
    chat?: { title?: unknown; suppress?: boolean; hideWhenOffline?: boolean };
    contactForm?: {
      title?: unknown;
      tags?: string | string[];
      suppress?: boolean;
      fields?: Array<{
        id: number;
        prefill: unknown;
      }>;
    };
    authenticate?: ZendeskSettingsAuth;
  };
};

type ZendeskSettings = {
  zESettings?: {
    webWidget: {
      authenticate: ZendeskSettingsAuth;
    };
  };
};

export const brandTags: Record<Rooms, string> = {
  [Rooms.BETLYAR]: 'brand_betlyar',
  [Rooms.BETLYOH]: 'brand_betlyoh',
  [Rooms.BETLYTN]: 'brand_betlytn',
  [Rooms.BETLYWV]: 'brand_betlywv',
  [Rooms.BLITZ]: 'brand_blitz',
  [Rooms.CAROUSEL]: 'brand_carousel',
  [Rooms.CASINO333]: 'brand_casino333',
  [Rooms.CASINOBELGIUM]: 'brand_casinobelgium',
  [Rooms.CIRCUSNL]: 'brand_circusnl',
  [Rooms.CIRCUSPE]: 'brand_circuspe',
  [Rooms.CIRCUS]: 'brand_circusbe',
  [Rooms.DEFAULT]: 'brand_default',
  [Rooms.ESTORILCASINO]: 'brand_estorilcasino',
  [Rooms.FAMILYGAMEONLINE]: 'brand_familygameonline',
  [Rooms.GOLDENVEGAS]: 'brand_goldenvegas',
  [Rooms.JOA]: 'brand_joa',
  [Rooms.LUCKYGAMES]: 'brand_luckygames',
  [Rooms.MAGICWINS]: 'brand_magicwins',
  [Rooms.MYCIRCUS]: 'brand_mycircus',
  [Rooms.PANACHE]: 'brand_panache',
  [Rooms.ZAMBA]: 'brand_zamba',
  [Rooms.MYCIRCUS]: '',
};

// For more information on the widget API see documentation :
// https://developer.zendesk.com/embeddables/docs/widget/core#commands
export type WindowWithZendeskClassicSDK = Window &
  ZendeskSettings & {
    zE?: WidgetFunction & { widget: 'classic' };
  };
export const windowWithZendeskSDK = window as WindowWithZendeskClassicSDK;
