// This was a duplicate from g1-utils but the usage of Url make RN app crash

import { conditionalWindow } from '../types/conditionalWindow';

/**
 * Get a search parameter from the given url or the current one.
 * Can safely be used in a non browser environment (will return null).
 * Should only be used in low-level package (g1-env, g1-logger) in order
 * to avoid dependencies (otherwise use the one from g1-utils)
 */
export const getSearchParam = (queryName: string, href?: string) => {
  if (
    typeof conditionalWindow !== 'object' ||
    conditionalWindow.location === undefined
  ) {
    return null;
  }
  const url = href || conditionalWindow.location.href;
  if (url.indexOf('?') === -1) {
    return null;
  }
  return url
    .slice(url.indexOf('?') + 1)
    .split('&')
    .reduce<string | null>((acc, hash) => {
      const [key, value] = hash.split('=');
      return key === queryName ? value : acc;
    }, null);
};
