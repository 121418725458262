import React, { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { actions, databaseRoutesRequestStateSelector } from '@gaming1/g1-core';
import {
  getPageNameFromLocation,
  getRoutePathWithoutParameters,
  MatchingPageNamesManifest,
  MultiLanguageRouter,
  RouteList,
  useRedirectionByPageName,
} from '@gaming1/g1-routing';
import { useRequestState } from '@gaming1/g1-store';
import { Box } from '@gaming1/g1-ui';

import { ResettingWrapper } from '../../../layout/components/ResettingWrapper';
import { useIsBlackListedRoute } from '../../../rninterop/hooks/useIsBlackListedRoute';
import {
  useDatabaseRouteByPageName,
  useNavigateWithAuthToken,
} from '../../hooks';

type RouterWrapperProps = {
  children?: ReactNode;
  /** List of page names that should match with each other */
  matchingPageNamesManifest?: MatchingPageNamesManifest;
  /** All the routes available in the app */
  routes: RouteList<string>;
};

/** Wrapper that should be above the main <Switch> of the application. It handles
 * - ensuring the language is set in the path
 * - redirections when the 'routename' query string is set in the url
 * - disabling the rendering of blacklisted routes
 */
export const RouterWrapper: FC<RouterWrapperProps> = ({
  children,
  routes,
  matchingPageNamesManifest,
}) => {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const isBlackListed = useIsBlackListedRoute();
  const { isNotAsked } = useRequestState(databaseRoutesRequestStateSelector);

  const redirectionByPageName = useRedirectionByPageName(
    routes,
    matchingPageNamesManifest,
  );

  const databaseRouteByPageName = useDatabaseRouteByPageName(
    getPageNameFromLocation(location),
  );

  useEffect(() => {
    if (matchingPageNamesManifest && isNotAsked) {
      dispatch(actions.getDatabaseRoutes.request());
    }
  }, [dispatch, isNotAsked, matchingPageNamesManifest]);

  const navigateWithAuthToken = useNavigateWithAuthToken();

  useEffect(() => {
    if (!redirectionByPageName && databaseRouteByPageName?.path) {
      navigateWithAuthToken(
        `${databaseRouteByPageName.origin}${getRoutePathWithoutParameters(
          databaseRouteByPageName?.path,
        )}`,
      );
    }
  }, [
    databaseRouteByPageName?.origin,
    databaseRouteByPageName?.path,
    navigateWithAuthToken,
    redirectionByPageName,
  ]);

  /**
   * React native - when path a is blacklisted, the view shouldn't be rendered.
   * This ensures that any native screen is considered coming from a web
   * fullscreen.
   */
  if (isBlackListed(location.pathname)) {
    return <Box minHeight="100vh" backgroundColor={colors.backgrounds[0]} />;
  }

  if (redirectionByPageName) {
    return <Redirect to={redirectionByPageName} />;
  }

  return (
    <MultiLanguageRouter>
      <ResettingWrapper>{children}</ResettingWrapper>
    </MultiLanguageRouter>
  );
};
