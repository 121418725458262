import React from 'react';
import { Route } from 'react-router-dom';

import { lazifyComponent } from '@gaming1/g1-utils';

import { CoreRoutes } from './routes';
import { RouteWrapper } from './routing/components/RouteWrapper';

const LazyAppDownloadPage = lazifyComponent(
  'AppDownloadPage',
  () => import('./geolocation/pages/AppDownloadPage'),
);

export const getCoreRouter = (coreRoutes: CoreRoutes) => [
  <Route exact key="home" path={`/:locale${coreRoutes.home.path}`} />,
  coreRoutes.downloadApp && (
    <Route
      exact
      key="downloadApp"
      path={`/:locale${coreRoutes.downloadApp.path}`}
    >
      <RouteWrapper section="default">
        <LazyAppDownloadPage />
      </RouteWrapper>
    </Route>
  ),
];
