import React, {
  ButtonHTMLAttributes,
  forwardRef,
  MouseEvent,
  ReactNode,
} from 'react';

import { useStateSwitch } from '@gaming1/g1-utils';

import { Loader } from '../Loader';
import { StyledSystemProps } from '../types';

import {
  AppButton,
  LoaderWrapper,
  RIPPLE_ANIMATION_DELAY_IN_MS,
} from './styles';
import { ButtonType } from './types';

type CommonButtonProps = {
  children?: ReactNode;
  /**
   * Whether the button should take the whole width available
   * default: false
   */
  block?: boolean;
  /**
   * If true, will show a loader instead of the button text content
   */
  loading?: boolean;
  /**
   * Define if the border should be present on the button
   */
  noBorder?: boolean;
  /** click event handler */
  onClick?: () => void;
  /**
   * Optional testId
   * Default: 'button'
   */
  testId?: string;
  /**
   * type of the button that will change its appearance
   * can be one of 'default' | 'primary' | 'secondary' | 'danger' | 'warning'
   * default: 'default'
   */
  type?: ButtonType;
};

export type ButtonProps = CommonButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'type'> &
  StyledSystemProps;

/**
 * Simple themed button with multiple types
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled,
      loading,
      onClick,
      testId = 'button',
      type,
      noBorder,
      ...rest
    },
    ref,
  ) => {
    const [animating, setAnimating] = useStateSwitch(
      false,
      RIPPLE_ANIMATION_DELAY_IN_MS,
    );

    const handleButtonClick = (
      e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
    ) => {
      if (typeof onClick === 'function') {
        e.preventDefault();
        onClick();
      }
      setAnimating(true);
    };
    return (
      <AppButton
        animating={animating}
        colorType={type}
        data-testid={testId}
        disabled={!!disabled || !!loading}
        noBorder={noBorder}
        onClick={handleButtonClick}
        ref={ref}
        type={type === 'submit' ? 'submit' : 'button'}
        {...rest}
      >
        {loading ? (
          <LoaderWrapper>
            <Loader smart={false} />
          </LoaderWrapper>
        ) : (
          children
        )}
      </AppButton>
    );
  },
);

export { AppButton };
export {
  APP_BUTTON_HEIGHT_IN_PX,
  AppButtonAnchor,
  AppButtonLink,
  appButtonCSS,
} from './styles';
export type { AppButtonProps } from './styles';
export type { ButtonType } from './types';
