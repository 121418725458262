import { createAsyncAction } from 'typesafe-actions';

import { LocaleCode } from '@gaming1/g1-config';
import { FailurePayload } from '@gaming1/g1-utils';

/** Change the language of the app */
export const switchLocale = createAsyncAction(
  'core/switch_locale_request',
  'core/switch_locale_success',
  'core/switch_locale_failure',
)<LocaleCode, LocaleCode, FailurePayload>();
