/**
 * Known languages
 */
export enum LocaleCodeEnum {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  nl = 'nl',
  pt = 'pt',
  sr = 'sr',
}

// This type is duplicated in g1-utils/src/helpers.ts, it should also be updated there.
export type DateFormat = 'dd/MM/yyyy' | 'MM/dd/yyyy' | 'yyyy-MM-dd';

/** Possible locale codes */
export type LocaleCode = keyof typeof LocaleCodeEnum;

/*
 * For more information on date formats (https://date-fns.org/docs/format).
 * The disposition of the formats might change according to partners
 * but the same information will always be displayed.
 */
export type I18nConfig = Readonly<{
  /** List of languages available for the app */
  availableLanguages: LocaleCode[];
  /** Date (day, month, year) */
  dateFormat: DateFormat;
  /** Date short (day, month) */
  dateShortFormat: string;
  /** Date (day, month, year) + time (hours, minutes, seconds) */
  dateTimeFormat: string;
  /** Long date time (month name, day, year, hours, minutes) */
  dateTimeLongFormat: string;
  /** Date (day, month, year) + time partial (hours, minutes) */
  dateTimePartialFormat: string;
  /** Date short (day, month) + time partial (hours, minutes) */
  dateTimeShortPartialFormat: string;
  /** Day of the week (iex. 'tuesday') */
  dayOfTheWeekFormat: string;
  /** Default language of the app if the preferred could not be found */
  defaultLanguage: LocaleCode;
  /** If true the available languages should be fetched from the backend */
  shouldBeRestrictedByShellParams: boolean;
  /** Time (hours, minutes, seconds) */
  timeFormat: string;
  /** Time partial (hours, minutes) */
  timePartialFormat: string;
}>;
