import { AppConfig } from './types';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type OverwriteConfig = {
  apiUrl?: string;
  loyaltyGameServerUrl?: string;
  mediaUrl?: string;
  prismicUrl?: string;
  siteUrl?: string;
  wsUrl?: string;
  zendeskUrl?: string;
};

// The lib can be used in both dom and non dom env
export type GlobalWithConditionalWindow = typeof globalThis & {
  window?: {
    overwriteConfig?: OverwriteConfig;
  };
};
const conditionalWindow = (globalThis as GlobalWithConditionalWindow).window;

const isNotReplacementKey = (value: string) => !/^#{.*}$/.test(value);

const isValidString = (value?: string): value is string =>
  value !== undefined &&
  typeof value === 'string' &&
  value.length > 0 &&
  isNotReplacementKey(value);
/**
 * Gets overwrite configuration with the values from the server ('overwriteConfig' property on window object).
 */
export const getOverwriteConfigByServerEnv = (): DeepPartial<AppConfig> => {
  const overwriteConfig =
    typeof conditionalWindow !== 'undefined'
      ? conditionalWindow?.overwriteConfig
      : undefined;

  return overwriteConfig
    ? {
        network: {
          ...(isValidString(overwriteConfig.apiUrl) && {
            apiUrl: overwriteConfig.apiUrl,
          }),
          ...(isValidString(overwriteConfig.loyaltyGameServerUrl) && {
            loyaltyGameServerUrl: overwriteConfig.loyaltyGameServerUrl,
          }),
          ...(isValidString(overwriteConfig.mediaUrl) && {
            mediaUrl: overwriteConfig.mediaUrl,
          }),
          ...(isValidString(overwriteConfig.prismicUrl) && {
            prismicUrl: overwriteConfig.prismicUrl,
          }),
          ...(isValidString(overwriteConfig.siteUrl) && {
            siteUrl: overwriteConfig.siteUrl,
          }),
          ...(isValidString(overwriteConfig.wsUrl) && {
            wsUrl: overwriteConfig.wsUrl.split(','),
          }),
          ...(isValidString(overwriteConfig.zendeskUrl) && {
            zendeskUrl: overwriteConfig.zendeskUrl,
          }),
        },
      }
    : {};
};
