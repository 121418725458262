import { MarketingConfig } from '../types/marketing';

/**
 * Marketing default configuration
 */
export const defaultMarketingConfig: MarketingConfig = {
  appsFlyer: {
    devKey: null,
    webDevKey: null,
  },
};
