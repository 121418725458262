import React, { FC, ReactNode, Suspense } from 'react';

import { Content, GoogleFont, SuspenseLoader } from '@gaming1/g1-core-web';
import { LoaderContext, MainContainer } from '@gaming1/g1-ui';

import { AppLoader } from './AppLoader';

const loaders = {
  default: () => <AppLoader height="3em" width="3em" />,
  page: () => <AppLoader height="10em" width="10em" />,
};

export const AppLayout: FC<{ children?: ReactNode }> = ({ children }) => (
  <MainContainer>
    <LoaderContext.Provider value={loaders}>
      <Suspense fallback={<SuspenseLoader />}>
        <Content>{children}</Content>
      </Suspense>
      <GoogleFont fontFamily="Montserrat:400,500,700,800" />
    </LoaderContext.Provider>
  </MainContainer>
);
