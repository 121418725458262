// The lib can be used in both dom and non dom env
type GlobalWithConditionalWindow = typeof globalThis & {
  window?: {
    document?: unknown;
    localStorage?: {
      getItem: (name: string) => string;
      setItem: (name: string, value: string) => void;
    };
    location?: {
      href: string;
    };
    navigator?: { product?: string };
    overwriteEnv?: { deployEnv: string };
    reactInterop?: unknown;
    ReactNativeWebView?: {
      postMessage: (message: string) => void;
    };
    // should be the same as packages/g1-logger/src/types/conditionalWindow.ts
    shouldBypassJestDetection?: boolean;
  };
};
/**
 * Window object extract from "global" with every (possibly undefined)
 * properties it can have on both web and RN environments
 */
export const conditionalWindow = (globalThis as GlobalWithConditionalWindow)
  .window;
