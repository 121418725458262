import { useContext, useEffect, useState } from 'react';

import { EWebSocketStatus } from '@gaming1/g1-network';

import { NetworkContext } from './components/NetworkProvider/NetworkContext';

/** Returns the two utilities to handle network transactions
 * - ajaxFetch for HTTP requests
 * - wsAdapter for WebSocket requests
 */
export const useNetwork = () => useContext(NetworkContext);

/** Return the current state of the wsAdapter status */
export const useNetworkState = () => {
  const { wsAdapter } = useNetwork();
  const [newtworkState, setNetworkState] = useState<EWebSocketStatus>(
    wsAdapter.getStatus(),
  );
  useEffect(() => {
    const subscription = wsAdapter.status$.subscribe(setNetworkState);
    return () => subscription.unsubscribe();
  }, [wsAdapter]);
  return newtworkState;
};
