/* istanbul ignore file */

import { combineReducers, Reducer } from 'redux';

import { commonCoreReducer } from './common/reducer';
import { geolocationReducer } from './geolocation/reducer';
import { i18nReducer } from './i18n/reducer';
import { loginReducer } from './login/reducer';
import { userSearchReducer } from './search/reducer';
import { sliderReducer } from './slider/reducer';
import { trackingReducer } from './tracking/reducer';
import { CoreActions, CoreState } from './types';
import { userCoreReducer } from './user/reducer';

export const coreReducer: Reducer<CoreState, CoreActions> = combineReducers({
  common: commonCoreReducer,
  geolocation: geolocationReducer,
  i18n: i18nReducer,
  login: loginReducer,
  search: userSearchReducer,
  slider: sliderReducer,
  tracking: trackingReducer,
  user: userCoreReducer,
});
