import { createAsyncAction } from 'typesafe-actions';

import { GetSliderRequestDTO } from '@gaming1/g1-requests';
import { FailurePayload } from '@gaming1/g1-utils';

import { GetSliderResponse } from './types';

/** Get slider */
export const getSlider = createAsyncAction(
  'core/get_slider_request',
  'core/get_slider_success',
  'core/get_slider_failure',
)<
  Omit<GetSliderRequestDTO, 'RoomDomainName'>,
  GetSliderResponse & { request: Omit<GetSliderRequestDTO, 'RoomDomainName'> },
  FailurePayload & { request: Omit<GetSliderRequestDTO, 'RoomDomainName'> }
>();
