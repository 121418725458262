import { conditionalWindow } from '../types/conditionalWindow';
import { DeployEnv } from '../types/deployEnv';

import { getEnvVariable } from './getEnvVariable';

const isDeployEnv = (envValue?: string): envValue is DeployEnv =>
  !!envValue &&
  ['local', 'development', 'testing', 'acceptance', 'production'].includes(
    envValue,
  );

/**
 * Get the current deploy env based on process.env values
 * Priorities: OVERWRITE_ENV > REACT_DEPLOY_ENV > NODE_ENV > fallback ( = 'development')
 */
export const getDeployEnvAndSource = (): { env: DeployEnv; source: string } => {
  const reactEnv = getEnvVariable('deployEnv');
  const nodeEnv = getEnvVariable('nodeEnv');

  const overwrittenEnv =
    typeof conditionalWindow !== 'undefined'
      ? conditionalWindow?.overwriteEnv?.deployEnv
      : undefined;
  switch (true) {
    case isDeployEnv(overwrittenEnv):
      return { env: overwrittenEnv as DeployEnv, source: 'OVERWRITE_ENV' };
    case isDeployEnv(reactEnv):
      return { env: reactEnv as DeployEnv, source: 'REACT_APP_DEPLOY_ENV' };
    case isDeployEnv(nodeEnv):
      return { env: nodeEnv as DeployEnv, source: 'NODE_ENV' };
    default:
      return { env: 'development', source: 'fallback' };
  }
};

export const getDeployEnv = () => getDeployEnvAndSource().env;
