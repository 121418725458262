/* istanbul ignore file */
// Unfortunately the latest testing-library/dom update make it impossible to test those
import { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { colors, fontSizes, fontWeight } from '@gaming1/g1-style';

import {
  SCROLL_BAR_BORDER_SIZE,
  SCROLL_BAR_SIZE,
  SCROLL_LOCK_CLASSNAME,
} from '../constants';

const removeBottomMarginsCss = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
  }
`;

/**
 * Scrollbar style.
 * https://alligator.io/css/css-scrollbars/ & https://codepen.io/team/css-tricks/pen/QWWXQzE
 */

const scrollBarCss = css`
  /**
  * Those 2 are for Firefox only. It's actually the standard (still experimental) but for now only FF implement it.
  *  See: https://developer.mozilla.org/fr/docs/Web/CSS/CSS_Scrollbars
   */
  html {
    scrollbar-width: thin;
    scrollbar-color: ${colors('tertiary')} ${colors('backgrounds')};
  }

  div {
    scrollbar-width: thin;
    scrollbar-color: ${colors('secondary')} transparent;
  }

  /**
   * The others below are for webkit based browsers since they don't implement the standard yet
   */
  html::-webkit-scrollbar {
    height: ${SCROLL_BAR_SIZE + SCROLL_BAR_BORDER_SIZE * 2}px;
    width: ${SCROLL_BAR_SIZE + SCROLL_BAR_BORDER_SIZE * 2}px;
  }

  div::-webkit-scrollbar {
    height: ${SCROLL_BAR_SIZE}px;
    width: ${SCROLL_BAR_SIZE}px;
  }

  html::-webkit-scrollbar-track {
    background: ${colors('backgrounds')};
  }

  div::-webkit-scrollbar-track {
    background: transparent;
  }

  html::-webkit-scrollbar-thumb {
    background-color: ${colors('tertiary')};
    border-radius: ${SCROLL_BAR_BORDER_SIZE * 2}px;
    border: ${SCROLL_BAR_BORDER_SIZE}px solid ${colors('backgrounds')};
  }

  div::-webkit-scrollbar-thumb {
    background-color: ${colors('tertiary')};
    border-radius: ${SCROLL_BAR_BORDER_SIZE * 2}px;
  }
`;

/**
 * Component that will populate the app with global css
 * (should be used only once)
 */
export const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    ${removeBottomMarginsCss}
    ${scrollBarCss}

    html {
      overflow-x:hidden;

      /** Remove the default chrome/safari highlight color. Instead we must
       * design our interactive component to change their color when
       * active/focused */
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }

    html, body {
    ${fontWeight('regular')};
      box-sizing: border-box;
      height: 100%;
      font-size: ${fontSizes('lg')};
      position: relative;
      -webkit-overflow-scrolling : touch;

      /*
      Ensure the background is not white if an element is larger than the
      viewport on mobile (shouldn't happen but in case of)
      */
      background-color: ${colors('mainBackground')};

    }

    body {
      /*
      * Used when a modal or drawer content is visible, prevent the
      * body to be scrolled
      */
      &.${SCROLL_LOCK_CLASSNAME} {
        /* See https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/ */
        position: fixed;
        width: 100%;
      }
    }

     *, *::before, *::after {
      box-sizing: inherit;
    }

    #root {
      min-height: 100%;
    }

 /**
 * Hide recaptcha badge
 * (Do not use display:none because it will disabled spam checking)
 *
 * TODO: Move this css code to the g1-user-web package in the module that manages the captcha (registration)
 */
    .grecaptcha-badge {
      visibility:hidden;
    }

  `;

/**
 * Component that will populate a shadow tree with global css
 * (should be used only once)
 */
export const GlobalShadowStyle = createGlobalStyle`
  ${styledNormalize}
  ${removeBottomMarginsCss}

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

/** Global style for Storybook use only */
export const GlobalStoryStyle = createGlobalStyle`
  ${styledNormalize}
  ${removeBottomMarginsCss}

  /* !important is added because some stylings are added inline by SB */

  html, body {
    min-height: 100% !important;
    height: 100% !important;
    ${fontWeight('regular')};
    position: relative;
  }

  #root, #story-root {
    ${fontWeight('regular')};
    box-sizing: border-box;

    /* height: 100%; */
    min-height: 100%;
    font-size: ${fontSizes('lg')};
    padding: 1em;
    background-color: ${colors('mainBackground')};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #root *, #root *::before, #root *::after, #story-root *, #story-root *::before, #story-root *::after {
    box-sizing: inherit;
  }

  .sb-show-main {
    padding: 0 !important;
    min-height: 100% !important;
  }
`;
