import React, { FC, ReactNode, useRef } from 'react';

import { ScrollContext } from '../contexts';

import { AppMain, MainWrapperDiv } from './styles';

export type MainContainerProps = {
  children?: ReactNode;
  /** ClassName of the outer div */
  className?: string;
};
/** Wrapper of the app (should be used only once) */
export const MainContainer: FC<MainContainerProps> = ({
  children,
  className,
}) => {
  const htmlElement = document.querySelector('html');
  const scrollRef = useRef<HTMLHtmlElement | null>(htmlElement);
  return (
    <MainWrapperDiv className={className} data-testid="main-container">
      <AppMain ref={scrollRef}>
        <ScrollContext.Provider value={scrollRef}>
          {children}
        </ScrollContext.Provider>
      </AppMain>
    </MainWrapperDiv>
  );
};
