import { isNonNullable } from '@gaming1/g1-utils';

/**
 * Custom detector intended to be used by the i18next-browser-languageDetector
 * plugin. It returns the truncated codes of the browser's preferred languages.
 * Example: ['fr-Be', 'en-US', 'en'] -> ['fr', 'en', 'en'].
 */
export const customNavigator = {
  name: 'customNavigator',
  lookup: () =>
    typeof navigator !== 'undefined'
      ? [...(navigator.languages || []), navigator.language]
          .filter(isNonNullable)
          .map((language) => language.substring(0, 2))
      : [],
};
