/* eslint-disable react/no-array-index-key */
import React from 'react';

import { BuildHeadTagsParams } from './types';

export const buildHeadTags = (config: BuildHeadTagsParams) => {
  const defaults = {
    templateTitle: '',
    defaultOpenGraphImageWidth: 0,
    defaultOpenGraphImageHeight: 0,
  };

  const tagsToRender = [];

  if (config.titleTemplate) {
    defaults.templateTitle = config.titleTemplate;
  }

  let updatedTitle = '';
  if (config.title) {
    updatedTitle = config.title;
    if (defaults.templateTitle) {
      updatedTitle = defaults.templateTitle.replace(/%s/g, () => updatedTitle);
    }
    tagsToRender.push(<title key="title">{updatedTitle}</title>);
  }

  if (config.description) {
    tagsToRender.push(
      <meta
        key="description"
        name="description"
        content={config.description}
      />,
    );
  }

  if (config.mobileAlternate) {
    tagsToRender.push(
      <link
        rel="alternate"
        key="mobileAlternate"
        media={config.mobileAlternate.media}
        href={config.mobileAlternate.href}
      />,
    );
  }

  if (config.languageAlternates && config.languageAlternates.length > 0) {
    config.languageAlternates.forEach((languageAlternate) => {
      tagsToRender.push(
        <link
          rel="alternate"
          key={`languageAlternate-${languageAlternate.hrefLang}`}
          hrefLang={languageAlternate.hrefLang}
          href={languageAlternate.href}
        />,
      );
    });
  }

  if (config.defaultLanguageHref && config.languageAlternates?.length) {
    tagsToRender.push(
      <link
        rel="alternate"
        key="defaultLanguageAlternate"
        hrefLang="x-default"
        href={config.defaultLanguageHref}
      />,
    );
  }

  if (config.twitter) {
    if (config.twitter.cardType) {
      tagsToRender.push(
        <meta
          key="twitter:card"
          name="twitter:card"
          content={config.twitter.cardType}
        />,
      );
    }

    if (config.twitter.site) {
      tagsToRender.push(
        <meta
          key="twitter:site"
          name="twitter:site"
          content={config.twitter.site}
        />,
      );
    }

    if (config.twitter.handle) {
      tagsToRender.push(
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content={config.twitter.handle}
        />,
      );
    }
  }

  if (config.facebook) {
    if (config.facebook.appId) {
      tagsToRender.push(
        <meta
          key="fb:app_id"
          property="fb:app_id"
          content={config.facebook.appId}
        />,
      );
    }
  }

  if (config.openGraph) {
    if (config.canonical) {
      tagsToRender.push(
        <meta key="og:url" property="og:url" content={config.canonical} />,
      );
    }

    if (config.openGraph.type) {
      const type = config.openGraph.type.toLowerCase();

      tagsToRender.push(
        <meta key="og:type" property="og:type" content={type} />,
      );
    }

    if (config.openGraph.title || config.title) {
      tagsToRender.push(
        <meta
          key="og:title"
          property="og:title"
          content={config.openGraph.title || updatedTitle}
        />,
      );
    }

    if (config.openGraph.description || config.description) {
      tagsToRender.push(
        <meta
          key="og:description"
          property="og:description"
          content={config.openGraph.description || config.description}
        />,
      );
    }

    // images
    if (config.defaultOpenGraphImageWidth) {
      defaults.defaultOpenGraphImageWidth = config.defaultOpenGraphImageWidth;
    }

    if (config.defaultOpenGraphImageHeight) {
      defaults.defaultOpenGraphImageHeight = config.defaultOpenGraphImageHeight;
    }

    if (config.openGraph.images && config.openGraph.images.length) {
      config.openGraph.images.forEach((image, index) => {
        tagsToRender.push(
          <meta
            key={`og:image:0${index}`}
            property="og:image"
            content={image.url}
          />,
        );

        if (image.alt) {
          tagsToRender.push(
            <meta
              key={`og:image:alt0${index}`}
              property="og:image:alt"
              content={image.alt}
            />,
          );
        }

        if (image.width) {
          tagsToRender.push(
            <meta
              key={`og:image:width0${index}`}
              property="og:image:width"
              content={image.width.toString()}
            />,
          );
        } else if (defaults.defaultOpenGraphImageWidth) {
          tagsToRender.push(
            <meta
              key={`og:image:width0${index}`}
              property="og:image:width"
              content={defaults.defaultOpenGraphImageWidth.toString()}
            />,
          );
        }

        if (image.height) {
          tagsToRender.push(
            <meta
              key={`og:image:height${index}`}
              property="og:image:height"
              content={image.height.toString()}
            />,
          );
        } else if (defaults.defaultOpenGraphImageHeight) {
          tagsToRender.push(
            <meta
              key={`og:image:height${index}`}
              property="og:image:height"
              content={defaults.defaultOpenGraphImageHeight.toString()}
            />,
          );
        }
      });
    }

    if (config.openGraph.locale) {
      tagsToRender.push(
        <meta
          key="og:locale"
          property="og:locale"
          content={config.openGraph.locale}
        />,
      );
    }

    if (config.openGraph.site_name) {
      tagsToRender.push(
        <meta
          key="og:site_name"
          property="og:site_name"
          content={config.openGraph.site_name}
        />,
      );
    }
  }

  if (config.canonical) {
    tagsToRender.push(
      <link rel="canonical" href={config.canonical} key="canonical" />,
    );
  }

  if (config.noindex || config.nofollow) {
    const content = [];
    if (config.noindex) {
      content.push('noindex');
    }
    if (config.nofollow) {
      content.push('nofollow');
    }
    tagsToRender.push(
      <meta key="robots" name="robots" content={content.join(',')} />,
    );
  }

  if (config.additionalMetaTags && config.additionalMetaTags.length > 0) {
    config.additionalMetaTags.forEach((tag) => {
      tagsToRender.push(
        <meta key={tag.name ? tag.name : tag.property} {...tag} />,
      );
    });
  }

  return tagsToRender;
};
