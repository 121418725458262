import React, {
  ContextType,
  FC,
  memo,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isLocaleCode, useConfig } from '@gaming1/g1-config';
import { I18nContext, I18nValues } from '@gaming1/g1-i18n';

import { whitelistedLanguagesSelector } from '../../selectors';

export type I18nProviderValue = {
  availableLanguagesFullCode: string[];
  values: I18nValues;
};

export type I18nProviderProps = {
  children?: ReactNode;
  value: I18nProviderValue;
};

/** Provides the context for i18n */
export const I18nProvider: FC<I18nProviderProps> = memo(
  ({ children, value: { availableLanguagesFullCode, values } }) => {
    const { i18n: i18nConfig } = useConfig();
    /* TODO: find a way to make the message "Warning: An update to I18nProvider
    inside a test was not wrapped in act(...)" disappear. By passing "false" to
    bindI18n in getTestI18n(), it goes away (because then react-i18next doesn't
    call setState on mount anymore) but that cause tests that relies on language
    update to fail. Using useContext(I18NextContext) instead of useTranslation
    here has the same effects */
    // const { i18n: i18nextInstance } = useContext(I18NextContext);
    const { i18n: i18nextInstance } = useTranslation();
    const {
      availableLanguages: availableLanguagesFromConfig,
      defaultLanguage,
    } = i18nConfig;
    const { language: currentLanguage } = i18nextInstance;

    const whitelistedLanguagesFromBackend = useSelector(
      whitelistedLanguagesSelector,
    );

    const filteredAvailableLanguages = availableLanguagesFromConfig.filter(
      (lang) => whitelistedLanguagesFromBackend.includes(lang),
    );

    const shouldAvailableLanguagesBeFiltered =
      i18nConfig.shouldBeRestrictedByShellParams &&
      !!whitelistedLanguagesFromBackend.length &&
      !!filteredAvailableLanguages.length;

    const availableLanguages = shouldAvailableLanguagesBeFiltered
      ? filteredAvailableLanguages
      : availableLanguagesFromConfig;

    const currentLocale = isLocaleCode(currentLanguage)
      ? currentLanguage
      : defaultLanguage;

    /** If the current language is not whitelisted, fallback to another */
    useEffect(() => {
      if (
        shouldAvailableLanguagesBeFiltered &&
        !filteredAvailableLanguages.includes(currentLocale)
      ) {
        const fallbackLanguage = filteredAvailableLanguages.includes(
          i18nConfig.defaultLanguage,
        )
          ? i18nConfig.defaultLanguage
          : filteredAvailableLanguages[0];
        i18nextInstance.changeLanguage(fallbackLanguage);
      }
    }, [
      currentLocale,
      filteredAvailableLanguages,
      i18nConfig.defaultLanguage,
      i18nextInstance,
      shouldAvailableLanguagesBeFiltered,
    ]);

    const currentLocaleFullCode = useMemo(
      () =>
        availableLanguagesFullCode.find((lng) =>
          RegExp(`^(${currentLocale})-.*$`).test(lng),
        ) ||
        availableLanguagesFullCode[0] ||
        'en-US',
      [availableLanguagesFullCode, currentLocale],
    );

    const i18nContextValue: ContextType<typeof I18nContext> = useMemo(
      () => ({
        availableLanguages,
        availableLanguagesFullCode,
        currentLocale,
        currentLocaleFullCode,
        values,
      }),
      [
        availableLanguages,
        availableLanguagesFullCode,
        currentLocale,
        currentLocaleFullCode,
        values,
      ],
    );

    return (
      <I18nContext.Provider value={i18nContextValue}>
        {children}
      </I18nContext.Provider>
    );
  },
);
