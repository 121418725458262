import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ConfigContext } from '@gaming1/g1-config';
import { actions } from '@gaming1/g1-core';
import { RemoteData } from '@gaming1/g1-utils';

export const useGetLicense = (geoComplyLicenseRequestStatus: RemoteData) => {
  const {
    core: {
      geoComply: { activated: isGeolocationActivated },
    },
  } = useContext(ConfigContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      isGeolocationActivated &&
      geoComplyLicenseRequestStatus === RemoteData.NotAsked
    ) {
      dispatch(actions.getGeoComplyLicenseKey.request());
    }
  }, [dispatch, geoComplyLicenseRequestStatus, isGeolocationActivated]);
};
