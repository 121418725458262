import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useGetIsMounted } from '@gaming1/g1-utils';

const MUTATION_OBSERVER_CALLBACK_DEBOUNCE_IN_MS = 10;
const TIMEOUT_CALLBACK_IN_MS = 500;

/** Make the scroll restoration work when going back in the history */
export const useScrollRestoration = () => {
  const history = useHistory();
  const currentLocation = useLocation();
  const currentLocationRef = useRef(currentLocation);
  currentLocationRef.current = currentLocation;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /* Store the last scroll position for each location using its key */
  const scrollHistoryRef = useRef<Record<string, number>>({});
  const latestLocationKeyRef = useRef(currentLocation.key);

  const rootElementRef = useRef(document.getElementById('root'));

  const getIsMounted = useGetIsMounted();

  const unregisterWatchers = (observer: MutationObserver) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    observer.disconnect();
  };

  const restoreScroll = useRef((observer: MutationObserver) => {
    if (
      latestLocationKeyRef.current &&
      getIsMounted() &&
      scrollHistoryRef.current[latestLocationKeyRef.current]
    ) {
      // Prevent scrolling on pages that have become smaller
      if (
        scrollHistoryRef.current[latestLocationKeyRef.current] <
        (rootElementRef.current?.getBoundingClientRect().height || 0)
      ) {
        window.scrollTo({
          top: scrollHistoryRef.current[latestLocationKeyRef.current],
          behavior: 'auto',
        });
      }
      delete scrollHistoryRef.current[latestLocationKeyRef.current];
    }
    unregisterWatchers(observer);
  }).current;

  const observerRef = useRef(
    new MutationObserver(
      /** Callback called when the history goes back one entry and the layout
      has shifted
      */
      debounce((_, observer) => {
        restoreScroll(observer);
      }, MUTATION_OBSERVER_CALLBACK_DEBOUNCE_IN_MS),
    ),
  );

  /**  Disabled automatic scroll restoration that isn't working well for SPAs */
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    const currentObserver = observerRef.current;
    const unregisterListener = history.listen((location, action) => {
      const currentKey = currentLocationRef.current.key;
      latestLocationKeyRef.current = location.key;
      /* Store scroll position on push */
      if (action === 'PUSH' && currentKey) {
        scrollHistoryRef.current[currentKey] = window.scrollY;
        unregisterWatchers(currentObserver);
        /* Restore scroll position on pop */
      } else if (
        action === 'POP' &&
        latestLocationKeyRef.current &&
        scrollHistoryRef.current[latestLocationKeyRef.current]
      ) {
        if (rootElementRef.current) {
          // Use observer to scroll after the page is loaded
          currentObserver.observe(rootElementRef.current, {
            subtree: true,
            childList: true,
          });
          // Fallback to a set timeout if no mutation is observed
          timeoutRef.current = setTimeout(() => {
            restoreScroll(currentObserver);
          }, TIMEOUT_CALLBACK_IN_MS);
        }
      } else {
        unregisterWatchers(currentObserver);
      }
    });
    return () => {
      unregisterWatchers(currentObserver);
      unregisterListener();
    };
  }, [history, restoreScroll]);
};
