import React, { forwardRef, ReactNode, useState } from 'react';
import { useTheme } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { Icon } from '../Icon';
import { IconType } from '../Icon/icons';

import {
  CLOSE_ICON_SIZE_IN_PX,
  MessageCloseButton,
  MessageContentWrapper,
  MessageIconWrapper,
  MessageWrapper,
  NORMAL_ICON_SIZE_IN_PX,
  SMALL_ICON_SIZE_IN_PX,
} from './styles';
import { MessageSize, MessageType } from './types';

const iconsByType: { [k in MessageType]: IconType } = {
  danger: 'DiscLess',
  info: 'DiscInfo',
  success: 'DiscCheck',
  warning: 'DiscWarning',
};

export type MessageProps = {
  /** React node children */
  children?: ReactNode;
  /** Specifies whether the message box can be closed or not */
  closable?: boolean;
  /** Callback that will be called when close button is clicked */
  onCloseButtonClicked?: () => void;
  /** The size of the information message */
  size?: MessageSize;
  /** Test id (default: 'message') */
  testId?: string;
  /** Makes the background of the message transparent */
  transparent?: boolean;
  /** The type of the information message */
  type?: MessageType;
} & SpaceProps;

/** Shows information to the user */
export const Message = forwardRef<HTMLDivElement, MessageProps>(
  (
    {
      children,
      closable = false,
      onCloseButtonClicked,
      size = 'normal' as MessageSize,
      testId = 'message',
      transparent,
      type = 'info' as MessageType,
      ...stylingProps
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(true);
    const { colors } = useTheme();
    const iconSize =
      size === 'small' ? SMALL_ICON_SIZE_IN_PX : NORMAL_ICON_SIZE_IN_PX;

    return visible ? (
      <MessageWrapper
        data-testid={`${testId}-${type}`}
        ref={ref}
        type={type}
        transparent={transparent}
        {...stylingProps}
      >
        <MessageIconWrapper
          size={size}
          iconColor={transparent ? colors?.neutrals[0] : undefined}
        >
          <Icon
            id={`${testId}-${type}`}
            type={iconsByType[type]}
            height={`${iconSize}px`}
            width={`${iconSize}px`}
          />
        </MessageIconWrapper>
        <MessageContentWrapper size={size}>{children}</MessageContentWrapper>
        {closable && (
          <MessageCloseButton
            data-testid={`${testId}-${type}-close`}
            onClick={() => {
              if (onCloseButtonClicked) {
                onCloseButtonClicked();
              } else {
                setVisible(false);
              }
            }}
          >
            <Icon
              id={`${testId}-${type}-close`}
              type="Cross"
              height={`${CLOSE_ICON_SIZE_IN_PX}px`}
              width={`${CLOSE_ICON_SIZE_IN_PX}px`}
            />
          </MessageCloseButton>
        )}
      </MessageWrapper>
    ) : null;
  },
);
