import { useEffect } from 'react';
import { switchMap, tap } from 'rxjs';
import { filter, first, pluck } from 'rxjs/operators';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { EWebSocketStatus, wsAdapter } from '@gaming1/g1-network';

import { logger } from '../../../logger';
import { useIframeMessages } from '../../../rninterop/hooks/useIframeMessages';

const isNetWorkAllowed = (networkType: string) =>
  ['cellular', 'wifi'].includes(networkType);

export const useConnectionChangeDetection = () => {
  const { changeConnectionType$ } = useIframeMessages();

  /**
   * Disconnects the WS when a specific action is received
   */
  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      const subscription = changeConnectionType$
        .pipe(
          pluck('payload'),
          filter(
            ({ connectionType, previousConnectionType }) =>
              !!connectionType &&
              connectionType !== 'unknown' &&
              !!previousConnectionType &&
              previousConnectionType !== 'unknown' &&
              isNetWorkAllowed(connectionType) &&
              isNetWorkAllowed(previousConnectionType),
          ),
          tap(() => {
            logger.info(
              `[RN Interop] WS connection will be lost due to connection type switch. Closing it...`,
            );
          }),
          tap(() => {
            wsAdapter.triggerDisconnection();
          }),
          switchMap(() =>
            wsAdapter.status$.pipe(
              filter((WSStatus) => WSStatus === EWebSocketStatus.disconnected),
              first(),
            ),
          ),
        )
        .subscribe({
          next: () => {
            wsAdapter.reconnect();
          },
        });

      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }

    return () => undefined;
  }, [changeConnectionType$]);
};
