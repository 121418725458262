import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';

export type MainWrapperDivProps = {
  className?: string;
};

export const MainWrapperDiv = styled.div<MainWrapperDivProps>`
  background-color: ${colors('mainBackground')};
  min-height: 100vh;
  display: flex;
`;

export const AppMain = styled.main`
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors('text')};
`;
