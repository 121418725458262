import { EnvVar } from '../types/envVar';

export const getEnvVariable = (varName: EnvVar): string | undefined => {
  let hasProcessEnv = false;
  try {
    hasProcessEnv = !!process.env.REACT_APP_DEPLOY_ENV;
  } catch {
    //
  }
  // Create-react-app
  // TODO: Make this work with vite https://stackoverflow.com/questions/69613533/testing-react-app-with-jest-using-vite-as-a-module-bundler-import-meta-error
  if (hasProcessEnv) {
    // Only way to ensure process.env variables are available
    switch (varName) {
      // TODO: decide if we want to use default values (which is not the case ATM)
      case 'appName':
        return process.env.REACT_APP_NAME;
      case 'appVersion':
        return process.env.REACT_APP_VERSION;
      case 'artifactVersion':
        return process.env.REACT_APP_ARTIFACT_VERSION;
      case 'baseName':
        return process.env.REACT_APP_BASE_NAME; // || '/';
      case 'deployEnv':
        return process.env.REACT_APP_DEPLOY_ENV; // || 'production';
      case 'gitHead':
        return process.env.REACT_APP_GIT_HEAD;
      case 'interopAssetsPath':
        return process.env.REACT_APP_INTEROP_ASSETS_PATH; // || '/';
      case 'nodeEnv':
        return process.env.NODE_ENV; // || 'production';
      case 'wrapperEnv':
        return process.env.REACT_APP_WRAPPER_ENV;
      default:
        return undefined;
    }
  }
  return undefined;
};
