import mergeWith from 'lodash/mergeWith';

import { createDefaultOverrides, defaultSimpleTheme } from './defaultTheme';
import {
  PartialSimpleTheme,
  PartialThemeOverrides,
  SimpleTheme,
  Theme,
  ThemeWithoutAliases,
} from './types';
import {
  BreakpointsWithAliases,
  FontSizesWithAliases,
  OpacityWithAliases,
  SpaceWithAliases,
  ThemeAliases,
  ZIndicesWithAliases,
} from './types/aliases';

/**
 * Create a theme by merging the default values with the overwrites provided
 * Array are replaced and not merged
 * @param partnerTheme partial theme object that overwrite the default values
 */
export const getTheme = (
  partnerTheme: PartialSimpleTheme = {},
  partialOverrides?: (partnerTheme: SimpleTheme) => PartialThemeOverrides,
): Readonly<Theme> => {
  const simpleTheme: SimpleTheme = mergeWith(
    {},
    defaultSimpleTheme,
    partnerTheme,
  );
  const theme: ThemeWithoutAliases = mergeWith(
    {},
    simpleTheme,
    createDefaultOverrides(simpleTheme),
    partialOverrides ? partialOverrides(simpleTheme) : {},
    (_, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined),
  );
  const themeAliases: ThemeAliases = {
    breakpoints: new BreakpointsWithAliases(
      partnerTheme.breakpoints || simpleTheme.breakpoints,
    ),
    fontSizes: new FontSizesWithAliases(simpleTheme.fontSizes),
    opacity: new OpacityWithAliases(simpleTheme.opacity),
    space: new SpaceWithAliases(simpleTheme.space),
    zIndices: new ZIndicesWithAliases(simpleTheme.zIndices),
  };
  return {
    ...theme,
    ...themeAliases,
  };
};

export const defaultTheme = getTheme(defaultSimpleTheme);
