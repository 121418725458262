import { SeoConfig } from '../types';

/**
 * SEO specific config
 */
export const defaultSeoConfig: SeoConfig = {
  dataDog: {
    applicationId: null,
    clientToken: null,
    partnerTag: null,
    sampleRate: 100,
    sessionReplaySampleRate: 5,
    site: 'datadoghq.com',
  },
  defaultImage:
    'https://www.gaming1.com/media/games/54887eb8-3518-e5a0-b25f-4bfec4c4de63.jpg',
  fullStoryOrgKey: null,
  hotjar: { id: null, version: 6 },
  metaPixelId: null,
  screebWebsiteId: null,
  sitemapBlackListedPaths: [
    '^/profile/?',
    '^/account/?',
    '^/deposit/?',
    '^/withdrawal/?',
    '^/[a-z]+-search-[a-z]+-results/?',
  ],
  twitterCard: 'summary_large_image',
  twitterSite: '@gaming1',
};
