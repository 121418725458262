import * as t from 'io-ts';

import { MessageTopic } from './types';

const messageTopicPattern = /[a-zA-Z-]+\/[a-zA-Z-]+/;

const isMessageTopic = (input: unknown): input is MessageTopic =>
  typeof input === 'string' && messageTopicPattern.test(input);

/** Custom type for strings containing at least one slash (e.g 'namespace/event') */
export const messageTopicCodec = new t.Type<MessageTopic, string, unknown>(
  'MessageTopic',
  isMessageTopic,
  (input, codec) =>
    isMessageTopic(input) ? t.success(input) : t.failure(input, codec),
  (a) => a,
);

// Matches the Envelope type defined in packages/g1-message-bus/src/types.ts
export const envelopeCodec = t.type(
  {
    channel: t.string,
    id: t.string,
    meta: t.record(t.string, t.string),
    payload: t.unknown,
    timestamp: t.number,
    topic: messageTopicCodec,
  },
  'Envelope',
);
