import React, {
  ComponentProps,
  ElementType,
  FC,
  Fragment,
  ReactNode,
} from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Store } from 'redux';
import { ThemeProvider } from 'styled-components';

import { AppConfig, ConfigContext } from '@gaming1/g1-config';
import { I18nProvider, NetworkProvider } from '@gaming1/g1-core';
import { getEnvVariable } from '@gaming1/g1-env';
import { I18n, I18nextProvider } from '@gaming1/g1-i18n';
import { HistoryProvider } from '@gaming1/g1-routing';
import { Theme } from '@gaming1/g1-style';
import { ZendeskWidgetProvider } from '@gaming1/g1-support-widget/web';
import { LayoutProvider, ToastProvider } from '@gaming1/g1-ui';

import { GeolocationProvider } from '../../../geolocation/components/GeolocationProvider';
import { logger } from '../../../logger';
import { NotificationProvider } from '../../../notifications/components/NotificationProvider';
import { BlacklistedRoutesProvider } from '../../../rninterop/components/BlacklistedRoutesProvider';
import { IframeEventsProvider } from '../../../rninterop/components/IframeEventsProvider';
import { iframeMessageManager } from '../../../rninterop/iframeMessageManager';
import { TrackingProvider } from '../../../tracking/components/TrackingProvider';

/** Extract the react-router base-name from the env */
const baseName = getEnvVariable('baseName');
const ROUTER_BASE_NAME =
  typeof baseName === 'string' && baseName.length > 1 ? baseName : undefined;

logger.debug(`[Routing] base path set to ${ROUTER_BASE_NAME || '/'}`);

export type AppProviderProps = {
  children?: ReactNode;
  /** Config object from g1-config */
  config: AppConfig;
  /** i18next instance */
  i18n: I18n;
  /** i18next values */
  i18nValues: ComponentProps<typeof I18nProvider>['value'];
  /** Redux store */
  store: Store;
  /** Theme object from g1-style */
  theme: Theme;
  /** Optional wrapper to be rendered under the history provider */
  wrapper?: ElementType;
};

/* TODO: restore the StrictMode */
// TODO: try to remove i18next from dependencies to see if the TS2742 error message is still there when building with TS
/**
 * Wrap its children into multiple context providers, giving access to
 * - the redux store
 * - the config
 * - the styled-component theme
 * - the i18next instance
 * - the common routes
 * - the network helpers
 * - the react-helmet helpers
 * - the layout values
 * - the history values
 * - the react-router browserRouter
 * - the tracking helpers
 * - the notifications helpers
 * - the iframe messages manager
 * - the Zendesk widget methods
 * - the privacy settings values
 *
 */
export const AppProvider: FC<AppProviderProps> = ({
  children,
  config,
  i18n,
  i18nValues,
  store,
  theme,
  wrapper: Wrapper = Fragment,
}) => (
  <NetworkProvider>
    <ReduxProvider store={store}>
      <I18nextProvider i18n={i18n}>
        <ConfigContext.Provider value={config}>
          <I18nProvider value={i18nValues}>
            <ThemeProvider theme={theme}>
              <HelmetProvider>
                <LayoutProvider>
                  <ToastProvider>
                    <BrowserRouter basename={ROUTER_BASE_NAME}>
                      <HistoryProvider>
                        <Wrapper>
                          <TrackingProvider>
                            <NotificationProvider>
                              <IframeEventsProvider
                                iframeMessageManager={iframeMessageManager}
                              >
                                <BlacklistedRoutesProvider>
                                  <ZendeskWidgetProvider>
                                    <GeolocationProvider>
                                      {children}
                                    </GeolocationProvider>
                                  </ZendeskWidgetProvider>
                                </BlacklistedRoutesProvider>
                              </IframeEventsProvider>
                            </NotificationProvider>
                          </TrackingProvider>
                        </Wrapper>
                      </HistoryProvider>
                    </BrowserRouter>
                  </ToastProvider>
                </LayoutProvider>
              </HelmetProvider>
            </ThemeProvider>
          </I18nProvider>
        </ConfigContext.Provider>
      </I18nextProvider>
    </ReduxProvider>
  </NetworkProvider>
);
