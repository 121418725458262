import { createContext } from 'react';

import { MediaBreakPointNames } from '@gaming1/g1-style';

export enum DrawerType {
  mobileMenu = 'mobileMenu',
  search = 'search',
  bettingFavorites = 'bettingFavorites',
  bettingSlip = 'bettingSlip',
  bettingSlipFast = 'bettingSlipFast',
  allSports = 'allSports',
  sportsAndLeagues = 'sportsAndLeagues',
  shopFilters = 'shopFilters',
  shopTickets = 'shopTickets',
  fastDeposit = 'fastDeposit',
}

export type LayoutContextValue = {
  backdropClickHandler: () => void;
  backdropVisibility: boolean;
  hideDrawer: () => void;
  hideModal: (id: string) => void;
  media: MediaBreakPointNames;
  showDrawer: (name: DrawerType, isClosableByUser?: boolean) => void;
  showModal: (id: string, isClosableByUser?: boolean) => void;
  visibleDrawer: DrawerType | null;
  visibleModal: string | null;
};

export const layoutContextDefaultValue: LayoutContextValue = {
  backdropClickHandler: () => undefined,
  backdropVisibility: false,
  hideDrawer: () => undefined,
  hideModal: () => undefined,
  media: 'xs',
  showDrawer: () => undefined,
  showModal: () => undefined,
  visibleDrawer: null,
  visibleModal: null,
};

export const LayoutContext = createContext<LayoutContextValue>(
  layoutContextDefaultValue,
);
