import styled, { css, keyframes } from 'styled-components';

import { zIndex } from '@gaming1/g1-style';
import { Message } from '@gaming1/g1-ui';

import { NotificationProps } from './types';

type NotificationElementProps = Pick<NotificationProps, 'type' | 'fixed'> & {
  fadesOut: boolean;
  visible: boolean;
};

const NOTIFICATION_ENTER_ANIMATION_TIME_IN_MS = 1000;
const NOTIFICATION_PAUSE_ANIMATION_TIME_IN_MS = 4000;
const NOTIFICATION_LEAVE_ANIMATION_TIME_IN_MS = 1000;
export const NOTIFICATION_ANIMATION_TIME_IN_MS =
  NOTIFICATION_ENTER_ANIMATION_TIME_IN_MS +
  NOTIFICATION_PAUSE_ANIMATION_TIME_IN_MS +
  NOTIFICATION_LEAVE_ANIMATION_TIME_IN_MS;

const fadeIn = keyframes`
  0% { opacity: 0 }
  100% { opacity: 1 }
`;

const fadeOutDelayed = keyframes`
  0% { opacity: 1 }
  75% { opacity: 1 }
  99.9% { opacity : 0; }
  100% { opacity: 0; }
`;

export const NotificationWrapper = styled.div<NotificationElementProps>`
  position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
  z-index: ${zIndex('notification')};
  top: 0;
  left: 0;
  right: 0;

  ${({ fadesOut }) =>
    fadesOut &&
    css`
      cursor: pointer;
    `}

  ${({ fadesOut, visible }) => {
    if (visible && fadesOut) {
      return css`
        animation: ${fadeIn} ${NOTIFICATION_ENTER_ANIMATION_TIME_IN_MS}ms
            forwards,
          ${fadeOutDelayed}
            ${NOTIFICATION_PAUSE_ANIMATION_TIME_IN_MS +
            NOTIFICATION_LEAVE_ANIMATION_TIME_IN_MS}ms
            ${NOTIFICATION_ENTER_ANIMATION_TIME_IN_MS}ms forwards;
      `;
    }

    if (visible && !fadesOut) {
      return css`
        animation: ${fadeIn} ${NOTIFICATION_ENTER_ANIMATION_TIME_IN_MS}ms;
      `;
    }

    return css`
      display: none;
    `;
  }}
`;

export const NotificationMessage = styled(Message)`
  border-radius: 0;
`;
