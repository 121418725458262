import styled, { css } from 'styled-components';
import {
  flexDirection,
  FlexDirectionProps,
  space,
  SpaceProps,
} from 'styled-system';

import { elevation, media, spaces } from '@gaming1/g1-style';
import {
  FluidContentContainer,
  ResponsiveContentContainer,
} from '@gaming1/g1-ui';

const pageContentContainerCss = css`
  ${elevation(0)};
  padding-bottom: ${spaces('xs')};
  ${media.md} {
    padding-bottom: ${spaces('sm')};
  }

  flex-direction: column;

  /* Add specificity over default paddings defined in media blocks */
  && {
    ${space}
    ${flexDirection}
  }
`;

export const PageFluidContentContainer = styled(FluidContentContainer)<
  SpaceProps & FlexDirectionProps
>`
  ${pageContentContainerCss};
`;

export const PageResponsiveContentContainer = styled(
  ResponsiveContentContainer,
)<SpaceProps & FlexDirectionProps>`
  ${pageContentContainerCss};
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${PageFluidContentContainer}, ${PageResponsiveContentContainer} {
    flex-grow: 1;
  }
`;

type PageContainerProps = {
  minHeight: number;
};
export const PageContainer = styled.div<PageContainerProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => props.minHeight || 0}px;
`;
