import React, { VFC } from 'react';
import { createPortal } from 'react-dom';

import { useNotifications } from '../../hooks/useNotifications';
import { NotificationContent } from '../../NotificationContext';
import { Notification } from '../Notification';
import { NOTIFICATION_CONTAINER_CLASSNAME } from '../NotificationContainer';

/** Look at the NotificationContext to display notification */
export const NotificationWatcher: VFC = () => {
  const { closeNotification, notificationQueue } = useNotifications();

  const activeNotification = Object.values(
    notificationQueue,
  ).reduce<NotificationContent | null>((acc, notificationItem) => {
    if (acc || !notificationItem) {
      return acc;
    }
    if (Array.isArray(notificationItem) && notificationItem.length > 0) {
      const firstValueOfArray = notificationItem[0];
      return firstValueOfArray;
    }
    if (!Array.isArray(notificationItem)) {
      return notificationItem;
    }
    return acc;
  }, null);

  const notificationContainers = document.querySelectorAll(
    `.${NOTIFICATION_CONTAINER_CLASSNAME}`,
  );
  // Use the last in the DOM if multiple containers found
  const notificationContainer = notificationContainers
    ? notificationContainers[notificationContainers.length - 1]
    : null;

  return activeNotification
    ? createPortal(
        <Notification
          fixed={!notificationContainer}
          type={activeNotification.type}
          timestamp={activeNotification.timestamp}
          onClose={() => {
            closeNotification(activeNotification.type);
          }}
        >
          {activeNotification.message}
        </Notification>,
        notificationContainer || document.body,
      )
    : null;
};
