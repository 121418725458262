import { BettingConfig } from '../types/betting';

/**
 * Betting default configuration
 */
export const defaultBettingConfig: BettingConfig = {
  betPipeSteps: {
    betMaker: [
      'resetBettingSlip',
      'getInitializationData',
      'betBuilderOddsCheckPipe',
      'getOutcomeInfos',
      'getOptimalBet',
      'getRiskManagement',
      [
        'getFreebetConditionsForBettingSlip',
        'getBoostusConditionsForBettingSlip',
        'getPromotionsIdsForBettingSlip',
        'askTournamentorForBettingSlip',
      ],
    ],
    betPlacement: ['placeBet'],
  },
  bettingslip: {
    defaultStake: '20',
    multiSingle: false,
    suggestions: [20, 50, 100, 250],
  },
  boostus: {
    enabled: false,
    showTo: ['odds'],
  },
  dailyOffers: {
    enabled: false,
  },
  defaultOddsUnit: 'decimal',
  enabled: true,
  fastBettingSlip: {
    minStake: 1,
  },
  favorites: {
    enabled: false,
  },
  history: {
    useGUID: false,
  },
  initialNbSportShown: 10,
  liveStreaming: {
    enabled: false,
  },
  lmt: {
    config: {},
    enabled: true,
    fakeId: 0,
    theme: 'light',
    url: 'de4f75930e98f328c9e763240489caf5',
  },
  minimalAge: 18,
  mygift: {
    enabled: true, // it's true by default because not a new feature and to keep compatibility (see with Lucas W.)
  },
  optiodds: {
    showTo: ['odds', 'potentialWinnings'],
  },
  pagination: {
    pageSize: 20,
  },
  promotions: {
    enabled: false,
  },
  revampUI: {
    enabled: false,
  },
  roundedOdds: {
    enabled: false,
  },
  showEmailNotifications: false,
  stats: {
    enabled: false,
  },
  superOdds: {
    enabled: false,
  },
  tournamentor: {
    enabled: false,
  },
  useMobxByDefault: {
    enabled: false,
  },
};
