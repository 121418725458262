import { GetSliderRequestDTO } from '@gaming1/g1-requests';
import { slugifyPayload } from '@gaming1/g1-utils';

/**
 * This function returns a slug. The LanguageCode is blacklisted from the
 * slug to prevent storing multiple sliders for every locale. Since the slice
 * store is cleaned on locale switch, this is not a problem.
 * WARNING: if the LanguageCode is not blacklisted, the request useEffect
 * could be triggered multiple times when the locale is switched.
 */
export const slugifySliderPayload = (request: GetSliderRequestDTO) =>
  slugifyPayload(request, ['LanguageCode']);
