import { isRight, Left } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { Observable, pipe, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { debouncedEventInput$, eventInput$ } from './observables';
import {
  Envelope,
  MessageFilter,
  MessageTopic,
  Serializable,
  TopicFilter,
} from './types';

// Duplicated in g1-utils/src/helpers.ts to avoid extra dependency
// From https://stackoverflow.com/a/2117523
/**
 * Generate a uuid v4
 * @returns string
 */
export const generateUuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // Needed for guid generation
    /* eslint-disable no-bitwise */
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    /* eslint-enable no-bitwise */
    return v.toString(16);
  });

/** Create an Envelope with automatically generated timestamp and unique ID */
export const createEnvelope = <Payload extends Serializable>(
  channel: string,
  topic: MessageTopic,
  payload: Payload,
  additionalMetadata?: Record<string, string>,
): Envelope<Payload> => ({
  id: generateUuid(),
  channel,
  meta: additionalMetadata || {},
  payload,
  timestamp: Date.now(),
  topic,
});

/** Custom Error class for codec errors in observable pipes */
export class MessagePayloadError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, MessagePayloadError.prototype);
  }

  codecError: null | Left<t.Errors> = null;
}

/**
 * Given an envelope with an unknown payload and a codec, will return the
 * envelope with the typed payload if the codec matches, will throw a
 * MessagePayloadError otherwise
 */
export const envelopeCodecGuard = <C extends t.Mixed>(
  envelope: Envelope<unknown>,
  codec: C,
): Envelope<t.OutputOf<C>> => {
  const decodeResult = codec.decode(envelope.payload);

  if (isRight(decodeResult)) {
    return envelope;
  }

  const codecError = new MessagePayloadError(
    `Envelope payload for topic ${envelope.topic} did not match codec  ${codec.name}`,
  );
  codecError.codecError = decodeResult;
  throw codecError;
};

/** Returns true if the given channel matches the envelope given to the returned
 * function */
export const isOfChannel = (channel: string) => (envelope: Envelope) =>
  envelope.channel === channel;

/** Returns true if the given topic filter matches the envelope given to the
 * returned function.
 * @param {string} topicFilter can be the desired topic(e.g. 'ns/event'), a
 * namespace  with a wildcard (e.g. 'ns/*')  or a wildcard ('*') */
export const isOfTopic =
  (topicFilter: TopicFilter) =>
  (envelope: Envelope): boolean => {
    if (topicFilter === '*') {
      return true;
    }
    if (topicFilter.endsWith('/*')) {
      return new RegExp(topicFilter.replace('/*', '\\/*')).test(envelope.topic);
    }
    return topicFilter === envelope.topic;
  };

/** Pipeable operator meant to be used with an Observable of Evenlope. If given
 * a codec, it will throw if the input doesn't match. Given undefined, it will
 * always return the envelope  */
export const mapGuardConditional = <Codec extends t.Mixed | undefined>(
  codec: Codec,
) =>
  pipe(
    map(
      (
        envelope: Envelope,
      ) /* : Codec extends t.Mixed ? Envelope<t.TypeOf<Codec>> : Envelope */ =>
        codec ? envelopeCodecGuard(envelope, codec) : envelope,
    ),
  );

export function defineMessage<
  Codec extends t.Mixed,
  Topic extends MessageTopic,
>(topic: Topic, payloadCodec: Codec): MessageFilter<Topic, t.TypeOf<Codec>>;
export function defineMessage<Topic extends MessageTopic>(
  topic: Topic,
  payloadCodec?: undefined,
): MessageFilter<Topic, undefined>;
export function defineMessage<
  Codec extends t.Mixed,
  Topic extends MessageTopic,
>(
  topic: Topic,
  payloadCodec?: Codec | undefined,
): MessageFilter<Topic, t.TypeOf<Codec>> | MessageFilter<Topic, undefined>;

/**
 * Create a message definition, joining a topic and an (optional) payload.
 * This message definition can be considered as a contract between publisher
 * and subscribers
 * @param topic the message topic
 * @param payloadCodec the codec used to describe the payload. Can be undefined
 * if the message has no payload
 * @returns an object containing the topic property and payloadCodec if it was
 * defined
 */
export function defineMessage<
  Codec extends t.Mixed,
  Topic extends MessageTopic,
>(topic: Topic, payloadCodec: Codec | undefined) {
  return payloadCodec === undefined
    ? {
        topic,
      }
    : {
        topic,
        payloadCodec,
      };
}

/**
 * This helper is solely made to connecte message bus from different origins
 * (web page, iframe, RN app). It should never be used for a regular usage of
 * the bus (subscribing and publishing messages)
 */
export const getMessageBusPluginHelpers = (): {
  input$: Subject<Envelope>;
  output$: Observable<Envelope>;
} => ({
  input$: eventInput$,
  output$: debouncedEventInput$,
});

/** Returns true if the given message definition was provided a payload codec */
export const isMessageFilterWithPayload = <
  Topic extends MessageTopic,
  Payload extends Serializable | undefined,
>(
  messageDefinition: MessageFilter<Topic, Payload>,
): messageDefinition is MessageFilter<Topic, Payload> & {
  payloadCodec: t.Type<Payload>;
} => 'payloadCodec' in messageDefinition;
