import React, { FC, ReactNode } from 'react';

import {
  switchLocaleRequestStateSelector,
  userLogoutRequestStateSelector,
} from '@gaming1/g1-core';
import { useRequestState } from '@gaming1/g1-store';

import { SuspenseLoader } from '../SuspenseLoader';

type ResettingWrapperProps = {
  children?: ReactNode;
  /** Fallback element to show while an action is pending */
  fallbackContent?: JSX.Element;
};

/**
 * This component will watch specific pending actions (switchLocale and logout).
 * If one of them is pending, a fallback will be returned instead of the children.
 */
export const ResettingWrapper: FC<ResettingWrapperProps> = ({
  children,
  fallbackContent = <SuspenseLoader />,
}) => {
  const { isLoading: isLogoutPending } = useRequestState(
    userLogoutRequestStateSelector,
  );

  const { isLoading: isSwitchLocalePending } = useRequestState(
    switchLocaleRequestStateSelector,
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{isLogoutPending || isSwitchLocalePending ? fallbackContent : children}</>
  );
};
