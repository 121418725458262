import { useContext, useMemo } from 'react';

import { ConfigContext } from '@gaming1/g1-config';

/**
 * Extract the initial language from the url.
 * So for the url localhost/fr/login, 'fr' will be returned
 * Only useful to use at the start of the app, since MultiLanguageRouter will
 * automatically rewrite the URL after the launch of the app
 */
export const useInitialAppLanguage = (pathname: string) => {
  const config = useContext(ConfigContext);

  const initialLanguage = useMemo(() => {
    const { availableLanguages } = config.i18n;

    const languages = availableLanguages.join('|');
    const localeRegex = new RegExp(`^/(${languages})/|^/(${languages})$`);

    const languageURLMatch = pathname.match(localeRegex);
    return languageURLMatch
      ? languageURLMatch[1] || languageURLMatch[2]
      : undefined;
    // This should only be run one time, at the start of the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return initialLanguage;
};
