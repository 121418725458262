import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { NEVER, Observable } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { GLOBAL_CHANNEL } from './constants';
import {
  defineMessage,
  isMessageFilterWithPayload,
  isOfChannel,
  isOfTopic,
  mapGuardConditional,
  MessagePayloadError,
} from './helpers';
import { logger } from './logger';
import { debouncedEventInput$ } from './observables';
import { Envelope, MessageFilter, MessageTopic, TopicFilter } from './types';

/**
 * Returns an observable yielding envelopes with unknown payload from the given
 * channel and topic
 * @deprecated
 * @param channel The channel in which the message would be sent
 * @param topicFilter The topic to subscribe to. Can use a wildcard to match multiple
 * topics with the same namespace (e.g. 'namespace/*')
 * @returns an observable of envelopes
 */
export const getMessage$ = (
  channel: string,
  topicFilter: TopicFilter,
): Observable<Envelope> =>
  debouncedEventInput$.pipe(
    filter(isOfChannel(channel)),
    filter(isOfTopic(topicFilter)),
  );

/**
 * Returns an observable yielding envelopes from the given
 * channel and topic only if the payload matches the given codec
 * @deprecated
 * @param channel The channel in which the message would be sent
 * @param topicFilter The topic to subscribe to. Can use a wildcard to match
 * multiple topics with the same namespace (e.g. 'namespace/*')
 * @param codec The codec ensuring the type safety of the payload. In the case
 * of a multi topic subscription, should be a t.union
 * @returns an observable of envelopes
 */
export const getTypedMessage$ = <Codec extends t.Mixed>(
  channel: string,
  topicFilter: TopicFilter,
  codec: Codec | undefined,
): Observable<Envelope<t.TypeOf<Codec>>> =>
  getMessage$(channel, topicFilter).pipe(
    mapGuardConditional(codec),
    catchError((error) => {
      if (error instanceof MessagePayloadError) {
        logger.error(error.message);
      }
      return NEVER;
    }),
  );

/**
 * Returns a function to subscribe to messages, filtered by the given message
 * bus channel
 * @param channel The channel in which the message would be sent
 */
export const subscribe =
  (channel: string) =>
  /**
   * Subscribe to the given topic using the provided callback. If the codec is
   * undefined, the payload will be unknown, else it will match its type.
   * @param topicFilter The topic to subscribe to. Can use a wildcard to match
   * multiple topics with the same namespace (e.g. 'namespace/*') or all topics
   * in the same channel ('*')
   * @param codec The codec ensuring the type safety of the payload. In the case
   * of a multi topic subscription, should be a t.union
   * @param callback The function called each time the message event is
   * dispatched. The first argument will be the payload while the second is the
   * whole envelope
   * @returns a callback function to unsubscribe
   */
  <Codec extends t.Mixed | undefined>(
    topicFilter: TopicFilter,
    codec: Codec,
    callback: (
      data: Codec extends t.Mixed ? t.TypeOf<Codec> : unknown,
      envelope: Envelope<Codec extends t.Mixed ? t.TypeOf<Codec> : unknown>,
    ) => void,
  ) => {
    const unsub = getTypedMessage$(channel, topicFilter, codec).subscribe({
      next: (envelope) => {
        callback(envelope.payload, envelope);
      },
    });
    return () => unsub.unsubscribe();
  };

const extractCodecMessage =
  <Topic extends MessageTopic, Payload = undefined>(
    messageDefinition: MessageFilter<Topic, Payload>,
  ) =>
  (envelope: Envelope) => {
    if (isMessageFilterWithPayload(messageDefinition)) {
      const decoded = messageDefinition.payloadCodec.decode(envelope.payload);
      if (isLeft(decoded)) {
        return decoded.left;
      }
    }
    return null;
  };
const isOfMessageDefinition =
  <Topic extends MessageTopic, Payload = undefined>(
    messageDefinition: MessageFilter<Topic, Payload>,
  ) =>
  (envelope: Envelope): envelope is Envelope<Payload> =>
    isMessageFilterWithPayload(messageDefinition)
      ? messageDefinition.payloadCodec.is(envelope.payload)
      : true;

/**
 * Returns a function to subscribe to a message from the message bus
 * @param channel The channel in which the message would be published
 */
export const subscribeToMessage =
  (channel: string) =>
  /**
   * Returns an observable yielding envelopes matching the given message
   * definition (a topic and a payload codec if provided)
   * @param messageDefinition the definition of the message to subscribe to,
   * @returns an observable of envelopes
   */
  <Topic extends MessageTopic, Payload = undefined>(
    messageDefinition: MessageFilter<Topic, Payload>,
  ): Observable<Envelope<Payload>> =>
    debouncedEventInput$.pipe(
      filter(isOfChannel(channel)),
      filter(isOfTopic(messageDefinition.topic)),
      map((envelope) => ({
        envelope,
        decodeErrors: extractCodecMessage(messageDefinition)(envelope),
      })),
      tap(({ decodeErrors }) => {
        if (decodeErrors) {
          logger.error(
            `[Message Bus] Payload for message ${messageDefinition.topic} on channel ${channel} failed to be decoded`,
            decodeErrors.forEach((error) => error.message),
          );
        }
      }),
      filter(({ decodeErrors }) => !decodeErrors),
      map(({ envelope }) => envelope),
      filter(isOfMessageDefinition(messageDefinition)),
    );

/** Subscribe to messages sent through the message bus on the global channel */
export const subscribeToGlobalMessages = subscribeToMessage(GLOBAL_CHANNEL);

const testDef = defineMessage('suoer/topic');

subscribeToMessage('chann')(testDef);
