import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';

import { LocaleCode } from '@gaming1/g1-config';
import { useI18n } from '@gaming1/g1-core';

import { CanShowWidget, WidgetCallback } from './types';

type WidgetAction =
  | 'open'
  | 'close'
  | 'unreadMessages'
  | 'locale'
  | 'zIndex'
  | 'cookies'
  | 'loginUser'
  | 'logoutUser'
  | 'helpCenter:reauthenticate'
  | 'logout';
type WidgetTarget =
  | 'messenger'
  | 'messenger:on'
  | 'messenger:set'
  | 'webWidget';
interface WidgetFunction {
  (
    target: WidgetTarget,
    action: WidgetAction,
    data?: LocaleCode | WidgetCallback,
  ): void;
  (target: 'messenger', action: 'loginUser', cb: WidgetCallback): void;
  (target: 'messenger:on', action: 'unreadMessages', cb: WidgetCallback): void;
  (target: 'messenger:set', action: 'locale', data: LocaleCode): void;
  (target: 'messenger:set', action: 'zIndex', newZIndex: number): void;
  (target: 'messenger:set', action: 'cookies', isEnabled: boolean): void;
}

type ZendeskWidgetMessengerType = {
  shouldWidgetBeVisible: boolean;
};

interface ZendeskSettingsAuth {
  jwtFn: (callback: (token: string) => void) => void;
  chat: {
    jwtFn: (callback: (token: string) => void) => void;
  };
}

interface ZendeskSettings {
  zESettings?: {
    webWidget: {
      authenticate: ZendeskSettingsAuth;
    };
  };
}

type WindowWithZendeskMessengerSDK = Window &
  ZendeskSettings & {
    zE?: WidgetFunction & { widget: 'messenger' };
  };
const windowWithZendeskSDK = window as WindowWithZendeskMessengerSDK;

/**
 * Messenger widget is currently not used by the website .
 * The widget type can be changed from zendesk.
 * We keep this code to show the widget if zendesk configuration changes.
 * The logic available in the classic widget is not imported here
 * because the behavior and the zendesk api are not the same for this widget type.
 */
export const ZendeskWidgetMessenger = forwardRef<
  CanShowWidget,
  ZendeskWidgetMessengerType
>(({ shouldWidgetBeVisible }, ref) => {
  const zendeskMethod = windowWithZendeskSDK?.zE;
  const { currentLocale: locale } = useI18n();
  /**
   * Show the widget (on a button click for instance)
   */
  const showWidget = useCallback(() => {
    if (zendeskMethod) {
      zendeskMethod('messenger', 'open');
    }
  }, [zendeskMethod]);

  // Manages to call a child method from a parent
  useImperativeHandle(ref, () => ({
    showWidget() {
      showWidget();
    },
  }));

  /** Hides and shows the widget */
  useEffect(() => {
    if (shouldWidgetBeVisible) {
      showWidget();
    } else if (zendeskMethod) {
      zendeskMethod('messenger', 'close');
    }
  }, [shouldWidgetBeVisible, showWidget, zendeskMethod]);

  /** Set the current language for the widget */
  useEffect(() => {
    if (zendeskMethod) {
      zendeskMethod('messenger:set', 'locale', locale);
    }
  }, [locale, zendeskMethod]);

  return null;
});
