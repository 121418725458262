import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import {
  alignContent,
  alignItems,
  alignSelf,
  color,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexWrap,
  fontSize,
  FontWeightProps,
  justifyContent,
  justifyItems,
  justifySelf,
  layout,
  LayoutProps,
  order,
  space,
  typography,
  width,
} from 'styled-system';

import { StyledFlexProps, StyledSystemProps } from '../types';

export type BoxProps = { children?: ReactNode } & StyledSystemProps &
  StyledFlexProps &
  LayoutProps &
  FontWeightProps;

/**
 * This code produces a typescript error when Box is implemented as a `div`
 * element:
 *
 * const Comp: FC<BoxProps> => {
 *  return Box {...props} />
 * }
 *
 * This is because Box `color` prop conflicts with the native div `color` prop.
 * So instead of using a regular div, we create a custon one without the `color`
 * prop. See: https://stackoverflow.com/questions/53711454/styled-system-props-typing-with-typescript
 */

// Avoid passing invalid dom props to the div element.
const Div = styled.div``;

const DivWithoutColorProp = forwardRef<
  HTMLDivElement,
  Omit<HTMLAttributes<HTMLDivElement>, 'color'>
>(({ children, ...props }, ref) => (
  <Div ref={ref} {...props}>
    {children}
  </Div>
));

/**
 * Generic div element made to easily compose layouts without needing to create
 * specific styled components
 * See: https://styled-system.com/guides/build-a-box
 */
export const Box = styled(DivWithoutColorProp)<BoxProps>`
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${fontSize}
  ${color}

  ${layout}

  ${alignContent}
  ${alignItems}
  ${alignSelf}
  ${flex}
  ${flexBasis}
  ${flexDirection}
  ${flexWrap}
  ${flexGrow}
  ${justifyContent}
  ${justifyItems}
  ${justifySelf}
  ${order}
  ${typography}
`;
