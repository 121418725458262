import { useContext } from 'react';

import { BlacklistedRoutesContext } from '../components/BlacklistedRoutesProvider';

/**
 * this hook provide a function that define if a path is black listed or not.
 * The black list is define through the "BlackListProvider"
 * @returns a function that check a path.
 */
export const useIsBlackListedRoute = () => {
  const blacklistedRoutes = useContext(BlacklistedRoutesContext);

  return (path: string): boolean =>
    blacklistedRoutes
      ? blacklistedRoutes.some((regex) => regex.test(path))
      : false;
};
