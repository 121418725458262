import { BehaviorSubject } from 'rxjs';

import {
  AppConfig,
  ConfigOverwriteByEnv,
  getConfig,
  PartnerConfig,
} from '@gaming1/g1-config';

const baseConfig: PartnerConfig = {
  betting: {},
  core: {},
  gaming: {},
  i18n: {
    availableLanguages: ['en'],
    defaultLanguage: 'en',
  },
  // This partner only contains static content no need for network configs
  network: {
    apiUrl: '',
    mediaUrl: '',
    prismicUrl: '',
    siteUrl: 'https://betlycom-us-front06.gaming1.com',
    wsUrl: '',
    zendeskUrl: '',
  },
  user: {},
};

const configOverwrite: ConfigOverwriteByEnv = {
  local: {},
  development: {},
  testing: {},
  acceptance: {},
  production: {},
};

export const config: AppConfig = getConfig(baseConfig, configOverwrite);

export const config$ = new BehaviorSubject(config);
