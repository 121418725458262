import { Action, Location } from 'history';
import {
  ContextType,
  FC,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Subject } from 'rxjs';

import { useI18n } from '@gaming1/g1-i18n';
import { usePrevious } from '@gaming1/g1-utils';

import { extractLocaleFromUrl } from '../../helpers';
import { HistoryContext } from '../../HistoryContext';
import { useLocaleSwitch } from '../../hooks/useLocaleSwitch';
import { HistoryEvent } from '../../types';

/**
 * Store the location history, the last non-fullscreen location, and
 * the state of the fullscreen layout
 */
export const HistoryProvider: FC<{
  children?: ReactNode;
}> = memo(({ children }) => {
  /* 
    Publisher that will emit rxjs-events when router history changes. 
    
    This package does not have direct access to the message bus because of the 
    dependency hierarchy. This message is forwarded to the message bus in 
    g1-core-web (usePublishLocationChanges).
    */
  const history$ = useRef(new Subject<HistoryEvent>()).current;
  const { currentLocale } = useI18n();
  const currentLocation = useLocation();
  const history = useHistory();
  const { switchLocale } = useLocaleSwitch();

  const [latestAction, setLatestAction] = useState<Action | null>(null);

  /** Full history of visited Location's */
  const [locationStack, setLocationStack] = useState<Array<Location>>(
    currentLocation?.pathname ? [currentLocation] : [],
  );

  /** Is the current render including a Fullscreen component ? */
  const [isFullscreenLayout, setIsFullscreenLayout] = useState(false);

  /** Is the current render including a bottom nav component in Fullscreen ? */
  const [isBottomNavVisibleInFullscreen, setIsBottomNavVisibleInFullscreen] =
    useState(false);

  /** Function handling the Fullscreen layout parameters */
  const setFullscreenLayout = (
    isFullScreen: boolean,
    isBottomNavVisible?: boolean,
  ) => {
    setIsFullscreenLayout(isFullScreen);
    setIsBottomNavVisibleInFullscreen(!!isBottomNavVisible);
  };

  /** The state of previous isFullscreenLayout associated with oldPreviousLocation  */
  const previousIsFullscreenLayout = usePrevious(isFullscreenLayout);

  /** Get the previous location from a locationStack */
  const getPreviousLocation = (
    subjectStack: Array<Location>,
  ): Location | null => {
    if (subjectStack.length < 2) {
      return null;
    }
    return subjectStack.at(-2) || null;
  };

  /**
   * The location where the browser will go if the "back" button is used (if it
   * wasn't used previously).
   */
  const previousLocation: Location | null = useMemo(
    () => getPreviousLocation(locationStack),
    [locationStack],
  );

  /**
   * Builds a new locationStack based on an action, a location and an initial
   * location stack
   */
  const buildLocationStack = useCallback(
    (
      action: Action,
      location: Location,
      initialLocationStack: Array<Location>,
    ): Array<Location> => {
      let newLocationStack: Array<Location> = [];
      switch (action) {
        case 'PUSH':
          newLocationStack = [...initialLocationStack, location];
          break;
        case 'POP':
          // If the action is 'POP', simply remove the last element.
          newLocationStack = initialLocationStack.slice(
            0,
            initialLocationStack.length - 1,
          );
          break;
        case 'REPLACE':
          // If the action is 'REPLACE', replace the last element in the array
          // with the current one.
          newLocationStack = [
            ...initialLocationStack.slice(0, initialLocationStack.length - 1),
            location,
          ];
          break;
        default: // no-op
      }

      if (newLocationStack.length > 200) {
        // Max 200 history size.
        // A limit that represents no limit is better than no limit at all.
        newLocationStack.shift();
      }

      return newLocationStack;
    },
    [],
  );

  /**
   * Listens to actions from the router and updates the history state.
   */
  useEffect(() => {
    /**
     * If multiple history events are emitted in quick succession by
     * history.listen, React may not have enough time to update the locationStack-
     * state. This can occur after a redirect, such as when accessing a
     * protected route where a PUSH action is quickly succeeded by a REPLACE.
     * In such cases, the PUSH action will be skipped when calculating a new
     * locationStack.
     *
     * To address this issue, we use intermediateLocationStack to store
     * intermediate states of the LocationStack:
     *  - When a history event is emitted and React has not updated the state
     *    yet, intermediateLocationStack is used as history.
     *  - When a history event is emitted and React has had time to update the
     *    state, intermediateLocationStack is null (because of the useEffect
     *    dependency array).
     */
    let intermediateLocationStack: Array<Location> | null = null;

    // Emits each time the router detects a change
    const unregister = history.listen((location, action) => {
      const updatedLocationStack = buildLocationStack(
        action,
        location,
        intermediateLocationStack || locationStack,
      );
      const updatedPreviousLocation = getPreviousLocation(updatedLocationStack);

      // Store intermediate result
      intermediateLocationStack = updatedLocationStack;

      // Update state
      setLatestAction(action);
      setLocationStack(updatedLocationStack);

      // Notify observers of updated history
      history$.next({
        action,
        previousLocation: updatedPreviousLocation,
        currentLocation: location,
      });

      if (action === 'POP') {
        const targetedLocale = extractLocaleFromUrl(location.pathname);
        if (
          targetedLocale &&
          currentLocale !== targetedLocale &&
          // If the translations are being debugged, no switch needed !
          (currentLocale as string) !== 'cimode'
        ) {
          switchLocale(targetedLocale, false);
        }
      }
    });
    return () => {
      unregister();
    };
  }, [
    buildLocationStack,
    currentLocale,
    history,
    history$,
    switchLocale,
    locationStack,
  ]);

  const fallbackLocation = useMemo(
    () => ({
      hash: '',
      pathname: `/${currentLocale}`,
      search: '',
      state: null,
    }),
    [currentLocale],
  );

  /**
   * The location where the "exit" button of a Fullscreen must redirect (i.e.
   * the latest non-Fullscreen location)
   */
  const [exitFullscreenLocation, setExitFullscreenLocation] =
    useState<Location | null>(null);

  useEffect(() => {
    if (
      !!previousLocation &&
      !isFullscreenLayout &&
      !previousIsFullscreenLayout
    ) {
      setExitFullscreenLocation(previousLocation);
    }
  }, [isFullscreenLayout, previousIsFullscreenLayout, previousLocation]);

  const historyContextValue: ContextType<typeof HistoryContext> = useMemo(
    () => ({
      exitFullscreenLocation: exitFullscreenLocation || fallbackLocation,
      latestAction,
      isBottomNavVisibleInFullscreen,
      isFullscreenLayout,
      history$,
      previousLocation,
      setFullscreenLayout,
    }),
    [
      exitFullscreenLocation,
      latestAction,
      fallbackLocation,
      isBottomNavVisibleInFullscreen,
      isFullscreenLayout,
      history$,
      previousLocation,
    ],
  );

  return (
    <HistoryContext.Provider value={historyContextValue}>
      {children}
    </HistoryContext.Provider>
  );
});
