import format from 'date-fns/format';
import { ContextType, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import { useConsentPolicy } from '@gaming1/g1-consent-management/shared';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { getAge, logger, usePreviousDifferent } from '@gaming1/g1-utils';

import { TrackingContext } from '../../TrackingContext';
import {
  ScreebEventProperties,
  ScreebObject,
  ScreebSdkFunction,
  WindowWithScreebSDK,
} from '../../types';

export const windowWithScreebSDK = window as WindowWithScreebSDK;
export const DELAY_BEFORE_SCRIPT_INJECTION_IN_MS = 5000;

const SCREEB_SCRIPT_ID = '$screeb';
export const SCREEB_SCRIPT_SRC = 'https://t.screeb.app/tag.js';

type ScreebContextValue = Pick<
  ContextType<typeof TrackingContext>,
  'isScreebEnabled' | 'trackScreebEvent'
>;

/**
 * This hook check if the user gave his consent for privacy settings and
 * initialize the screeb sdk. It also returns the event tracking function.
 */
export const useScreeb = (): ScreebContextValue => {
  const { core, i18n, seo } = useConfig();

  const { getUserConsentStatusForPurpose, getUserConsentStatusForVendor } =
    useConsentPolicy();

  const user = useSelector(userSelector);
  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const previousIsUserLoggedIn = usePreviousDifferent(isUserLoggedIn);

  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [isSdkInitialized, setIsSdkInitialized] = useState(false);

  const hasUserAcceptedScreeb =
    getUserConsentStatusForPurpose(
      core.privacySettings.purposeIDs.uxImprovement,
    ) && getUserConsentStatusForVendor(core.privacySettings.vendorIDs.screeb);

  const isScreebEnabled = !!seo.screebWebsiteId && !!hasUserAcceptedScreeb;

  const trackScreebEvent = useCallback(
    (eventName: string, eventProperties?: ScreebEventProperties) => {
      if (isSdkInitialized) {
        windowWithScreebSDK.$screeb?.(
          'event.track',
          eventName,
          eventProperties,
        );

        logger.debug('[Tracking] Screeb function called (event.track)', {
          event: eventName,
          ...eventProperties,
        });
      }
    },
    [isSdkInitialized],
  );

  /* Handles script injection */
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (isScreebEnabled && !isScriptLoaded) {
      timeout = setTimeout(() => {
        const screebObject = ((...args) => {
          (screebObject.q = screebObject.q || []).push(args);
        }) as ScreebSdkFunction & ScreebObject;

        windowWithScreebSDK['ScreebObject'] = SCREEB_SCRIPT_ID;
        windowWithScreebSDK[SCREEB_SCRIPT_ID] =
          windowWithScreebSDK[SCREEB_SCRIPT_ID] || screebObject;

        const scriptTag = document.createElement('script');
        scriptTag.id = SCREEB_SCRIPT_ID;
        scriptTag.src = SCREEB_SCRIPT_SRC;
        scriptTag.type = 'text/javascript';
        scriptTag.async = true;
        scriptTag.onload = () => setIsScriptLoaded(true);

        document.getElementsByTagName('head')[0].appendChild(scriptTag);
      }, DELAY_BEFORE_SCRIPT_INJECTION_IN_MS);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isScreebEnabled, isScriptLoaded]);

  /* Initialize the screeb sdk */
  useEffect(() => {
    if (seo.screebWebsiteId && isScriptLoaded && !isSdkInitialized) {
      windowWithScreebSDK.$screeb?.('init', seo.screebWebsiteId);
      setIsSdkInitialized(true);

      logger.debug("[Tracking] Screeb function called ('init')");
    }
  }, [isScriptLoaded, isSdkInitialized, seo.screebWebsiteId]);

  /* Identify the user when logged in */
  useEffect(() => {
    if (isSdkInitialized && isUserLoggedIn && user?.Id) {
      const userProperties = {
        age: getAge(format(new Date(user.Birthday), i18n.dateFormat)),
        email: user.Email || '',
        firstname: user.FirstName || '',
        lastname: user.LastName || '',
      };

      windowWithScreebSDK.$screeb?.('identity', user.Id, userProperties);

      logger.debug("[Tracking] Screeb function called ('identity')", {
        id: user.Id,
        ...userProperties,
      });
    }
  }, [
    i18n.dateFormat,
    isSdkInitialized,
    isUserLoggedIn,
    user?.Birthday,
    user?.Email,
    user?.FirstName,
    user?.Id,
    user?.LastName,
  ]);

  /* Reset the identity when the user is logged out */
  useEffect(() => {
    if (isSdkInitialized && previousIsUserLoggedIn && !isUserLoggedIn) {
      windowWithScreebSDK.$screeb?.('identity.reset');

      logger.debug("[Tracking] Screeb function called ('identity.reset')");
    }
  }, [isSdkInitialized, isUserLoggedIn, previousIsUserLoggedIn]);

  return { isScreebEnabled, trackScreebEvent };
};
