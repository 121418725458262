import { useContext, useEffect } from 'react';

import { interopMessages } from '@gaming1/g1-core';
import { getWrapperEnv } from '@gaming1/g1-logger';
import { useIsFullscreenLayout } from '@gaming1/g1-routing';
import { LayoutContext } from '@gaming1/g1-ui';

import { sendIframeMessage } from '../../../rninterop/iframeMessageManager/helpers';

/**
 * Detects whether a backdrop is visible,
 * thus asking the RN app to disable/enable the "Pull to Refresh" functionality
 * (on Android at least)
 */
export const useEnablePullToRefresh = () => {
  const { backdropVisibility: isBackdropVisible } = useContext(LayoutContext);
  const isFullscreen = useIsFullscreenLayout();

  useEffect(() => {
    if (getWrapperEnv() === 'rn') {
      if (isBackdropVisible || isFullscreen) {
        sendIframeMessage(interopMessages.togglePullToRefresh, {
          isEnabled: false,
        });
      } else {
        sendIframeMessage(interopMessages.togglePullToRefresh, {
          isEnabled: true,
        });
      }
    }
  }, [isBackdropVisible, isFullscreen]);
};
