import { FC, useEffect } from 'react';

import {
  analyticsInjectedEventMessage,
  MESSAGE_BUS_MARKETING_CHANNEL,
} from '@gaming1/g1-core-api';
import { subscribeToMessage } from '@gaming1/g1-message-bus';

import { useTracking } from '../../hooks/useTracking';

/** Manages analytics injected events and forward them to meta pixel */
export const MetaPixelEventsManager: FC = () => {
  const { isMetaPixelEnabled, trackMetaPixelEvent } = useTracking();

  useEffect(() => {
    if (isMetaPixelEnabled) {
      const sub = subscribeToMessage(MESSAGE_BUS_MARKETING_CHANNEL)(
        analyticsInjectedEventMessage,
      ).subscribe({
        next: ({ payload: { data } }) => {
          const event = data.event || data.action;
          if (typeof event === 'string') {
            trackMetaPixelEvent(event);
          }
        },
      });
      return () => sub.unsubscribe();
    }
    return () => null;
  }, [isMetaPixelEnabled, trackMetaPixelEvent]);

  return null;
};
