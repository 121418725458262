import React, { VFC } from 'react';

import { Loader } from '@gaming1/g1-ui';

import { usePageMinHeight } from '../../hooks';

import { SuspenseLoaderContainer } from './styles';

/** A simple view used to display a suspense loader */
export const SuspenseLoader: VFC = () => {
  const { minHeight } = usePageMinHeight(true);

  return (
    <SuspenseLoaderContainer
      data-testid="suspense-loader"
      minHeight={minHeight}
    >
      <Loader type="page" />
    </SuspenseLoaderContainer>
  );
};
