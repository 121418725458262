import styled from 'styled-components';

import { colors } from '@gaming1/g1-style';
import { ContentContainer } from '@gaming1/g1-ui';

/** Main content wrapper of the app */
export const Content = styled(ContentContainer)`
  flex-direction: column;
  flex: 1;
  width: 100%;
  color: ${colors('text')};
`;
