import debounce from 'lodash/debounce';
import {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { fromEvent, mapTo } from 'rxjs';

import { MediaBreakPointNames } from '@gaming1/g1-style';

import {
  createPausableTimer,
  isMediaBreakPointAboveOrEqual,
  isMediaBreakPointBelowOrEqual,
} from './helpers';
import { LayoutContext } from './LayoutProvider/LayoutContext';
import { InnerBPContext } from './MediaCol';
import { ScrollableContentHint } from './ScrollableContentHint';

export const AUTOROTATION_INTERVAL_IN_MS = 5000;

/**
 * Returns the media breakpoint (for the viewport) and the inner breakpoint
 * (created by `MediaCol`). `innerBP` can be `null` if the component is not used
 * within a `MediaCol` component.
 */
export const useMedia = () => {
  const innerBP = useContext(InnerBPContext);
  const { media } = useContext(LayoutContext);

  return { innerBP, mediaBP: media };
};

type MaxBeacon = { max: MediaBreakPointNames };
type MinBeacon = { min: MediaBreakPointNames };

/**
 * Tells if the viewport is above or under the provided breakpoint
 *
 * ### Usage
 *
 * ```ts
 * const isBPAboveOrXL = useMediaBreakPoint({ min: 'xl' });
 * const isBPUnderOrXL = useMediaBreakPoint({ max: 'xl' });
 * ```
 */
export const useMediaBreakPoint = (beacon: MaxBeacon | MinBeacon) => {
  const { media } = useContext(LayoutContext);

  return 'min' in beacon
    ? isMediaBreakPointAboveOrEqual(media, beacon.min)
    : isMediaBreakPointBelowOrEqual(media, beacon.max);
};

/**
 * Checks whether the provided element is scrollable or not and if it's been
 * scrolled passed a threshold of 300px and returns `null` when scrolled enough
 * or a hint component when user can scroll and hasn't reached said threshold.
 *
 * ### Usage
 *
 * ```tsx
 * const scrollableElemRef = useRef<HTMLDivElement>(null);
 * const ScrollableHint = useScrollableContentHint(scrollableElemRef);
 *
 * // ...
 *
 * return (
 *  <div ref={scrollableElemRef}>{content}</div>
 *  {ScrollableHint && <ScrollableHint size="1em" />}
 * );
 * ```
 */
export const useScrollableContentHint = <T extends HTMLElement>(
  ref: RefObject<T>,
) => {
  // Implementation details:
  // see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight
  // see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollTop
  const SCROLL_THRESHOLD_IN_PX = 300;

  const isLargeViewportOrAbove = useMediaBreakPoint({ min: 'lg' });

  const [isScrollable, setIsScrollable] = useState(false);
  const [hasScrolledThreshold, setHasScrolledThreshold] = useState(false);

  useEffect(() => {
    const scrollElem = ref.current;
    if (scrollElem) {
      const refIsScrollable =
        scrollElem.scrollHeight >
        scrollElem.clientHeight + SCROLL_THRESHOLD_IN_PX;
      setIsScrollable(refIsScrollable);

      if (refIsScrollable) {
        const scrollHandler = debounce(() => {
          const newHasFullyScrolled =
            Math.abs(scrollElem.scrollTop) > SCROLL_THRESHOLD_IN_PX;
          // Only execute this once when the user reaches the end of the scroll
          // When he scrolls back up, he won't need the hint anymore (hopefully).
          if (newHasFullyScrolled) {
            setHasScrolledThreshold(newHasFullyScrolled);
          }
        }, 300);

        scrollElem.addEventListener('scroll', scrollHandler);

        return () => {
          scrollElem.removeEventListener('scroll', scrollHandler);
        };
      }
    }

    return () => undefined;
  }, [ref]);

  return isLargeViewportOrAbove && isScrollable && !hasScrolledThreshold
    ? ScrollableContentHint
    : null;
};

/**
 * Waits `AUTOROTATION_INTERVAL_IN_MS` milliseconds before executing the given
 * `action()`.
 *
 * It returns an `abort` function which then... aborts the waiting and,
 * therefore, the call of the given `action`
 */
export const usePausableWaitThen = (
  containerRef: HTMLDivElement | null,
  timeInMs = AUTOROTATION_INTERVAL_IN_MS,
) => {
  const isWaiting = useRef(false); // Kind of debounce multiple calls

  return useCallback(
    (action: () => void) => {
      if (containerRef && !isWaiting.current) {
        // blocks further calls before the timer stream ends
        isWaiting.current = true;
        const pause$ = fromEvent(containerRef, 'mouseenter').pipe(
          mapTo('pause' as const),
        );
        const resume$ = fromEvent(containerRef, 'mouseleave').pipe(
          mapTo('resume' as const),
        );
        const subscription = createPausableTimer({
          pause$,
          resume$,
          timeInMs,
        }).subscribe({
          next: () => {
            action();
            // unlock a new timer call
            isWaiting.current = false;
          },
        });

        // abort function. This is called when user manually changes the order by
        // navigating
        return () => {
          subscription.unsubscribe();
          // unlock a new timer call
          isWaiting.current = false;
        };
      }

      return null;
    },
    [containerRef, timeInMs],
  );
};
