import { useEffect } from 'react';
import { filter } from 'rxjs/operators';

import { getWrapperEnv } from '@gaming1/g1-logger';
import { Envelope, getMessageBusPluginHelpers } from '@gaming1/g1-message-bus';

import { logger } from '../../logger';

import { useIframeMessages } from './useIframeMessages';

const { input$, output$ } = getMessageBusPluginHelpers();

type MessageTopic = Envelope['topic'];

const isValidMessageTopic = (input: string): input is MessageTopic =>
  input.indexOf('/') !== 1;

// Change this to true to log every shared messages
const shouldLogVerbosely = false;

/** Connect the message bus in the web context to the one living in the React
 * Native context. Meaning that messages from RN will be also received on the
 * web and vice-versa.
 */
export const useSharedMessageBus = () => {
  const shouldForwardRNMessages = getWrapperEnv() === 'rn';

  const { messageBusMessageReceived$, forwardMessageBusMessage } =
    useIframeMessages();

  useEffect(() => {
    if (shouldForwardRNMessages) {
      const sub = messageBusMessageReceived$.subscribe({
        next: ({ payload: envelope }) => {
          if (isValidMessageTopic(envelope.topic)) {
            const topic: MessageTopic = isValidMessageTopic(envelope.topic)
              ? envelope.topic
              : `unknown/${envelope.topic}`;
            input$.next({ ...envelope, topic });

            if (shouldLogVerbosely) {
              logger.debug(
                '[Message Bus] Message from RN to the web:',
                envelope,
              );
            }
          }
        },
      });
      return () => sub.unsubscribe();
    }
    return () => null;
  }, [messageBusMessageReceived$, shouldForwardRNMessages]);

  useEffect(() => {
    if (shouldForwardRNMessages) {
      const sub = output$
        // Filter out events coming from another source
        .pipe(filter((envelope) => !envelope.meta?.origin))
        .subscribe({
          next: (envelope) => {
            forwardMessageBusMessage({
              ...envelope,
              // Mark the event as being shared
              meta: { ...envelope.meta, origin: 'spa' },
            });

            if (shouldLogVerbosely) {
              logger.debug(
                '[Message Bus] Message from the web to RN:',
                envelope,
              );
            }
          },
        });

      return () => {
        sub.unsubscribe();
      };
    }
    return () => null;
  }, [forwardMessageBusMessage, shouldForwardRNMessages]);
};
