import { AppConfig } from '../types';

import { defaultAnalyticsConfig } from './analytics';
import { defaultAssetsManagementConfig } from './assetsManagement';
import { defaultBettingConfig } from './betting';
import { defaultCoreConfig } from './core';
import { defaultCreditConfig } from './credit';
import { defaultGamingConfig } from './gaming';
import { defaultI18nConfig } from './i18n';
import { defaultLoyaltyConfig } from './loyalty';
import { defaultMarketingConfig } from './marketing';
import { defaultNetworkConfig } from './network';
import { defaultRoomConfig } from './room';
import { defaultSecurityConfig } from './security';
import { defaultSeoConfig } from './seo';
import { defaultUserConfig } from './user';

export const defaultConfig: AppConfig = {
  analytics: defaultAnalyticsConfig,
  assetsManagement: defaultAssetsManagementConfig,
  betting: defaultBettingConfig,
  core: defaultCoreConfig,
  credit: defaultCreditConfig,
  gaming: defaultGamingConfig,
  i18n: defaultI18nConfig,
  loyalty: defaultLoyaltyConfig,
  marketing: defaultMarketingConfig,
  network: defaultNetworkConfig,
  room: defaultRoomConfig,
  security: defaultSecurityConfig,
  seo: defaultSeoConfig,
  user: defaultUserConfig,
};
