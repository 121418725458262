import { Context } from '@datadog/browser-core';
import {
  datadogLogs,
  LogsInitConfiguration,
  StatusType,
} from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ContextType, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useConfig } from '@gaming1/g1-config';
import { useConsentPolicy } from '@gaming1/g1-consent-management/shared';
import { userLoggedInSelector, userSelector } from '@gaming1/g1-core';
import { getEnvVariable } from '@gaming1/g1-env';
import { DeployEnv, getDeployEnv } from '@gaming1/g1-logger';

import { logger } from '../../../logger';
import { TrackingContext } from '../../TrackingContext';

type DataDogEnv = 'dev' | 'tst' | 'acc' | 'prd';

type DataDogContextValue = Pick<
  ContextType<typeof TrackingContext>,
  | 'isDataDogEnabled'
  | 'logDataDogErrorOrWarning'
  | 'pushDataDogAction'
  | 'pushDataDogContext'
  | 'pushDataDogError'
>;

const DATADOG_ENVS: Record<DeployEnv, DataDogEnv> = {
  local: 'dev',
  development: 'dev',
  testing: 'tst',
  acceptance: 'acc',
  production: 'prd',
};

export const DD_LOGS_SEPARATOR = ' ';
const DD_SERVICE_NAME = 'web-app';

/**
 * Implements DataDog functionalities :
 *   - sending context informations to DataDog
 *   - sending custom actions to DataDog
 *   - sending errors to DataDog
 *
 * Documentation can be found here :
 * https://docs.datadoghq.com/real_user_monitoring/browser/#initialization-parameters
 */
export const useDataDog = (): DataDogContextValue => {
  const {
    core: { privacySettings },
    seo: { dataDog },
  } = useConfig();

  const { getUserConsentStatusForPurpose, getUserConsentStatusForVendor } =
    useConsentPolicy();

  const isDataDogEnabled =
    !!getUserConsentStatusForPurpose(
      privacySettings.purposeIDs.performanceMeasure,
    ) &&
    !!getUserConsentStatusForVendor(privacySettings.vendorIDs.datadog) &&
    !!dataDog.applicationId &&
    !!dataDog.clientToken &&
    !!dataDog.site;

  const pushDataDogAction = useCallback(
    (name: string, context?: Context | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog action :', name, context);
        datadogRum.addAction(name, context);
      }
    },
    [isDataDogEnabled],
  );

  const pushDataDogContext = useCallback(
    (key: string, value?: Context | string | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog context :', key, value);
        datadogRum.addRumGlobalContext(key, value);
      }
    },
    [isDataDogEnabled],
  );

  const pushDataDogError = useCallback(
    (error: unknown, context?: Context | undefined) => {
      if (isDataDogEnabled) {
        logger.debug('[Tracking] Sending DataDog error :', error, context);
        datadogRum.addError(error, context);
      }
    },
    [isDataDogEnabled],
  );

  /* Init datadog RUM and Logs */
  useEffect(() => {
    if (isDataDogEnabled) {
      const datadogEnv = DATADOG_ENVS[getDeployEnv()];

      // RUM
      const initRUMData: Parameters<typeof datadogRum.init>[0] = {
        applicationId: dataDog.applicationId || '',
        clientToken: dataDog.clientToken || '',
        env: datadogEnv,
        // Default : 100
        sampleRate: dataDog.sampleRate,
        // Default: 0
        sessionReplaySampleRate: dataDog.sessionReplaySampleRate,
        service: DD_SERVICE_NAME,
        site: dataDog.site || '',
        trackInteractions: true,
        version: getEnvVariable('appVersion') || '',
      };
      logger.debug(
        '[Tracking] Initializing DataDog with parameters :',
        initRUMData,
      );
      datadogRum.init(initRUMData);

      // LOGS
      const initLogsData: LogsInitConfiguration = {
        clientToken: dataDog.clientToken || '',
        site: dataDog.site || '',
        service: DD_SERVICE_NAME,
        forwardErrorsToLogs: true,
        sessionSampleRate: dataDog.sampleRate,

        version: getEnvVariable('appVersion') || '',
        env: datadogEnv,
      };
      datadogLogs.init(initLogsData);

      pushDataDogContext('partner', dataDog.partnerTag || '');
    }
  }, [
    dataDog.applicationId,
    dataDog.clientToken,
    dataDog.partnerTag,
    dataDog.sessionReplaySampleRate,
    dataDog.sampleRate,
    dataDog.site,
    isDataDogEnabled,
    pushDataDogContext,
  ]);

  const logDataDogErrorOrWarning = useCallback(
    (
      message: string,
      messageContext?: object,
      status?: StatusType,
      error?: Error,
    ) => {
      logger.debug(
        `[Tracking] Sending DataDog ${status} with message : `,
        message,
      );
      datadogLogs.logger.log(message, messageContext, status, error);
    },
    [],
  );

  const isUserLoggedIn = useSelector(userLoggedInSelector);
  const user = useSelector(userSelector);

  /* Setup datadog user context */
  useEffect(() => {
    if (isDataDogEnabled && isUserLoggedIn && user) {
      pushDataDogContext('usr', {
        id: user.Pseudo || null,
        logged: !!isUserLoggedIn,
      });
    }
  }, [isDataDogEnabled, isUserLoggedIn, pushDataDogContext, user]);

  const ddData: DataDogContextValue = useMemo(
    () => ({
      isDataDogEnabled,
      logDataDogErrorOrWarning,
      pushDataDogAction,
      pushDataDogContext,
      pushDataDogError,
    }),
    [
      isDataDogEnabled,
      logDataDogErrorOrWarning,
      pushDataDogAction,
      pushDataDogContext,
      pushDataDogError,
    ],
  );

  return ddData;
};
