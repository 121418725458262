import { produce } from 'immer';
import omit from 'lodash/omit';
import { getType } from 'typesafe-actions';

import {
  generateInitialRequestState,
  produceFailureState,
  produceLoadingState,
  produceSuccessState,
  RemoteData,
} from '@gaming1/g1-utils';

import * as actions from '../actions';
import { CoreActions } from '../types';

import { GeolocationState } from './types';

export const initialState: GeolocationState = {
  currentGeolocation: null,
  geoComplyLicenseKey: null,
  hasGeolocationErrorsAlreadyOccured: false,
  requests: {
    checkIsCachedGeolocationValid: generateInitialRequestState(),
    connectToGeoComply: generateInitialRequestState(),
    decryptGeolocation: generateInitialRequestState(),
    getGeoComplyLicenseKey: generateInitialRequestState(),
    requestGeolocation: generateInitialRequestState(),
  },
  troubleshooters: null,
};

export const geolocationReducer = (
  state: GeolocationState = initialState,
  action: CoreActions,
) =>
  produce(state, (draftState) => {
    switch (action.type) {
      // loggedOutUser
      case getType(actions.loggedOutUser):
        draftState.requests.decryptGeolocation.status = RemoteData.NotAsked;
        draftState.requests.requestGeolocation.status = RemoteData.NotAsked;

        draftState.currentGeolocation = null;
        draftState.troubleshooters = null;
        draftState.hasGeolocationErrorsAlreadyOccured = false;
        break;

      // connectToGeoComply
      case getType(actions.connectToGeoComply.request):
        produceLoadingState(draftState.requests.connectToGeoComply);
        break;

      case getType(actions.connectToGeoComply.success):
        produceSuccessState(draftState.requests.connectToGeoComply);
        break;

      case getType(actions.connectToGeoComply.failure):
        produceFailureState(
          draftState.requests.connectToGeoComply,
          action.payload,
        );
        break;

      // getGeoComplyLicenseKey
      case getType(actions.getGeoComplyLicenseKey.request):
        produceLoadingState(draftState.requests.getGeoComplyLicenseKey);
        draftState.geoComplyLicenseKey = null;
        break;

      case getType(actions.getGeoComplyLicenseKey.success):
        produceSuccessState(draftState.requests.getGeoComplyLicenseKey);
        draftState.geoComplyLicenseKey = action.payload.License;
        break;

      case getType(actions.getGeoComplyLicenseKey.failure):
        produceFailureState(
          draftState.requests.getGeoComplyLicenseKey,
          action.payload,
        );
        break;

      // requestGeolocation
      case getType(actions.requestGeolocation.request):
        produceLoadingState(draftState.requests.requestGeolocation);
        break;

      case getType(actions.requestGeolocation.success):
        produceSuccessState(draftState.requests.requestGeolocation);
        break;

      case getType(actions.requestGeolocation.failure):
        produceFailureState(
          draftState.requests.requestGeolocation,
          action.payload,
        );
        break;

      // checkIsGeolocationValid
      case getType(actions.checkIsCachedGeolocationValid.request):
        produceLoadingState(draftState.requests.checkIsCachedGeolocationValid);
        break;

      case getType(actions.checkIsCachedGeolocationValid.success):
        produceSuccessState(draftState.requests.checkIsCachedGeolocationValid);
        draftState.currentGeolocation = action.payload;
        break;

      case getType(actions.checkIsCachedGeolocationValid.failure):
        produceFailureState(
          draftState.requests.checkIsCachedGeolocationValid,
          action.payload,
        );
        draftState.currentGeolocation = null;
        break;

      // decryptGeolocation
      case getType(actions.decryptGeolocation.request):
        produceLoadingState(draftState.requests.decryptGeolocation);
        break;

      case getType(actions.decryptGeolocation.success): {
        produceSuccessState(draftState.requests.decryptGeolocation);
        const { payload } = action;

        draftState.currentGeolocation = omit(payload, 'Troubleshooters');
        draftState.troubleshooters = payload.Troubleshooters || null;

        if (!state.hasGeolocationErrorsAlreadyOccured && !payload.IsValid) {
          draftState.hasGeolocationErrorsAlreadyOccured = true;
        }

        break;
      }

      case getType(actions.decryptGeolocation.failure):
        produceFailureState(
          draftState.requests.decryptGeolocation,
          action.payload,
        );
        break;

      case getType(actions.loggedInUser):
        draftState = initialState;
        break;

      default: // Immer will automatically return the state
    }
  });
