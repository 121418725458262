import { useContext } from 'react';

import { IframeEventsContext } from '../components/IframeEventsProvider';

/**
 * Make use of methods and observable of the iframeMessageManager to send and
 * receive "postMessage" events. Especially useful when the webapp is wrapped in
 * a react-native webview
 */
export const useIframeMessages = () => useContext(IframeEventsContext);
